import React, { Component } from "react";

// Components
import ErrorModal from "../../ErrorModal";
import EditNicknameModalConsent from "../../EditNicknameModalConsent";
import NewAccountConnectionModal from "../../NewAccountConnectionModal";
import DataTable from "../presentation/DataTable";
import SearchBar from "../../SearchBar";
import ConnectRenewButton from "../../ConnectRenewButton";

// Lib
import { Link, withRouter } from "react-router-dom";
import AnalyticsService from "analytics-web";
import debounce from 'lodash/debounce';

// Services
import i18n from "../../../i18n";
import DatasourceService from "../../Services/DatasourceService";

// Utils
import  { delegatedAccountsCheck } from  "../../../utils/DelegatedAccounts";
import PromiseAllsettled from "../../../utils/PromiseAllsettled";

class FindConnectionContainer extends Component {
  state = {
    isLoading: true,
    findConnectionFields: [],
    dsList: [],
    connectedAccounts: [],
    changeNickname: false,
    editNicknameTitle: "",
    editNicknameDesc: "",
    hasError: false,
    errorTitle: "",
    errorMessage: "",
    errorButtonText: i18n.t("ok"),
    errorModalButtonAction: this.handleToggleErrorModal,
    queryString: "",
    searchResults: [],
    isSendingReq: false,
    clickedDs: {},
    dataSource: this.props.dataSource,
    expiredAccountWithNewCredModalOpen: false,

    limit: window.config.connectionsPagination,
    walletAccountName: window.config.wallet_account_name,

    changeNicknameValues: {}
  };

  componentDidMount = () => {
    const { 
      dataSource,
      isInternetDisconnected
    } = this.props;

    let fields = [];

    //Analytics
    this.handleAnalyticsTrack("onLoad_event");

    // These are required for lib table
    fields = [{ selector: "multiple_accounts_enable" }];


    // =====================
    // Api calls 
    // =====================

    // Api calls if internet is active and without any errors

    if (!isInternetDisconnected) { 
      let promises = [];

      // 1. Get all the Data Sources

      promises.push(
        new Promise((resolve, reject) => {
          DatasourceService.getAllDatasources()
            .then((orgs) => {
              resolve(orgs);
            })
            .catch((e) => {
              reject(e);
            });
        })
      );
      
      // 2. Get user Data sources

      promises.push(
        new Promise((resolve, reject) => {
          DatasourceService.getMyDatasourceAccounts()
            .then((accounts) => {
              // 1.0.1 Sort based on disabled enabled
              const sortedDsAccounts = accounts.sort((a, b) => Boolean(a.disabled) - Boolean(b.disabled));
  
              // 1.1 Check expiry
              sortedDsAccounts.map((account) => DatasourceService.isAccountExpired(account));
  
              resolve(sortedDsAccounts);
            })
            .catch((e) => {
              reject(e);
            });
        })
      );

      // 3. Get connected accounts history
  
      promises.push(
        new Promise((resolve, reject) => {
          DatasourceService.getConnectedAccountsHistory().then((result) => {
            resolve(result);
          }).catch((e) => {
            reject(e);
          });
        })
      );

      // 4. Call to get the relations
      if (dataSource && !dataSource.error) { 
        promises.push(
          new Promise((resolve, reject) => {
            DatasourceService.getDatasourceByIDRelations(dataSource.data_source.data_source_id).then((result) => {
              resolve(result);
            }).catch((e) => {
              reject(e);
            });
          })
        );
      }

      return Promise.all(promises).then(async (results) => {
        let dataSources = results[0];
        let connectedAccounts = results[1];
        const connectedAccountsHistory = results[2];
        const getRelationsByID = results[3];
      
        // Get the connected acounts

        const datasourcesList = this.filterConnectedAccounts(
          dataSources,
          connectedAccounts
        );

        let res = {
          connectedAccounts,
          datasourcesList,
          getRelationsByID
        };
        
        // Checks for Success Logins or Errors

        res = this.handleChecksForErrorsAndSuccessLogins(connectedAccountsHistory, res);
        
        this.setState({
          findConnectionFields: fields,
          hasError: res.hasError,
          errorTitle: res.errorTitle,
          errorMessage: res.errorMessage,
          errorModalButtonAction: res.errorModalButtonAction,
          dsList: datasourcesList,
          connectedAccounts,
          isLoading: false,
          changeNickname: res.changeNickname,
          editNicknameTitle: res.editNicknameTitle,
          editNicknameDesc: res.editNicknameDesc,
          changeNicknameValues: res.changeNicknameValues,
          expiredAccountWithNewCredModalOpen: res.expiredAccountWithNewCredModalOpen,
          dataSource: res.dataSource,
        });
      })
      .catch((e) => {
        if (e.status && e.status !== 401) {
          this.setState({
            isLoading: false,
            hasError: true,
            errorTitle: i18n.t("findConnections_failure_load_title"),
            errorMessage: i18n.t("findConnections_failure_load_desc"),
            errorModalButtonAction: this.handleCancelButton,
            isOpenConnectionRequiredModal: false,
          });
        }
      });
    }
  };

  // ========================================
  // Multiple Accounts Actions  Start 
  // ========================================

  handleChecksForErrorsAndSuccessLogins = (connectedAccountsHistory, DataObj) => {
    let { 
      walletAccountName,
      hasError,
      errorTitle,
      errorMessage,
      errorModalButtonAction,
    } = this.state;

    const {
      dataSource,
      isLoginFailedError
    } = this.props;

    let selectedDS = DatasourceService.getSelectedDs();

    // If trying to use a DSA which is laready claimed in another account

    if (isLoginFailedError && isLoginFailedError.status === 403) {
      DataObj.hasError = true;
      DataObj.errorTitle = i18n.t("connect_rs__failure_alreadyused_in_other_wallet_403_title");
      DataObj.errorMessage = i18n.t("connect_rs__failure_alreadyused_in_other_wallet_403_desc", {
        rsName: selectedDS.name || selectedDS.data_source.name,
        walletAccountName });
    } else {

      // 3. Checks after succesful login/No errors
      // 3.1 Checks for login credentials

      if (dataSource) {

        DataObj.dataSource = dataSource;

        // Analytics
        const analyticText = dataSource.selected_connection_type === "RENEW_CONNECTION" ? "Expired required data source modal - Renew" : "Required data source modal - Log in";
        this.handleAnalyticsTrack("onClick_event", analyticText);

          DataObj = this.handleCheckLoginCredentials(connectedAccountsHistory, selectedDS, DataObj, hasError, errorTitle, errorMessage, errorModalButtonAction);

      }
    }

    return DataObj;
  }

  handleCheckLoginCredentials = (connectedAccountsHistory, selectedDS, dataSourceState, hasError, errorTitle, errorMessage, errorModalButtonAction) => {
    let { 
      dataSource,
      changeNickname,
      expiredAccountWithNewCredModalOpen,
      changeNicknameValues
    } = this.state;

    let result = {
      changeNickname,
      editNicknameTitle: i18n.t("find_connection_edit_nickname_modal__title", { dsName: dataSourceState?.dataSource?.data_source?.name }),
      editNicknameDesc: i18n.t("edit_nickname_modal__new_connection_desc"),
      changeNicknameValues,
      expiredAccountWithNewCredModalOpen,
      hasError, 
      errorTitle, 
      errorMessage,
      errorModalButtonAction,
      isOpenConnectionRequiredModal: dataSourceState.isOpenConnectionRequiredModal,
      isExpiredConnectionRequired: dataSourceState.isExpiredConnectionRequired,
      connectionRequiredModalTitle: dataSourceState.connectionRequiredModalTitle,
      connectionRequiredModalBody: dataSourceState.connectionRequiredModalBody,
      connectionRequiredModalButton1: dataSourceState.connectionRequiredModalButton1,
      connectionRequiredModalButton2: dataSourceState.connectionRequiredModalButton2,
      dataSource: dataSourceState.dataSource,
    };

    if (dataSource && dataSource.ds_account_id) {
 
      // 0 Check for delegated accounts
    
      const delegatedAccounts = delegatedAccountsCheck(dataSourceState.connectedAccounts, dataSource, dataSourceState.getRelationsByID);

      result.dataSource.delegatedAccounts = delegatedAccounts;

      // 0.1 Add default nickname for the connected account

      result.changeNicknameValues = { 
        [dataSource.ds_account_id]: { nickname: dataSource.nickname } 
      };
   
      // 0.2 Add default nickname for delegated accounts

      if (delegatedAccounts.length) {
        delegatedAccounts.forEach(delegatedAccount => {
          result.changeNicknameValues = { 
            ...result.changeNicknameValues,
            [delegatedAccount.ds_account_id]: { nickname: delegatedAccount.nickname }
          };
        })
      }
      
      // 1. Open Edit Nickname modal for a new connection
        result = this.handleOpenEditNicknameModalForNewConnection(dataSource, result)

      if (connectedAccountsHistory && connectedAccountsHistory.length) {
      
        const foundConnectedAccount = connectedAccountsHistory.filter(account => (account.ds_account_id === dataSource.ds_account_id));

        // 2. IF Same Credentials to renew
        if (dataSource.ds_account_id === selectedDS.ds_account_id) {
          if (dataSource.selected_connection_type === "RENEW_CONNECTION") {
            result.changeNickname = true;
            result.editNicknameTitle = i18n.t("edit_nickname_modal__renew_connection_title");
            result.editNicknameDesc = i18n.t("edit_nickname_modal__renew_connection_desc");
            result.isOpenConnectionRequiredModal = false;
            result.isExpiredConnectionRequired = false;
          } 
        } else {

          // 3. IF New Credentials to connect/renew Check Cases
          result = this.handleNewCredentialsToConnectRenewChecks(foundConnectedAccount, result);

        }
          
        // 4. IF New Credentials to Renew a connection

        result = this.handleNewCredentialsToRenewConnection(foundConnectedAccount, dataSource, result);
      }    
    }

    return result;
  }

  // ==============================================
  // handleCheckLoginCredentials Secondary Methods
  // ==============================================

  handleOpenEditNicknameModalForNewConnection = (dataSource, DataObj) => {

    if (dataSource.selected_connection_type !== "RENEW_CONNECTION" && !dataSource.expired) {

      DataObj.changeNickname = true;
      DataObj.isOpenConnectionRequiredModal = false;
      DataObj.isExpiredConnectionRequired = false;
    }

    return DataObj;
  }

  handleNewCredentialsToConnectRenewChecks = (foundConnectedAccount, DataObj) => {

    // 3.1 IF New Credentials to connect/renew but those cred already used in another which is connected
    
    if (foundConnectedAccount.length) {
      if (!foundConnectedAccount[0].expired && !foundConnectedAccount[0].disabled) {
        DataObj.hasError = true;
        DataObj.errorTitle = i18n.t("connect_rs__failure_already_connected_title");
        DataObj.errorMessage = i18n.t("connect_rs__failure_already_connected_desc");
        DataObj.errorModalButtonAction = this.handleToggleErrorModal;
        DataObj.changeNickname = false;
        DataObj.isOpenConnectionRequiredModal = false;
        DataObj.isExpiredConnectionRequired = false;
      } else {

        // 3.2. IF New Credentials to connect/renew but those cred already used in another which is not connected

        DataObj.expiredAccountWithNewCredModalOpen = true;
        DataObj.changeNickname = false;
        DataObj.isOpenConnectionRequiredModal = false;
        DataObj.isExpiredConnectionRequired = false;
      }
    } 

    return DataObj;
  }

  handleNewCredentialsToRenewConnection = (foundConnectedAccount, dataSource, DataObj) => {

    if (!foundConnectedAccount.length && dataSource.selected_connection_type === "RENEW_CONNECTION") {
      DataObj.expiredAccountWithNewCredModalOpen = true;
      DataObj.changeNickname = false;
      DataObj.isOpenConnectionRequiredModal = false;
      DataObj.isExpiredConnectionRequired = false;
    }

    return DataObj;
  }

  filterConnectedAccounts = (datasources, connectedAccounts) => {
    let filterDataSources = datasources;

    filterDataSources = filterDataSources.length && filterDataSources.map((ds) => {
      let tempConnectedAccounts = [];

      connectedAccounts.length && connectedAccounts.map((connectedAccount) => {
        if (ds.data_source_id === connectedAccount.data_source.data_source_id) {
          tempConnectedAccounts.push(connectedAccount);
        }

        return connectedAccount;
      });

      if (tempConnectedAccounts.length > 1) {
        // Alphabatical sort for connected accounts
        tempConnectedAccounts.sort((a, b) =>
          a.nickname
            .toLowerCase()
            .localeCompare(b.nickname.toLowerCase(), undefined, {
              numeric: true,
              sensitivity: "base",
            })
        );

        // # Sort by Expired
        tempConnectedAccounts.sort((a, b) => a.expired - b.expired);

        // # Sort by Disabled
        tempConnectedAccounts.sort(
          (a, b) => Boolean(a.disabled) - Boolean(b.disabled)
        );
      }

      ds.connectedAccounts = tempConnectedAccounts;
      return ds;
    });

    filterDataSources = filterDataSources.sort((a, b) => {
      if (b.name.toLowerCase() > a.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });

    return filterDataSources;
  };

  // Search
  handleOnChange = (e) => {
    this.setState({
      queryString: e.target.value ? e.target.value : "",
      isLoading: true
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { queryString } = this.state;

    if ((prevState.queryString !== queryString) && queryString) {
      this.handleSearch(queryString);
    }

    if (prevState.queryString && !queryString) {
      this.setState({
        searchResults: [], 
        isLoading: false
      });
    }
  };

  handleSearch = debounce((query) => {
    const { dsList } = this.state;
  
    //Analytics
    this.handleAnalyticsTrack("onSubmit_event", `Search connections - ${query}`);

    let tmpResults = [];

    // Search By Connection name

    for (let dsItem of dsList) {
      for (let key in dsItem) {
        if (key === "name" && dsItem[key].toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) {
          tmpResults.push(dsItem);
          break;
        }

        // Search Connected Accounts

        const result = this.searchConnectedAccounts(key, dsItem, query);
      
        if (result) {
          tmpResults.push(result);
        }
      }
    }

    return this.setState({
      searchResults: tmpResults,
      isLoading: false
    });
  }, 500);


  searchConnectedAccounts = (key, dsItem, query) => {
    let tmpResults;

    if (key === "connectedAccounts" && Boolean(dsItem[key].length)) {
      dsItem[key].map((item) => {
        if (item.nickname.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) {
          tmpResults = dsItem;
        }

        return item;
      });
    }

    return tmpResults;
  }

  handleCancelButton = () => {
    //Analytics
    this.handleAnalyticsTrack("onClick_event", "Cancel Button");

    const { previousPage } = this.props;

    let tempPrevpage = "profile";

    if (previousPage === "CONNECTIONS_PAGE") {
      tempPrevpage = "my-activity";
    }

    this.props.history.push(window.GLOBAL_PATH + tempPrevpage);
  };

  handleConnectRsAction = async (account, connectionType) => {
    const { connectedAccounts } = this.state;

    try {
      // Spinner
      this.setState({
        isSendingReq: true,
      });

      let tempDataSourceID = account.data_source_id;
      let tempAnalyticsDesc = account.name;

      if (account.data_source) {
        tempDataSourceID = account.data_source.data_source_id;
        tempAnalyticsDesc = `${account.data_source.name} - ${account.nickname}`;
      }

      //Analytics
      this.handleAnalyticsTrack("onClick_event", tempAnalyticsDesc);
      
      const result = await DatasourceService.requestConnection(
        tempDataSourceID,
        "FIND_CONNECTIONS",
        connectedAccounts,
        account,
        connectionType
      );

      document.location = result.auth_request;

      this.setState({ clickedDs: account });
    } catch (e) {
      if (e.status && e.status !== 401) {
        this.setState({
          isSendingReq: false,
          hasError: true,
          errorTitle: i18n.t("findConnections_connection_failure_title"),
          errorMessage: i18n.t("findConnections_connection_failure_desc"),
        });
      }
    }
  };

  handleOnNicknameChange = (e) => {
    const { changeNicknameValues } = this.state;
    const { name, value } = e.target; 

    this.setState({ changeNicknameValues: { ...changeNicknameValues, [name]: { "nickname": value } } })
  }

  handleToggleErrorModal = () => {
    // Clearing browser state
    window.history.pushState("isLoginFailedError", null);

    // Remove Browser History
    this.handleRemoveHistory();

    this.setState({ isLoginFailedError: false, hasError: false });
  };

  handleEditNickNameAction = async (nicknameValues) => {
    let { dsList, connectedAccounts } = this.state;

    let DataObj = {
      dsList,
      hasError: false,
      changeNickname: false,
      errorTitle: "",
      errorMessage: "",
      nicknameValues
    }

    // Remove Browser History
    this.handleRemoveHistory();

    // Clearing browser state 
    window.history.replaceState("state", null);

    // Spinner
    this.setState({ isSendingReq: true });

    let promisesArr = [];
    
    for (let connectedAccount of connectedAccounts) { 
      for (const key in DataObj.nicknameValues) {
        if ((connectedAccount.ds_account_id === key) && (connectedAccount.nickname !== nicknameValues[key].nickname)) {
          promisesArr.push(
            new Promise((resolve, reject) => {
              DatasourceService.editNickname(key, nicknameValues[key].nickname).then((result) => {
                resolve(result);
              })
              .catch((e) => {
                e.ds_account_id = key;
                reject(e);
              });
            })
          );
        }
      }
    }
    
    // Get the result for all the nickname methods
    
    await PromiseAllsettled(promisesArr).then(allResults=> {
      
      // 1. Update the Nickname
      
      DataObj = this.handleUpdateNicknameForList(DataObj, allResults);
      
      // 2. Filter If we have a error except 409
      const except409ErrorFilter = allResults.filter(item => ((item.status === "rejected") && (item.reason.status !== 409)));
      
      if (except409ErrorFilter.length) {
        DataObj.changeNickname = false;
        DataObj.hasError = true;
        DataObj.errorTitle = i18n.t("edit_nickname_modal__failure_nickname_update_title");
        DataObj.errorMessage = i18n.t("edit_nickname_modal__failure_nickname_update_desc");
      }
    });
    
    this.setState({
      dsList: DataObj.dsList,
      changeNickname: DataObj.changeNickname,
      changeNicknameValues: DataObj.nicknameValues,
      isSendingReq: false,
      hasError: DataObj.hasError,
      errorTitle: DataObj.errorTitle,
      errorMessage: DataObj.errorMessage,
    });
  };

  //===========================================
  // Secondary functions for editnickname Start
  //===========================================

  handleUpdateNicknameForList = (DataObj, allResults) => {
    DataObj.dsList.map((dsListItem) => {
      dsListItem.connectedAccounts.map((connectedAccount) => {
        for (let resultItem of allResults) { 

          // For Fulfiled succesful calls
          if (resultItem.status === "fulfilled" && connectedAccount.ds_account_id === resultItem.value.ds_account_id) {

            //Analytics
            this.handleAnalyticsTrack("onClick_event", `Submit edit nickname with ${resultItem.value.nickname} for ${resultItem.value.ds_account_id}`);

            connectedAccount.nickname = resultItem.value.nickname;
          }

          // For Failed 409 error calls

          if (resultItem.status === "rejected") {
            DataObj.hasError = false;
            DataObj.changeNickname = true;

            if (resultItem.reason.status === 409) {
              DataObj.nicknameValues[resultItem.reason.ds_account_id].error = i18n.t("edit_nickname_modal__failure_nickname_409_title");
            } 
          }
        }
        
        return connectedAccount;
      });

      return dsListItem;
    });

    return DataObj;
  }

  //===========================================
  // Secondary functions for editnickname End
  //===========================================

  handleDisconnectExpiredAccountWithNewCredModal = async () => {
    let { dataSource, dsList } = this.state;

    // Remove Browser History
    this.handleRemoveHistory();

    try {
      const result = await DatasourceService.disableMyDatasourceAccount(
        dataSource.ds_account_id
      );

      dsList = dsList.map((ds) => {
        ds.connectedAccounts.map((connectedAccount) => {
          if (connectedAccount.ds_account_id === result.ds_account_id) {
            connectedAccount.disabled = result.disabled;
          }

          return connectedAccount;
        });

        return ds;
      });

      this.setState({
        expiredAccountWithNewCredModalOpen: false,
        dsList,
      });
    } catch (error) {
      if (error.status !== 401) {
        this.setState({
          hasError: true,
          errorTitle: i18n.t("connectionDetail__failure_disconnect_title"),
          errorMessage: i18n.t("connectionDetail__failure_disconnect_desc"),
          isSendingReq: false,
        });
      }
    }
  };

  handleCloseExpiredAccountWithNewCredModal = () => {
    const { dsList, dataSource } = this.state;

    // Upate the connected Accounts
    dsList.length &&
      dsList.map((ds) => {
        if (dataSource.data_source_id === ds.data_source_id) {
          ds.connectedAccounts.push(dataSource);
        }

        return ds;
      });

    this.setState({
      dsList,
      changeNickname: true,
      expiredAccountWithNewCredModalOpen: false,
    });
  };

  handleRemoveHistory = () => {
    DatasourceService.removeConnectedAccountsHistory();
  };

  renderActionButton = (account, isConnectedAccounts) => {
    const { isSendingReq, clickedDs } = this.state;

    const { isInternetDisconnected, handleToggleNetworkModal } = this.props;

    let isReconnectBtn = false;
    let handleSubmitAction = this.handleConnectRsAction;
    let buttonType = "CONNECT_CONNECTION";
    let tempAccount = account;
    let dsAccountID = account && account.data_source_id;
    let isAddButton = false;
    let tempClickedDs = clickedDs && clickedDs.data_source_id;
    let isConnectedText = false;

    // 1. Show Add button
    if (account.connectedAccounts && account.connectedAccounts.length) {
      isAddButton = true;
    }

    if (isConnectedAccounts) {
      isAddButton = false;
      dsAccountID = account.ds_account_id;
      tempClickedDs = clickedDs.ds_account_id;

      // 2. Connected Text
      if (account.expired === false && !account.disabled) {
        isConnectedText = true;
      }

      // 3. Reconnect Button
      if (account.expired === true || account.disabled) {
        buttonType = "RENEW_CONNECTION";
        isReconnectBtn = true;
      }
    }

    return (
      <ConnectRenewButton
        isSendingReq={isSendingReq}
        clickedRsID={tempClickedDs}
        dsAccountID={dsAccountID}
        isInternetDisconnected={isInternetDisconnected}
        handleToggleNetworkModal={handleToggleNetworkModal}
        isReconnectBtn={isReconnectBtn}
        handleSubmitAction={handleSubmitAction}
        buttonType={buttonType}
        account={tempAccount}
        isAddButton={isAddButton}
        isConnectedText={isConnectedText}
      />
    );
  };

  handleRenderBreadcrumbPreviouspage = () => {
    const { previousPage } = this.props;

    let tempPrevpage = (
      <Link
        to={window.GLOBAL_PATH}
        className="breadcrumb-link textPrimaryColor"
      >
        {i18n.t("sidebar__my_account")}
      </Link>
    );

    if (previousPage === "CONNECTIONS_PAGE") {
      tempPrevpage = (
        <Link
          to={window.GLOBAL_PATH + "my-activity"}
          className="breadcrumb-link textPrimaryColor"
        >
          {i18n.t("sidebar__my_activity")}
        </Link>
      );
    }

    return tempPrevpage;
  };

  // Tracking

  handleAnalyticsTrack = (type, action) => {
    AnalyticsService.track(type, {
      context: 'Wallet',
      page: "Add Connection Page",
      actions: action,
      url: window.location.hostname + window.location.pathname
    })
  }

  render() {
    const {
      isLoading,
      dsList,
      findConnectionFields,
      changeNickname,
      editNicknameTitle,
      editNicknameDesc,
      hasError,
      errorTitle,
      errorMessage,
      errorButtonText,
      errorModalButtonAction,
      searchResults,
      queryString,
      limit,
      isSendingReq,
      dataSource,
      expiredAccountWithNewCredModalOpen,
      changeNicknameValues,
    } = this.state;

    const { 
      isInternetDisconnected, 
      handleToggleNetworkModal, 
      handleReload 
    } = this.props;

    let tableData = [];
    let noDataText;
    
    // Data preparation
    if (searchResults.length && queryString) {
      // #. Search Results

      tableData = searchResults;
    } else {

      // #. Regular list
      tableData = dsList;
    }

    // Error Messages
    // # If internet disconnected

    if (isInternetDisconnected && !dsList.length) {
      noDataText = (
        <div className="noResultErrorMessage">
          <h3>{i18n.t("connections__internet_failure_load_title")}</h3>
          <p>{i18n.t("connections__internet_failure_load_desc")}</p>
          <button className="btn backgroundPrimaryColor" onClick={handleReload}>
            {i18n.t("reload")}
          </button>
        </div>
      );
    }

    // # No data to show

    if (!isLoading && !dsList.length) {
      noDataText = (
        <div div className="noResultErrorMessage">
          <h3>{i18n.t("findConnections__connection_not_found_title")}</h3>
          <p>{i18n.t("findConnections__connection_not_found_desc")}</p>
        </div>
      );
    }
    
    return (
      <div className="wrapper find-connection-container">
        <div className="connectionDetail-breadcrumb">

          {/* breadcrumb */}

          {
            this.handleRenderBreadcrumbPreviouspage()
          }
        
          <span className="icon-chevron"></span>
          <p className="textPrimaryColor">{i18n.t("findConnections__title")}</p>
        </div>

        <h1>{i18n.t("findConnections__title")}</h1>

        {/* Search Input */}

        <SearchBar
          handleOnChange={this.handleOnChange}
          queryString={queryString}
        />

        {/* Sub heading */}

        <div className="sub-content">
          <p className="sub-heading">
            {i18n.t("findConnections__multiple_accounts_enable")}
          </p>
          <p className="sub-heading">
            {i18n.t("findConnections__multiple_accounts_enable_desc")}
          </p>
        </div>

        {/* Connections Table */}

        <DataTable
          noDataText={noDataText}
          errorMessage={errorMessage}
          isSearchable={true}
          searchResults={searchResults}
          queryString={queryString}
          isLoading={isLoading}
          isInternetDisconnected={isInternetDisconnected}
          tableData={tableData}
          findConnectionFields={findConnectionFields}
          paginationPerPage={limit}
          isMobileVersion={true}
          renderActionButton={this.renderActionButton}
        />

        {/* Footer Section */}

        <div className="footer">
          <button
            className="textPrimaryColor"
            onClick={this.handleCancelButton}
          >
            {i18n.t("findConnections__cancel")}
          </button>
        </div>

        
        {/* ====== */}
        {/* MOdals */}
        {/* ====== */}

        {
          window.config.nickname_screen
          &&
          changeNickname 
          && 
          <EditNicknameModalConsent
            isOpen={changeNickname}
            handleEditNicknameAction={this.handleEditNickNameAction}
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            title={editNicknameTitle}
            description={editNicknameDesc}
            nicknameValues={changeNicknameValues}
            handleOnChange={this.handleOnNicknameChange}
            dsName={dataSource.data_source.name}
            logo_url={dataSource.data_source.style.logo_url}
            dsAccount={dataSource}
          />
        }

        {
          hasError
          && 
          <ErrorModal
            isOpen={hasError}
            errorTitle={errorTitle}
            errorMessage={errorMessage} 
            errorButtonText={errorButtonText}
            handleToggleModal={errorModalButtonAction}
          />
        }

        {
          expiredAccountWithNewCredModalOpen 
          && 
          <NewAccountConnectionModal
            isOpen={expiredAccountWithNewCredModalOpen}
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            dataSource={dataSource}
            handleCloseExpiredAccountWithNewCredModal={this.handleCloseExpiredAccountWithNewCredModal}
            handleDisconnectExpiredAccountWithNewCredModal={this.handleDisconnectExpiredAccountWithNewCredModal}
          />
        }
      </div>
    );
  }
}

export default withRouter(FindConnectionContainer);
