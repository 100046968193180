  import React from "react";

// Lib
import Select, { components } from 'react-select';

const CustomSelect = ({ className, Name, Required, Label, Value, Placeholder, handleOnChange, SelectOptions }) => {

  // Select Custom icon for web
  const DropdownIndicator = props => {
    return (
      <div className="dropdown-indicator">
        <components.DropdownIndicator {...props}>
          <div className="sortIcon textPrimaryColor">&#9662;</div>
        </components.DropdownIndicator>
      </div>
    );
  };
  
  return (
    <Select
      role="text"
      classNamePrefix="react-select"
      placeholder={Placeholder}
      className={className}
      aria-labelledby={Placeholder}
      inputId={Name}
      name={Name}
      onChange={handleOnChange}
      options={SelectOptions}
      defaultValue={Value || ""}
      isClearable={false}
      isSearchable={false}
      required={Required}
      components={{ 
        DropdownIndicator, 
        IndicatorSeparator: null 
      }}
    />
  );
}

export default CustomSelect;