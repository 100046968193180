import axios from "axios";
import { AuthService } from "./AuthService";

let ProfileApi = {};

var getEndpoint = function() {
  return window.config.url;
};

ProfileApi.getMyProfile = function () {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/profile`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

ProfileApi.selfDelete = function (reason) {
  return new Promise((resolve, reject) => {
    axios
      .delete("/me/delete", {
        baseURL: getEndpoint(),
        params: { reason },
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

ProfileApi.postCredentialConnections = function (connection_jwt) {
  return new Promise((resolve, reject) => {
    axios
      .post("/me/verifiable-credentials/connections", { connection_jwt }, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
}

ProfileApi.getCredentialOffers = function (state) {  
  return new Promise((resolve, reject) => {
    axios
      .get("/me/verifiable-credentials/credential-offers", {
        baseURL: getEndpoint(),
        params: { state },
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

ProfileApi.acceptCredentialOffer = function (credOfferId, accept) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/me/verifiable-credentials/credential-offer/${credOfferId}?accept=${accept}`, "", {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

export default ProfileApi;