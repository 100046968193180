import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// Services
import ConsentRequestService from "../../Services/ConsentRequestService";
import DatasourceService from "../../Services/DatasourceService";
import ProfileService from "../../Services/ProfileService";

// Components
import DeclineModal from "../../DeclineModal";
import ErrorModal from "../../ErrorModal";
import EditNicknameModalConsent from "../../EditNicknameModalConsent";
import NewAccountConnectionModal from "../../NewAccountConnectionModal";
import RsList from "../presentation/RsList";
import ActionButtons from "../presentation/ActionButtons";
import Header from "../../Header";
import Spinner from "../../Spinner";
import ConsentHeader from "../presentation/ConsentHeader";
import ConsentSubHeader from "../presentation/ConsentSubHeader"
import ConsentFooter from "../presentation/ConsentFooter";

// Lib
import i18n from "../../../i18n";
import { SessionStorage } from "../../Services/BrowserStorage";
import AnalyticsService from "analytics-web";

// Utils
import  { delegatedAccountsCheck } from  "../../../utils/DelegatedAccounts";
import PromiseAllsettled from "../../../utils/PromiseAllsettled";

class ConsentContainer extends Component {
  constructor(props) {
    super(props);

    // Get the selected ID
    let selectedId = null;
    let dataSourceHistory = {};
    let isLoginFailedError = {};
  
    if (props.location.state) {
      selectedId = props.location.state.dsAccountid;
      dataSourceHistory = props.location.state.dataSource;
      isLoginFailedError = props.location.state.error;
    }
    // 1. Get the Request from token history
    const tokenHistory = ConsentRequestService.tokenHistory();
    
    let request

    request = tokenHistory && ConsentRequestService.currentRequestDetailsFromTokenHistory(props.transactionID, tokenHistory);
  
    // 2. Check the if there is any previous state available in storage
    request = this.checkStorageForHistory(request);
  
    // 3. Api Calls
    const acceptAccessToResource = this.setupRequestAcceptingAll(request, selectedId, dataSourceHistory, isLoginFailedError);
    this.state = {
      request: request,
      datasource: {},
      acceptAccessToResource: acceptAccessToResource,
      connectedAccounts: [],
      datasources: [],

      isScopeModalOpen: false,
      scopeModalID: null,
      isLoadingConnection: true,
      isOpenDeclineModal: false,
      isDeclineRequest: false,

      hasError: false,
      errorTitle: "",
      errorMessage: "",
      errorButtonText: i18n.t("ok"),

      isSendingReq: false,
      shouldDateSourceSelectorModalOpen: false,

      dataSourceHistory,
      changeNickname: false,
      editNicknameTitle: "",
      editNicknameDesc: "",
      changeNicknameValues: {},
      expiredAccountWithNewCredModalOpen: false,
      toggleErrorModal: null,
      isLoginFailedError,

      consent_configs: window.config.consent_configs,
      current_transaction_ID: props.transactionID,

      isIDPFlow: true,
      isAutoAccept: false,
      isAutoConnect: false
    };
  }

  checkStorageForHistory = (request) => {
    const requestHistory = ConsentRequestService.getRequestHistory(request);

    if (requestHistory) {
      return JSON.parse(requestHistory);
    }

    return request;
  }

  // IDP Flow
  handleIDPFlow = async (chosenSource, changeNicknameValues, request, rsList, filteredDsAccounts, datasources, readyToSubmit) => {
    const hasDataSource = (request)?.requested_resources?.some(rs => rs.dataSource);
    const availableResource = DatasourceService.handleSources(datasources, filteredDsAccounts, rsList[0]);
    const hasChosenSource = chosenSource && Object.keys(chosenSource).length !== 0;

    // Autoaccept flow for autofilled content
    if (readyToSubmit) {
      this.pushPermissionsAction();
      this.setState({isAutoAccept: readyToSubmit})
      return;
    }
    // Autoconnect flow
    if(!hasDataSource && !hasChosenSource) {
      // Redirects for autoconnect
      if(availableResource[0]) this.handleConnectRsAction(availableResource[0], 'CONNECT_CONNECTION');
      this.setState({isAutoConnect: !hasDataSource})
      return;
    }

    // Autoaccept flow for skipping nickname
    if (hasChosenSource) {
      this.handleEditNickNameAction(changeNicknameValues);
      rsList.forEach((resource) => {
        this.onDataSourceSelect(this.state.datasource, resource ,request);
        return;
      });
      this.pushPermissionsAction();
      this.setState({isAutoAccept: chosenSource && hasDataSource})
    }
  }

  handleConnectRsAction = async (account, connectionType) => {
    const { connectedAccounts, current_transaction_ID } = this.state;
    const tempDataSourceID = account.data_source ? account.data_source.data_source_id : account.data_source_id;
    try {
      const consentRedirectLink = await DatasourceService.requestConnection(tempDataSourceID, "", connectedAccounts, account, connectionType, current_transaction_ID, {auto: true});
      if (consentRedirectLink) this.handleSuccessRedirect(consentRedirectLink.auth_request);
    } catch (e) {
      console.log('e', e)
    }
  };

  transformRsListReadyToSubmit = (resources, acceptAccessToResource) => {
    let rslist = [];
    let readyToSubmit = false;
    
    if (resources && resources.length) {
      rslist = resources.sort((a, b) => a.resource_definition.name.localeCompare(b.resource_definition.name, 0, { numeric: false }));   
  
      //sorting scopes by display_name
  
      rslist.map(li => li.scopes_requested.sort((a, b) => a.display_name?.localeCompare(b.display_name, 0, { numeric: false})));
      
      // Authorization button....
      for (let req_resource of resources) {
        if (req_resource.dataSource && acceptAccessToResource[req_resource.resource_definition.res_def_id]) {
          readyToSubmit = true; 
          break;
        }
      }
    }

    // 2. If all rs is disabled

    if (readyToSubmit) {
      const formattedAcceptAccessToResource = Object.values(acceptAccessToResource);
      const isAllDisabled = formattedAcceptAccessToResource.filter(res => {
        return res === true;
      });
      
      readyToSubmit = isAllDisabled.length;
    }
    return [rslist, readyToSubmit];
  }

  componentDidMount = () => {
    //Analytics
    this.handleAnalyticsTrack("onLoad_event", "", "");
  }

  setupRequestAcceptingAll = (request, selectedId, dataSourceHistory, isLoginFailedError) => {
    if(!request) { 
      return []; 
    } else {
      
      // 1. Get user data sources
      DatasourceService.getMyDatasourceAccounts().then(async (accounts) => {
        
        // 1.0.1 Sort based on disabled enabled
        let filteredDsAccounts = accounts.sort((a, b) => Boolean(a.disabled) - Boolean(b.disabled));
        let errorTitle;
        let errorMessage;
        let toggleErrorModal;
        let tempChangeNickname = false;
        let tempEditNicknameTitle = i18n.t("find_connection_edit_nickname_modal__title", { dsName: dataSourceHistory?.dataSource?.data_source?.name });
        let tempEditNicknameDesc = i18n.t("edit_nickname_modal__new_connection_desc");
        let tempExpiredAccountWithNewCredModalOpen = false;
        let tempHasError = false;
        let tempShouldDateSourceSelectorModalOpen = true;
        let selectedDS = DatasourceService.getSelectedDs();
        let tempChangeNicknameValues;
  
        // 1.1 Check expiry
        filteredDsAccounts.map(account => DatasourceService.isAccountExpired(account));
  
        // 1.2 Check the request history if its expired or not
        // Or if the account is disabled
        ConsentRequestService.checkForDisabledExpiredAcc(request, filteredDsAccounts)
  
        // 1.3 Find chosen one
        const chosenSource = filteredDsAccounts.find(x => x.ds_account_id === selectedId);
  
        if (chosenSource) {
          this.setState({ datasource: chosenSource });
        }
        
        // 1.4 Datasource History to compare
        if (isLoginFailedError && isLoginFailedError.status === 403) {
          errorTitle = i18n.t("connect_rs__failure_alreadyused_in_other_wallet_403_title");
          errorMessage = i18n.t("connect_rs__failure_alreadyused_in_other_wallet_403_desc", {
            rsName: selectedDS.name || selectedDS.data_source.name,
            walletAccountName: window.config.wallet_account_name
          });
          toggleErrorModal = this.handleToggleErrorModal;
          tempShouldDateSourceSelectorModalOpen = false;
          tempHasError = true;
        }
        
        // 1.4 Compare the history
        if (dataSourceHistory && dataSourceHistory.ds_account_id && !dataSourceHistory.error) {
  
          // 1.5. Call to get the relations
          
          const getRelationsByID = await DatasourceService.getDatasourceByIDRelations(dataSourceHistory.data_source.data_source_id);
  
          // 1.6 Check for delegated accounts
          
          const delegatedAccounts = delegatedAccountsCheck(filteredDsAccounts, dataSourceHistory, getRelationsByID);
          
          dataSourceHistory.delegatedAccounts = delegatedAccounts;
  
          // 1.7 Add default nickname for the connected account
  
          tempChangeNicknameValues = { 
            [dataSourceHistory.ds_account_id]: { nickname: dataSourceHistory.nickname } 
          };
  
          // 1.8 Add default nickname for delegated accounts
          
          if (delegatedAccounts.length) {
            delegatedAccounts.forEach(delegatedAccount => {
              tempChangeNicknameValues = { 
                ...tempChangeNicknameValues,
                [delegatedAccount.ds_account_id]: { nickname: delegatedAccount.nickname }
              };
            })
          }
  
          // Get the Connected Accounts history
          const connectedAccountsHistory = await DatasourceService.getConnectedAccountsHistory();
          // 1. Open Edit Nickname modal for a new connection
          if (dataSourceHistory.selected_connection_type !== "RENEW_CONNECTION") {
            tempChangeNickname = true;
            tempShouldDateSourceSelectorModalOpen = false;
          }
          
          if (connectedAccountsHistory && connectedAccountsHistory.length) {
            
            const foundConnectedAccount = connectedAccountsHistory.filter(account => (account.ds_account_id === dataSourceHistory.ds_account_id));
            
            // 2. IF Same Credentials to renew
            if (dataSourceHistory.ds_account_id === selectedDS.ds_account_id) {
              if ((selectedDS.expired || selectedDS.disabled) && dataSourceHistory.selected_connection_type === "RENEW_CONNECTION") {
                tempChangeNickname = true;
                tempEditNicknameTitle = i18n.t("edit_nickname_modal__renew_connection_title");
                tempEditNicknameDesc = i18n.t("edit_nickname_modal__renew_connection_desc");
                tempShouldDateSourceSelectorModalOpen = false;
              } 
            } else {
  
              // 3. IF New Credentials to connect/renew but those cred already used in another which is connected
              if (foundConnectedAccount.length) {
                if (!foundConnectedAccount[0].expired && !foundConnectedAccount[0].disabled) {
                  errorTitle = i18n.t("connect_rs__failure_already_connected_title");
                  errorMessage = i18n.t("connect_rs__failure_already_connected_desc");
                  tempChangeNickname = false;
                  tempHasError = true;
                  toggleErrorModal = this.handleToggleErrorModal;
                  tempShouldDateSourceSelectorModalOpen = false;
                } else {
  
                  // 4. IF New Credentials to connect/renew but those cred already used in another which is not connected
      
                  tempExpiredAccountWithNewCredModalOpen = true;
                  tempChangeNickname = false;
                  tempShouldDateSourceSelectorModalOpen = false;
                }
              } 
            }
  
            // 5. IF New Credentials to Renew a conenction
            if (!foundConnectedAccount.length && dataSourceHistory.selected_connection_type === "RENEW_CONNECTION") {
              tempExpiredAccountWithNewCredModalOpen = true;
              tempChangeNickname = false;
              tempShouldDateSourceSelectorModalOpen = false;
            }
          }
        } 
   
        // 1.4 Auto select connected ones
        const tempRequest = this.autoSelectConnectedResources(filteredDsAccounts, request);
        
        this.setState({
          hasError: tempHasError,
          changeNickname: tempChangeNickname,
          editNicknameTitle: tempEditNicknameTitle,
          editNicknameDesc: tempEditNicknameDesc,
          changeNicknameValues: tempChangeNicknameValues,
          errorTitle,
          errorMessage,
          toggleErrorModal,
          expiredAccountWithNewCredModalOpen: tempExpiredAccountWithNewCredModalOpen,
          shouldDateSourceSelectorModalOpen: tempShouldDateSourceSelectorModalOpen,
          connectedAccounts: filteredDsAccounts,
          isLoadingConnection: false,
          request: tempRequest
        })
      }).catch(error => {
        if (error && error.status !== 401) {
          this.setState({
            hasError: true,
            errorTitle: i18n.t("consent__loading_list_error_title"),
            errorMessage: i18n.t("consent__loading_list_error_desc"),
          })
        }
      });
      
      // Get all data sources
      DatasourceService.getAllDatasources().then(datasources => {
        this.setState({ datasources });
      }).catch(e => e)
  
      // create map where resource_req_id = true
  
      if (request.requested_resources) {
        return request.requested_resources.reduce((acc, obj) => {
          acc[obj.resource_definition.res_def_id] = true; return acc
        }, {});
      }
    }
  }


  componentDidUpdate = async (prevProps, prevState) => {
    if (JSON.stringify(prevState.datasources) !== JSON.stringify(this.state.datasources)) {
      // isIDPFlow determines if it should continue with autoaccept/autoconnect flow
      const myProfile = await ProfileService.getMyProfile();
      const isIDPMatchConfig =  window.config?.auto?.idp_name === myProfile?.idp_name;
      // Start IDP Flow
      if(isIDPMatchConfig && !this.props.isInternetDisconnected) {
        const [rsList, readyToSubmit] = this.transformRsListReadyToSubmit(this.state.request.requested_resources, this.state.acceptAccessToResource)
        this.handleIDPFlow(this.state.datasource, this.state.changeNicknameValues, this.state.request,  rsList, this.state.connectedAccounts, this.state.datasources, readyToSubmit);
      }
      this.setState({isIDPFlow: isIDPMatchConfig});
    }
  }
  
  pushPermissionsAction = async (isDeclineRequest=false) => {
    const {
      acceptAccessToResource,
      request: {
        requested_resources,
        transaction_id,
        connectingDsID
      },
      connectedAccounts,
    } = this.state;
    
    this.setState({ isSendingReq: !isDeclineRequest, isDeclineRequest })

    let grantedPermissions = []; 
    let expiredAccounts = [];

    // 1 If Decline Request empty permissions
    if (!isDeclineRequest) {
      // 2 Prepare granted permissions
      // 2.1 Check if the rot expired for the selected sources if the user is idle....

      expiredAccounts = await Promise.resolve(this.handleExpiryCheckBeforeSubmission(requested_resources, connectedAccounts));

      requested_resources.map(resource => {
        if (acceptAccessToResource[resource.resource_definition.res_def_id] && resource.dataSource) {
          const res_def_id = resource.resource_definition.res_def_id;
          const rs_res = resource.dataSource.resources.find(x => x.resource.res_def_id === res_def_id);
          const scopes = resource.scopes_requested.map(x => x.scope);
  
          grantedPermissions.push({
            dsa_id: resource.dataSource.ds_account_id.toString(),
            rs_res_id: rs_res.rs_res_id.toString(),
            scopes_granted: scopes 
          })
        }
  
        return resource;
      })
    }

    // Analytics
    this.handleAnalyticsTrack("onClick_event", isDeclineRequest ? `Declined request` : `Allow button`);

    if (!expiredAccounts.length) {
      try {
        await ConsentRequestService.completeWithPermissions(transaction_id, grantedPermissions).then(async (perm) => {
          // Remove request history
          SessionStorage.remove(`request_history-${transaction_id}`);
          
          // Redirect Api
          await ConsentRequestService.redirectAfterPermissionSubmit(transaction_id, null, perm.permission_code, connectingDsID).then(result => {

            this.handleSuccessRedirect(result.data.redirect_url);
          }) ;
        });        
      } catch (e) {
        if (e.status && e.status !== 401) {
          // Remove request history
          SessionStorage.remove(`request_history-${transaction_id}`);

          //  Analytics
          this.handleAnalyticsTrack("error", "", e);

          this.setState({
            hasError: true,
            errorTitle: i18n.t("consent__sending_auth_error_title"),
            errorMessage: i18n.t("consent__sending_auth_error_desc"),
            isSendingReq: false
          })
        }
      }
    }
  };

  handleSuccessRedirect = (redirectUrl) => {
    window.location.assign(redirectUrl);
  }

  handleExpiryCheckBeforeSubmission = (requested_resources, connectedAccounts) => {
    
    return new Promise((resolve) => {
      let expiredAccounts = [];

      requested_resources.map(re_res => {
        connectedAccounts.map(async (account) => {
          
          await DatasourceService.isAccountExpired(account);
  
          if (re_res.dataSource && account.ds_account_id === re_res.dataSource.ds_account_id && account.expired) {
  
            this.setState({
              hasError: true,
              errorTitle: i18n.t("consent__expired_connection_error_title"),
              errorMessage: i18n.t("consent__expired_connection_error_desc"),
            })
            expiredAccounts.push(account);

          }
          
          return account;
        })
        return re_res;
      })

      resolve(expiredAccounts);
    })
  }

  autoSelectConnectedResources = (accounts, request) => {
    // 1. Check if source modal is open to stop the repopulate
    const isSourceModalOpen = ConsentRequestService.checkForSourceModalOpen(request);
    // 2. Get all the connected accounts for a resource
    if (accounts.length) {
      request.requested_resources.map(req_res => {

        let connectedAccounts = [];
        let temp_req_res = req_res;


        accounts.map(account => {
          account.data_source.resources.map(res => {
            if (!account.expired && !account.disabled) {
              if (temp_req_res.resource_definition.res_def_id === res.resource.res_def_id) {
                connectedAccounts.push(account);
                temp_req_res = ConsentRequestService.handleAutoPopulate(temp_req_res, account, isSourceModalOpen);
              }
            }
      
            return res;
          });
          
          return account;
        })

        temp_req_res.connectedAccounts = connectedAccounts;
        return temp_req_res;
      })
    }

    // keeping state history
    SessionStorage.set(`request_history-${request.transaction_id}`, JSON.stringify(request));

    return request;
  };

  onDataSourceSelect = (chosenSource, resource) => {
    const { request } = this.state;
    
    for (let i = 0; i < request.requested_resources.length; i++) {

      if (request.requested_resources[i].resource_definition.res_def_id === resource.resource_definition.res_def_id) {
        request.requested_resources[i].dataSource = chosenSource.data_source;
        request.requested_resources[i].dataSource.ds_account_id = chosenSource.ds_account_id;
        request.requested_resources[i].dataSource.nickname = chosenSource.nickname;
      }
    }

    // keeping state history
    SessionStorage.set(`request_history-${request.transaction_id}`, JSON.stringify(request));

    this.setState({ request: request });
    this.closeConnectionModal(resource.resource_definition);
  };


  openConnectionModal = (resource_definition) => {
    const { current_transaction_ID } = this.state;

    SessionStorage.set(`${resource_definition.res_def_id}-${current_transaction_ID}`, true);
    SessionStorage.set("isAutoPopulatedOnLoad", true);
    this.setState({ shouldDateSourceSelectorModalOpen: true });

    // Analytics
    this.handleAnalyticsTrack("onClick_event", `${resource_definition.name} ${resource_definition.buttonType}`, "");
  };
  
  closeConnectionModal = (resource_definition) => {
    const { current_transaction_ID } = this.state;

    SessionStorage.remove(`${resource_definition.res_def_id}-${current_transaction_ID}`);
    SessionStorage.remove("isAutoPopulatedOnLoad");

    this.setState({ shouldDateSourceSelectorModalOpen: false });
  };

  handleToggleChange = e => {
    const resDefId = e.target.id;
    
    const { acceptAccessToResource } = this.state;
    acceptAccessToResource[resDefId] = acceptAccessToResource[resDefId] === false ? true : false;
    this.setState({ acceptAccessToResource })
  }

  handleDataSourceSelectorModalToggle = (res_def_id) => {
    const { current_transaction_ID } = this.state;

    let tempShouldDateSourceSelectorModalOpen = false;

    if (SessionStorage.get(`${res_def_id}-${current_transaction_ID}`) === "true") {
      tempShouldDateSourceSelectorModalOpen =  true;
    } 

    return tempShouldDateSourceSelectorModalOpen;
  }

  handleToggleScopeModal = (resource_definition) => {
    const { isScopeModalOpen } = this.state;

    if (isScopeModalOpen === false) {
      // Analytics
      this.handleAnalyticsTrack("onClick_event", `${resource_definition.name} What is this button`, "");
    }
    
    this.setState({
      isScopeModalOpen: !isScopeModalOpen,
      scopeModalID: resource_definition.res_def_id
    });
  };

  handleToggleDeclineModal = () => {
    const { isOpenDeclineModal } = this.state;

    //Analytics
    if (isOpenDeclineModal) {
      this.handleAnalyticsTrack("onClick_event", `Cancel in Decline modal`, "");
    } else {
      this.handleAnalyticsTrack("onClick_event", `Decline button`, "");
    }

    this.setState({ isOpenDeclineModal: !isOpenDeclineModal })
  }

  handleToggleErrorModal = () => {
    // Clearing browser state 
    window.history.pushState("error", null);

    // Remove Browser History
    this.handleRemoveHistory();

    this.setState({
      hasError: false,
      isLoginFailedError: {},
      shouldDateSourceSelectorModalOpen: true
    })
  }

  // Onchange for nickname

  handleOnNicknameChange = (e) => {
    const { changeNicknameValues } = this.state;
    const { name, value } = e.target;

    this.setState({ changeNicknameValues: { ...changeNicknameValues, [name]: { "nickname": value } } })
  }

  handleEditNickNameAction = async (nicknameValues) => {
    const { connectedAccounts } = this.state;

    let DataObj = {
      connectedAccounts,
      changeNickname: false,
      hasError: false,
      errorTitle: "",
      errorMessage: "",
      nicknameValues,
      shouldDateSourceSelectorModalOpen: true
    }

    // Remove Browser History
    this.handleRemoveHistory();

    // Clearing browser state 
    window.history.replaceState("state", null);
    
    // Spinner
    this.setState({ isSendingReq: true });

    let promisesArr = [];

    for (let connectedAccount of connectedAccounts) { 
      for (const key in nicknameValues) {
        if ((connectedAccount.ds_account_id === key) && (connectedAccount.nickname !== nicknameValues[key].nickname)) {
          promisesArr.push(
            new Promise((resolve, reject) => {
              DatasourceService.editNickname(key, nicknameValues[key].nickname).then((result) => {
                resolve(result);
              })
              .catch((e) => {
                e.ds_account_id = key;
                reject(e);
              });
            })
          );
        }
      }
    }

    // Get the result for all the nickname methods

    await PromiseAllsettled(promisesArr).then(allResults=> {

      // 1. Update the Nickname

      DataObj = this.handleUpdateNicknameForList(DataObj, allResults)

      // 2. Filter If we have a error except 409
      const except409ErrorFilter = allResults.filter(item => ((item.status === "rejected") && (item.reason.status !== 409)));
      
      if (except409ErrorFilter.length) {
        DataObj.changeNickname = false;
        DataObj.hasError = true;
        DataObj.errorTitle = i18n.t("edit_nickname_modal__failure_nickname_update_title");
        DataObj.errorMessage = i18n.t("edit_nickname_modal__failure_nickname_update_desc");
      }
    });

    this.setState({
      connectedAccounts: DataObj.connectedAccounts,
      shouldDateSourceSelectorModalOpen: DataObj.shouldDateSourceSelectorModalOpen,
      changeNickname: DataObj.changeNickname,
      isSendingReq: false,
      hasError: DataObj.hasError,
      errorTitle: DataObj.errorTitle,
      errorMessage: DataObj.errorMessage,
      toggleErrorModal: this.handleToggleErrorModal,
    })
  }

  //===========================================
  // Secondary functions for editnickname Start
  //===========================================

  handleUpdateNicknameForList = (DataObj, allResults) => {
    DataObj.connectedAccounts.map((connectedAccount) => {
      for (let resultItem of allResults) { 

        // For Fulfiled succesful calls
        if (resultItem.status === "fulfilled" && connectedAccount.ds_account_id === resultItem.value.ds_account_id) {

          //Analytics
          this.handleAnalyticsTrack("onClick_event", `Submit edit nickname with ${resultItem.value.nickname} for ${resultItem.value.ds_account_id}`);

          connectedAccount.nickname = resultItem.value.nickname;
        }

        // For Failed 409 error calls

        if (resultItem.status === "rejected") {
          DataObj.hasError = false;
          DataObj.changeNickname = true;
          DataObj.shouldDateSourceSelectorModalOpen = false;

          if (resultItem.reason.status === 409) {
            DataObj.nicknameValues[resultItem.reason.ds_account_id].error = i18n.t("edit_nickname_modal__failure_nickname_409_title");
          } 
        }
      }
      return connectedAccount;
    });
    
    return DataObj;
  }

  //===========================================
  // Secondary functions for editnickname End
  //===========================================

  handleDisconnectExpiredAccountWithNewCredModal = async () => {
    let {
      dataSourceHistory,
      connectedAccounts,
    } = this.state;

    // Remove Browser History
    this.handleRemoveHistory();
    
    try {
      const result = await DatasourceService.disableMyDatasourceAccount(dataSourceHistory.ds_account_id);
     
      connectedAccounts = connectedAccounts.map(connectedAccount => {
        if (connectedAccount.ds_account_id === result.ds_account_id) {
          connectedAccount.disabled = result.disabled;
        }

        return connectedAccount;
      });

      this.setState({
        expiredAccountWithNewCredModalOpen: false,
        shouldDateSourceSelectorModalOpen: true,
        connectedAccounts
      })
    } catch (error) {
      if (error.status !== 401) {
        this.setState({
          hasError: true,
          errorTitle: i18n.t("connectionDetail__failure_disconnect_title"),
          errorMessage: i18n.t("connectionDetail__failure_disconnect_desc"),
          isSendingReq: false,
          shouldDateSourceSelectorModalOpen: false,
        })
      }
    }
  }

  handleCloseExpiredAccountWithNewCredModal = () => {
    this.setState({
      changeNickname: true,
      expiredAccountWithNewCredModalOpen: false,
      shouldDateSourceSelectorModalOpen: false,
    })
  }

  handleRemoveHistory = () => {
    DatasourceService.removeConnectedAccountsHistory();
  }

  // Tracking

  handleAnalyticsTrack = (type, action, reason) => {
    const { request } = this.state;

    const spName = (request && request.client) && request.client.name;
    
    AnalyticsService.track(type, {
      context: 'Wallet',
      page: `Consent Page - ${spName}`,
      actions: action,
      url: window.location.hostname + window.location.pathname,
      reason
    })
  }

  render() {
    const {
      request,
      acceptAccessToResource,
      isOpenDeclineModal,
      isDeclineRequest,

      connectedAccounts,
      datasources,
      isScopeModalOpen,
      scopeModalID,
      shouldDateSourceSelectorModalOpen,
      consent_configs,
      isLoadingConnection,

      hasError,
      errorTitle,
      errorMessage,
      errorButtonText,

      isSendingReq,

      dataSourceHistory,
      changeNickname,
      editNicknameTitle,
      editNicknameDesc,
      changeNicknameValues,
      expiredAccountWithNewCredModalOpen,
      toggleErrorModal,
      isIDPFlow
    } = this.state;
  
    const { 
      isInternetDisconnected,
      handleToggleNetworkModal 
    } = this.props;
    
    // If no request Available redirect back

    if (!request) {
      return <Redirect to={window.GLOBAL_PATH} />;
    }

    if (isIDPFlow && !hasError) {
      return (
        <div className="loader-wrapper">
          <Spinner />
        </div>
      )
    }

    // Sort RS List based on type

    const resources = request.requested_resources;
    let rslist = [];
    let readyToSubmit = false;
    
    if (resources && resources.length) {
      rslist = resources.sort((a, b) => a.resource_definition.name.localeCompare(b.resource_definition.name, 0, { numeric: false }));   
  
      //sorting scopes by display_name
  
      rslist.map(li => li.scopes_requested.sort((a, b) => a.display_name?.localeCompare(b.display_name, 0, { numeric: false})));
      
      // Authorization button....
      for (let req_resource of resources) {
        if (req_resource.dataSource && acceptAccessToResource[req_resource.resource_definition.res_def_id]) {
          readyToSubmit = true; 
          break;
        }
      }
    }

    // 2. If all rs is disabled

    if (readyToSubmit) {
      const formattedAcceptAccessToResource = Object.values(acceptAccessToResource);
      const isAllDisabled = formattedAcceptAccessToResource.filter(res => {
        return res === true;
      });
      
      readyToSubmit = isAllDisabled.length;
    }
    
    return (
      <>
        <div className="header-wrapper">
          <Header />
        </div>
        <div className="wrapper">
          <div className="consent">
              <ConsentHeader request={request} />

              <ConsentSubHeader request={request} />

              {/* RS Lists */}

              <RsList 
                rslist={rslist} 
                request={request}
                acceptAccessToResource={acceptAccessToResource}
                connectedAccounts={connectedAccounts}
                datasources={datasources}
                isScopeModalOpen={isScopeModalOpen}
                scopeModalID={scopeModalID}
                shouldDateSourceSelectorModalOpen={shouldDateSourceSelectorModalOpen}
                consent_configs={consent_configs}
                isInternetDisconnected={isInternetDisconnected}
                handleToggleNetworkModal={handleToggleNetworkModal} 
                handleToggleScopeModal={this.handleToggleScopeModal}
                handleToggleChange={this.handleToggleChange}
                onDataSourceSelect={this.onDataSourceSelect}
                openConnectionModal={this.openConnectionModal}
                closeConnectionModal={this.closeConnectionModal}
                isLoadingConnection={isLoadingConnection}
                handleDataSourceSelectorModalToggle={this.handleDataSourceSelectorModalToggle}
              />

              {/* Actions Buttons */}
              
              <ActionButtons 
                readyToSubmit={readyToSubmit}
                isInternetDisconnected={isInternetDisconnected}
                handleToggleNetworkModal={handleToggleNetworkModal}
                isSendingReq={isSendingReq}
                pushPermissionsAction={this.pushPermissionsAction}
                handleToggleDeclineModal={this.handleToggleDeclineModal}
              />

              {/* TOU and PP text */}
              
              <ConsentFooter 
                request={request} 
                handleAnalyticsTrack={this.handleAnalyticsTrack} 
              />

              {/* MODALS  */}

              {
                isOpenDeclineModal
                &&
                <DeclineModal 
                  isOpen={isOpenDeclineModal}
                  handleToggleDeclineModal={this.handleToggleDeclineModal}
                  handleDeclineAction={this.pushPermissionsAction.bind(this, true)}
                  request={request}
                  isDeclineRequest={isDeclineRequest}
                  title={i18n.t("consent__decline_title")}
                  description={i18n.t('consent__decline_desc', { client: request.client.name })}
                />
              }

              {
                hasError
                &&
                <ErrorModal 
                  isOpen={hasError}
                  errorTitle={errorTitle}
                  errorMessage={errorMessage}
                  errorButtonText={errorButtonText}
                  handleToggleModal={toggleErrorModal}
                />
              }

              {
                window.config.nickname_screen
                &&
                changeNickname
                &&
                <EditNicknameModalConsent
                  isOpen={changeNickname}
                  handleEditNicknameAction={this.handleEditNickNameAction}
                  isSendingReq={isSendingReq}
                  isInternetDisconnected={isInternetDisconnected}
                  handleToggleNetworkModal={handleToggleNetworkModal}
                  title={editNicknameTitle}
                  description={editNicknameDesc}
                  nicknameValues={changeNicknameValues}
                  handleOnChange={this.handleOnNicknameChange}
                  dsName={dataSourceHistory.data_source.name}
                  logo_url={dataSourceHistory.data_source.style.logo_url}
                  dsAccount={dataSourceHistory}
                />
              }

              {
                expiredAccountWithNewCredModalOpen
                && 
                <NewAccountConnectionModal
                  isOpen={expiredAccountWithNewCredModalOpen}
                  isSendingReq={isSendingReq}
                  isInternetDisconnected={isInternetDisconnected}
                  handleToggleNetworkModal={handleToggleNetworkModal}
                  dataSource={dataSourceHistory}
                  handleCloseExpiredAccountWithNewCredModal={this.handleCloseExpiredAccountWithNewCredModal}
                  handleDisconnectExpiredAccountWithNewCredModal={this.handleDisconnectExpiredAccountWithNewCredModal}
                />
              }
          </div>
        </div>
      </>
    );
  }
}

export default ConsentContainer;
