import React from "react";

// Lib
import Modal from "react-modal";

// Components
import Spinner from "../../Spinner";

const ConnectionRequiredModal = ({ 
  isOpen,
  isInternetDisconnected,
  isSendingReq,
  handleToggleNetworkModal,
  modalTitle,
  modalBody,
  modalButton1,
  modalButton2,
  handlelogoutAction,
  handleConnectRsAction,
  isExpiredConnectionRequired
})  => {
  
  const tempFooterClassname = isExpiredConnectionRequired ? "footer" : "footer add-connection-footer";

  const  buttonActionType = isExpiredConnectionRequired ? "RENEW_CONNECTION" :"CONNECT_CONNECTION";

  // APi button edge cases

  let renderButton;
  if (isInternetDisconnected) {
    renderButton = (
      <button className="disconnectButton backgroundPrimaryColor" onClick={handleToggleNetworkModal}> { modalButton2 } </button>
    );
  } else if (isSendingReq) {
    renderButton = (
      <button className="disconnectButton backgroundPrimaryColor button--spinner"><Spinner /></button>
    );
  } else {
    renderButton = (
      <button className="disconnectButton backgroundPrimaryColor" onClick={() => handleConnectRsAction(buttonActionType)} disabled={isSendingReq}>{ modalButton2 }</button>
    )
  }
 
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      overlayClassName="modal-overlay"
      contentLabel={modalTitle}
    >
      <div className="modal-wrapper decline-wrapper connection-required">
        <h2 
          className="header"
          dangerouslySetInnerHTML={{ __html: modalTitle }}
        />

        <div 
          className="modal-body"
          dangerouslySetInnerHTML={{ __html: modalBody }}
        />

        <div className={tempFooterClassname}>

          {/* Expired Modal Close Button */}

          {
            <button className="disconnectButton backgroundPrimaryColor" onClick={handlelogoutAction} >
              { modalButton1 }
            </button>
          }

          {/* Connect or Rener action button */}

          { renderButton }
        </div>
      </div>
    </Modal>  
  );
}

export default ConnectionRequiredModal;