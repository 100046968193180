import { BrowserStorage, SessionStorage } from "./BrowserStorage";

// import IDPConnectService from "./IDPConnectService";

/**
    Handles Request and state related to client authentication to wallet server
    - idp login flow
    - wallet server session
*/
const AuthService = {
    // LoginToIdp,
    logout,
    isAuthenticated,
    authorizationHeader
};

/**

!!! This sets state in the AuthService, login session id and secret

@param idp The idp object form the IdpApi
@return Promise with url for redirect
*/
// function LoginToIdp(idp) {
//     return IDPConnectService.LoginToIdp(idp, "auth");
// }

function logout() {
    return new Promise((resolve, reject) => {

        // Clearing browser history
        BrowserStorage.clear();
        SessionStorage.clear();
        
        // redirect to logout url
        let logoutUrl;

        if (window.config.logout_url) {
            logoutUrl = `${window.config.logout_url}?post_logout_redirect_uri=${window.location.href}`;
        } else {
            logoutUrl = window.GLOBAL_PATH;
        }

        window.location.assign(logoutUrl);

        resolve();
    });
}

function isAuthenticated() {
    return BrowserStorage.get("session");
}

function authorizationHeader() {
    return "Bearer " + BrowserStorage.get("session");
}

export { AuthService };
