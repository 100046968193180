import ProofRequestsApi from "./ProofRequestsApi";

let ProofRequestsService = {};

ProofRequestsService.getPendingProofRequests = function (state) {
    return new Promise((resolve, reject) => {
        ProofRequestsApi.getPendingProofRequests(state).then(
              result => {
                  resolve(result);
              },
              error => reject(error)
          );
      });
  };

ProofRequestsService.getPendingProofRequestByID = function (proofID) {
    return new Promise((resolve, reject) => {
        ProofRequestsApi.getPendingProofRequestByID(proofID).then(
                result => {
                    resolve(result);
                },
                error => reject(error)
            );
        });
    };

ProofRequestsService.getValidVerifiableCredentials = function (proofID) {
    return new Promise((resolve, reject) => {
        ProofRequestsApi.getValidVerifiableCredentials(proofID).then(
                result => {
                    resolve(result);
                },
                error => reject(error)
            );
        });
    };


ProofRequestsService.acceptProofRequest = function (proofID, accept, credential_id) {
    return new Promise((resolve, reject) => {
        ProofRequestsApi.acceptProofRequest(proofID, accept, credential_id).then(
                result => {
                    resolve(result);
                },
                error => reject(error)
            );
        });
    };

export default ProofRequestsService;
