import React from "react";

// Components
import Spinner from "../../Spinner";
import CustomTable from "../../CustomTable";

// Lib
import MediaQuery from 'react-responsive';
import InfiniteScroll from "react-infinite-scroll-component";

const MobileVersion = ({ handleMobileDevice, searchPaginationResults, paginationOrganizationsList, fetchMoreItems, handleHasMore, isPaginationLoader, queryString, isLoading, isInternetDisconnected, customSort, connectionHeaderFields, handleOnSortClick, onSortClick }) => {
  
  return (
    <MediaQuery maxDeviceWidth={750} onChange={handleMobileDevice}>
      <InfiniteScroll
        dataLength={searchPaginationResults.length ? searchPaginationResults.length : paginationOrganizationsList.length}
        next={fetchMoreItems}
        hasMore={handleHasMore}
        loader={isPaginationLoader && <div className="loader-wrapper"><Spinner /></div>}
      >
        <CustomTable
          isSearchable={true}
          searchResults={searchPaginationResults}
          queryString={queryString}
          isLoading={isLoading}
          isInternetDisconnected={isInternetDisconnected}
          tableData={searchPaginationResults.length ? searchPaginationResults : paginationOrganizationsList}
          customSort={customSort}
          fields={connectionHeaderFields}
          handleOnSortClick={handleOnSortClick}
          sortIcon={onSortClick ? <div className="sortIcon textPrimaryColor caretUp">&#9662;</div> : <div className="sortIcon textPrimaryColor">&#9662;</div>}
          isMobileVersion={true}
        />
      </InfiniteScroll>
    </MediaQuery>
  );
}

export default MobileVersion;
