import React, { Component } from "react";

// Lib
import i18n from "../../../i18n";
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';

// Components
import Spinner from "../../Spinner";

class DisconnectDetailsModal extends Component {

  render() {
    const {
      isDisconnectDetailsModalOpen,
      handleToggleDisconnectDetailsModal,
      handleDisconnectInformationSourceDetailsModalSubmitButton,
      handleDisconnectServiceProviderDetailsModalSubmitButton,
      clickedRow,
      table_type,
      isSendingReq,
      isInternetDisconnected,
      handleToggleNetworkModal
    } = this.props;

    return (
      <Modal
        isOpen={isDisconnectDetailsModalOpen}
        ariaHideApp={true}
        onRequestClose={handleToggleDisconnectDetailsModal}
        overlayClassName="modal-overlay"
        contentLabel={i18n.t("connectionDetail__disconnect")}
      >
        <div className="modal-wrapper disconnect-Details-modal">
          <button className="close-icon" onClick={handleToggleDisconnectDetailsModal} aria-label={i18n.t("connectionDetail__aria_label_close_button")}>
            <CloseIcon className="arrow-icon" />
          </button>

          <h2 className="header">
            {
              `${i18n.t("connectionDetail__disconnect")}?`
            }
          </h2>
          <div className="modal-body">
            {
              table_type === "SERVICE_PROVIDER"
              ?
              <React.Fragment>
                <div 
                  className="sub-heading-top"
                  dangerouslySetInnerHTML = {
                    {
                      __html: i18n.t('connectionDetail__disconnetDetailsSPModalBodyTop', {
                        rsName: clickedRow.client.name,
                      })
                    }
                  }
                />

                <div 
                  className="sub-heading-bottom"
                  dangerouslySetInnerHTML = {
                    {
                      __html: i18n.t('connectionDetail__disconnetDetailsSPModalBody', {
                        rsName: clickedRow.client.name,
                      })
                    }
                  }
                />
              </React.Fragment>
              :
              <React.Fragment>
                <div 
                  className="sub-heading-top"
                  dangerouslySetInnerHTML={{__html: i18n.t('connectionDetail__disconnetDetailsISModalBodyTop')}}
                />
                <div className="sub-heading-bottom">
                  {
                    i18n.t("connectionDetail__disconnetDetailsISModalBody")
                  }
                </div>
              </React.Fragment>
            }

            {/* {

              table_type === "SERVICE_PROVIDER"
              &&
              <React.Fragment>
                {
                  (clickedRow.scopes_granted && clickedRow.scopes_granted.length)
                  &&
                  <div className="edit-View-container">
                    {
                      clickedRow.scopes_granted.map((scope, i) => <p key={i}>{scope}</p>)
                    }
                  </div>
                }
              </React.Fragment>
            }
             */}
          </div>
          <div className="footer">
            <button 
              className="noThanksButton textPrimaryColor borderPrimaryColor"
              onClick={handleToggleDisconnectDetailsModal}
              disabled={isSendingReq} 
            >
              {
                i18n.t("connectionDetail__cancel")
              }
            </button>

            {
              isInternetDisconnected
              ?
              <button 
                className="disconnectButton backgroundPrimaryColor"
                onClick={handleToggleNetworkModal} 
              >
                {
                  i18n.t("connectionDetail__disconnect")
                }
              </button>
              :
              isSendingReq
              ?
              <button className="disconnectButton backgroundPrimaryColor button--spinner">
                <Spinner />
              </button>
              :
              <button 
                className="disconnectButton backgroundPrimaryColor"
                onClick={
                table_type === "SERVICE_PROVIDER" ? handleDisconnectServiceProviderDetailsModalSubmitButton : handleDisconnectInformationSourceDetailsModalSubmitButton
                } 
              >
                {
                  i18n.t("connectionDetail__disconnect")
                }
              </button>
            }
          </div>
        </div>
      </Modal>
    );
  }
}

export default DisconnectDetailsModal;
