import React, { Component } from "react";

// Component
import Sidebar from "../Sidebar";
import NetworkDetector from "../NetworkDetector";
import Header from "../Header";

// Libraries
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

class StandardLayout extends Component {

  state = {
    isDrawerOpen: false
  }

  handleDrawerToggle = () => {
    const { isDrawerOpen } = this.state;

    this.setState({ isDrawerOpen: !isDrawerOpen })
  }

  render () {
    const { children, handleReload, isInternetDisconnected, handleToggleNetworkModal } = this.props;
    const { isDrawerOpen } = this.state;

    return (
      <div className="standardLayout">
        <Hidden mdUp implementation="css">

          <div className="header-wrapper">
            <Header handleDrawerToggle={this.handleDrawerToggle} hasMenu={true} />
          </div>
        </Hidden>

        <div className="container">
          {/* Mobile Drawer */}
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor='left'
              open={isDrawerOpen}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Sidebar handleDrawerToggle={this.handleDrawerToggle} />
            </Drawer>
          </Hidden>
          
          {/* Always open Drawer */}
          <Hidden smDown implementation="css" className="hidden-xsDown">
            <Drawer
              variant="permanent"
              open
            >
              <Sidebar />
            </Drawer>

          </Hidden>

          <div className="children-wrapper">
            {
              React.Children.map(children, child =>
              React.cloneElement(child, {
              handleReload: handleReload,
              isInternetDisconnected: isInternetDisconnected,
              handleToggleNetworkModal: handleToggleNetworkModal
              }))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default NetworkDetector(StandardLayout);