import React from "react";

 // Lib
 import parse from 'html-react-parser';
 import i18n from "../../../i18n";

 const ConsentHeader = ({ request }) => {
   
    return (
        <>
            {/* If Custom header Available or not */}
            
            {
                request.client?.consent_title_html
                ?
                parse(request.client?.consent_title_html)
                :
                <h1>
                    {i18n.t("consent__title", { clientName: request.client?.name })}
                </h1>
            }
        </>
    )
 }

 export default ConsentHeader;
