import React from "react";

// Lib
import Toggle from "react-toggle";
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';

// Services
import i18n from "../../../i18n";

const ScopeModal = ({ isOpen, data, consent_configs, handleToggleScopeModal }) => {   
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleToggleScopeModal}
      ariaHideApp={true}
      overlayClassName="modal-overlay"
      contentLabel={data.resource_definition.name}
    >
      <div className="modal-wrapper scope-modal-wrapper">
        <div className="header"> 
        
          <h1>{data.resource_definition.name}</h1>

          <button className="scope-close" onClick={handleToggleScopeModal} aria-label={i18n.t("resourceDetailsModal__close")}>
            <CloseIcon className="arrow-icon" />
          </button>
        </div>

        {/* Optional description section */}

        {
          data.resource_definition.description
          &&
          <div className="whats-this-wrapper">
            <strong>{i18n.t("consent__whats_this")}</strong>
            <p>{data.resource_definition.description}</p>
            
            {
              data.resource_definition.information_uri
              &&
              <a className="textPrimaryColor" href={data.resource_definition.information_uri} target="_blank" rel="noopener noreferrer">{i18n.t("consent__learn_more")}</a>
            }
          </div>
        }

        <p className="sub-heading">{i18n.t("consent__scope_modal_sub_heading")}</p>
        
        {/* Scopes */}

        <div className="scopes-wrapper">   
          {
            data.scopes_requested.map((scope, idx) => (
              <div key={idx} className="scope-item">
                <div className="left-side">
                  <strong>{scope.display_name}</strong>
                  {
                    scope.purpose
                    &&
                    <p className="purpose">{scope.purpose}</p>
                  }
                </div>
                
                  {
                    consent_configs.enable_scope_toggle 
                    &&
                    <div className="right-side">
                      <Toggle
                        checked={true}
                        className="toggler"
                        icons={false}
                        id="1"
                        aria-label="toggle switch"
                      />
                    </div>
                  }
              </div>
            ))
          }
        </div>
      </div>
    </Modal>
  );
}

export default ScopeModal;