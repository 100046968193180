import React from "react";

 // Lib
import parse from 'html-react-parser';

// Components
import TOUandPP from "../../TOUandPP"; 

 const ConsentFooter = ({ request, handleAnalyticsTrack }) => {
    return (
        <>
          {/* If Custom Footer Available or not */}
          
          {
            request.client?.consent_footer_html
            ?
            parse(request.client?.consent_footer_html)
            :
            <TOUandPP 
              clientName={request.client?.name}
              tos_uri={request.client?.tos_uri}
              policy_uri={request.client?.policy_uri}
              handleAnalyticsTrack={handleAnalyticsTrack}
            />
          }
        </>
    )
 }

 export default ConsentFooter;
