import React from "react";

// Components
import Spinner from "./Spinner";

const ButtonWithSpinner = ({ 
    className,
    isInternetDisconnected,
    handleToggleNetworkModal, 
    btnText, 
    handleButtonAction,
    isDisabled,
    isSendingReq
}) =>  {
    const renderButton = () => {
        if (isInternetDisconnected) {
            return (
                <button 
                    className={className}
                    onClick={handleToggleNetworkModal} 
                >
                    { btnText }
                </button>
            )
        } else if (isSendingReq) {
            return (
                <button className={`${className} button--spinner`}>
                    <Spinner />
                </button>
            )
        } else {
            return (
                <button 
                    className={className}
                    onClick={handleButtonAction}
                    disabled={isDisabled}
                > 
                    { btnText }
                </button>
            )
        }        
    }

    return (
        <>
            {
                renderButton()
            }
        </>
    )
}

export default ButtonWithSpinner;
