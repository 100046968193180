import React from "react";

 // Lib
import parse from 'html-react-parser';

 const ConsentSubHeader = ({ request }) => {
    return (
        <>
            {/* If Custom Subtitle Available or not */}

            {
                request.client?.consent_subtitle_html
                &&
                parse(request.client?.consent_subtitle_html)
            }
        </>
    )
 }

 export default ConsentSubHeader;
