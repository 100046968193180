export const delegatedAccountsCheck = (DSAccounts, connectDsAccount, getRelationsByID) => {

    let relatedArr = [];
    let delegatedAccounts = [];
  
    if (getRelationsByID && getRelationsByID.length) {
  
      // Get the related Arr containing subs
      
      DSAccounts.map(dsAccount => {
        getRelationsByID.map(item => {
          // Check data source id to be same
          // Also match the sub
    
          if ((item.sub === dsAccount.sub) && (dsAccount.data_source.data_source_id === connectDsAccount.data_source.data_source_id)) {
            relatedArr = [...relatedArr, ...item.related];
          }
    
          return item;
        });
        
        return dsAccount;
      })
    
      // Now get the dsa ccount with that related arr subs.
    
      if (relatedArr.length) {
        DSAccounts.forEach(dsAccount => {
          relatedArr.forEach((relatedItem) => {
    
            // NOW match the sub of related with dsaccounts
            if ((relatedItem === dsAccount.sub) && (dsAccount.data_source.data_source_id === connectDsAccount.data_source.data_source_id)) { 
              delegatedAccounts.push(dsAccount);
            }
          })
        })
      }
    }
    
    return delegatedAccounts;
}
