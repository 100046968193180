import React from "react";

// Components
import CustomTable from "../../CustomTable";

// Services
import i18n from "../../../i18n";

const DataTable = ({
  noDataText,
  errorMessage,
  isSearchable,
  searchResults,
  queryString,
  isLoading,
  isInternetDisconnected,
  tableData,
  findConnectionFields,
  paginationPerPage,
  isMobileVersion,
  renderActionButton
}) => {

  // Formatting fields data with table settings
  // My connections
  const findConnectionHeaderFields = findConnectionFields && findConnectionFields.map((item) => {
    
    //Add new connections
    if (item.selector === "multiple_accounts_enable") {
      item.cell = row => (
        <>
          <div className="connection-row">

            <div className="right-side">
              <span>{row.name}</span>
            </div>

            {
              renderActionButton(row)
            }
          </div>

          {
            row.connectedAccounts.length 
            ? 
            <ul className="connected-accounts">
              {
                row.connectedAccounts.map((account, i) => (
                  <li key={i}>
                    <div>
                      <span>{account.nickname || `-`}</span>
                      {
                        (account.expired && !account.disabled)
                        && 
                        <p className="reconnect-error-message">
                          {
                            i18n.t("findConnections__reconnect_button_expired_error_msg")
                          }
                        </p>
                      }

                      {
                        account.disabled 
                        && 
                        <p className="reconnect-error-message">
                          {
                            i18n.t("findConnections__reconnect_button_disconndecte_error_msg")
                          }
                        </p>
                      }
                    </div>

                    {
                      renderActionButton(account, true)
                    }
                  </li>
                ))
              }
            </ul>
            : 
            null
          }
        </>
      );
    }

    return item;
  });
  
  return (
    <>
      {
        noDataText 
        ? 
        noDataText
        : 
        <CustomTable
          errorMessage={errorMessage}
          isSearchable={isSearchable}
          searchResults={searchResults}
          queryString={queryString}
          isLoading={isLoading}
          isInternetDisconnected={isInternetDisconnected}
          tableData={tableData}
          noDataText={noDataText}
          fields={findConnectionHeaderFields}
          paginationPerPage={paginationPerPage}
          isMobileVersion={isMobileVersion}
        />
      }
    </>
  );
}

export default DataTable;