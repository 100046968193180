import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import IDPConnectService from "./Services/IDPConnectService";

// Components
import Spinner from "./Spinner";

class IdpRedirectRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,
      error: null,
      hasError:  false,
      last_view: null,
    };
  }

  componentDidMount = () => {
    return IDPConnectService.LoginToIdpCallback()
      .then(result => {
        this.setState({ redirectTo: result.internal_redirect, last_view: result.last_view });
      }).catch(e => {
        this.setState({
          hasError: true,
          error: e.data,
          last_view: e.last_view
        })
      })
  }

  render() {
    const {
      redirectTo,
      hasError,
      last_view,
      error
    } = this.state;
 
    if (hasError) {
      return <Redirect to = {
        {
          pathname: last_view === "CONSENT_QR" ? window.GLOBAL_PATH + "consent-request" : window.GLOBAL_PATH + "welcome",
          state: {
            isLoginFailed: true,
            lookup_code: error && error.lookup_code
          }
        }
      }
      />;
    }

    if (redirectTo) {
      return <Redirect to={{ pathname: redirectTo }} />;
    }

    return (
      <div className="redirect-router-spinner-wrapper">
        <Spinner />
      </div>
    )
  }
}

export default IdpRedirectRouter;