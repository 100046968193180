import React from "react";

// Lib
import i18n from "../../../i18n";
import Modal from "react-modal";

// images
import exit from "../../../assets/exit.png";

const NewConnectionModal = ({  newConnectionToggle, handleToggleConnection }) => {
  return (
    <Modal
      isOpen={newConnectionToggle}
      onRequestClose={handleToggleConnection}
      ariaHideApp={true}
      overlayClassName="modal-overlay"
      contentLabel={i18n.t("connections_modal_title")}
    >
      <button className="modal-exit" onClick={handleToggleConnection}>
        <img src={exit} alt="close modal" />
      </button>
      <div className="connections-add-modal">
        <h2 className="title">{i18n.t("connections_modal_title")}</h2>
        <p className="message">{i18n.t("connections_modal_message")}</p>
        <button className="btn backgroundPrimaryColor">{i18n.t("connections_modal_add_connection")}</button>
      </div>
    </Modal>
  );
}

export default NewConnectionModal;
