import React, { Component } from "react";


class Spinner extends Component {
  render() {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-container">
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
          <div className="backgroundPrimaryColor"></div>
        </div>
      </div>
    );
  }
}

export default Spinner;
