import React, { Component } from "react";

// Services
import i18n from '../../../i18n';

class RequestErrorContainer extends Component {
  render() {

    return (
      <div className="wrapper request-error-page">
        <div className="noResultErrorMessage">
          <h3>{i18n.t("request_error_title")}</h3>
          <p>{i18n.t("request_error_desc")}</p>
        </div>
      </div>
    )
  }
}

export default RequestErrorContainer;
