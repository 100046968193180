import React, { Component } from "react";

// Lib
import i18n from "../i18n";
import Modal from "react-modal";

// Components
import Spinner from "./Spinner";

class DisconnectModal extends Component {

  render() {
    const {
      isDisconnectModalOpen,
      handleToggleDisconnectAllModal,
      handleDisconnectSubmission,
      title,
      body1,
      body2,
      isSendingReq,
      handleToggleNetworkModal,
      isInternetDisconnected
    } = this.props;

    return (
      <Modal
        isOpen={isDisconnectModalOpen}
        onRequestClose={handleToggleDisconnectAllModal}
        ariaHideApp={true}
        overlayClassName="modal-overlay"
        contentLabel={title}
      >
        <div className="modal-wrapper disconnect-Details-modal">
          <h2 className="header">
            {title}
          </h2>
          <div className="modal-body">
            <div className="sub-heading-top" dangerouslySetInnerHTML={{ __html: body1 }} />
            <div className="sub-heading-bottom">
              {body2} 
            </div>
          </div>
          <div className="footer">
            <button className="noThanksButton textPrimaryColor borderPrimaryColor" onClick={
              handleToggleDisconnectAllModal} disabled={isSendingReq} >
              {
                i18n.t("connectionDetail__cancel")
              }
            </button>

            {
              isInternetDisconnected
              ?
              <button className="disconnectButton backgroundPrimaryColor" onClick={handleToggleNetworkModal}>
                {
                  i18n.t("connectionDetail__disconnect")
                }
              </button>
              :
              isSendingReq
              ?
              <button className="disconnectButton backgroundPrimaryColor button--spinner">
                <Spinner />
              </button>
              :
              <button className="disconnectButton backgroundPrimaryColor" onClick={handleDisconnectSubmission}> 
                {
                  i18n.t("connectionDetail__disconnect")
                }
              </button>
            }
          </div>
        </div>
      </Modal>
    );
  }
}

export default DisconnectModal;
