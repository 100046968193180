import React from "react";

// Lib
import i18n from "../../../i18n";

// Components
import Spinner from "../../Spinner";

const RsListItemResource = ({ resource, acceptAccessToResource, isLoadingConnection, consent_configs, openConnectionModal }) => {

  const renderConnectionWithDS = () => {
    if (isLoadingConnection) {
      return (
        <Spinner />
      )
    } else {
      return (
        <>
          <p className="subTitle">{i18n.t("consent__information_source_text")}</p>
          <div className={!acceptAccessToResource[resource.resource_definition.res_def_id] ? "connection-info connection-info--disabled" : "connection-info"}>

            {/* Show DS name with nickname  */}

            <p className="connected-dataSource-name">
              { resource.dataSource.name } – <strong>{resource.dataSource.nickname}</strong>
            </p>
            
            {/* Change connection Button  */}

            {
              consent_configs.enable_change_button
              &&
              <button
                className="change-connection-btn textPrimaryColor"
                onClick={!acceptAccessToResource[resource.resource_definition.res_def_id] ? null : openConnectionModal.bind(this, resource.resource_definition)}
              >
                {i18n.t("consent__change")}
              </button> 
            }                       
          </div>
        </>
      )
    }
  }

  const renderConnectionWithoutDS = () => { 
    if (isLoadingConnection) {
      return (
        <Spinner />
      )
    } else {
      return (
        <div className="connection-info connection-info--add-connection">
          <p>
            <span>&#33;</span> {i18n.t("consent__select_a_source")}
          </p>

          <button
            className={!acceptAccessToResource[resource.resource_definition.res_def_id] ? "find-connection-button find-connection-button--disabled borderPrimaryColor backgroundPrimaryColor" : "find-connection-button borderPrimaryColor backgroundPrimaryColor"}
            onClick={openConnectionModal.bind(this, resource.resource_definition)}
            disabled={!acceptAccessToResource[resource.resource_definition.res_def_id] ? true : false}
          >
            {i18n.t("consent__find_source")}
          </button>
        </div>
      )
    }
  }

  const renderResource = () => {
    let tempResource = resource;
    
    if (resource.dataSource) {
      tempResource.resource_definition.buttonType = "Change Button";

      return (
        renderConnectionWithDS()
      )
    } else {
      tempResource.resource_definition.buttonType = "Add Button";

      return(
        renderConnectionWithoutDS()
      )
    }
  }

  return (
    <div className="connected-rs">
      {
        renderResource()
      }
    </div>
  );
}

export default RsListItemResource;