import React, { Component } from "react";

// Lib
import i18n from "../i18n";
import Modal from "react-modal";

class LogoutModal extends Component {
  render() {
    const {
      isOpen,
      handleToggleLogoutModal,
      handlelogoutAction,
      isInternetDisconnected,
      handleToggleNetworkModal
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={handleToggleLogoutModal}
        ariaHideApp={true}
        overlayClassName="modal-overlay"
        contentLabel={i18n.t("logout__logout")}
      >
        <div className="modal-wrapper logout-wrapper">
          <h2 className="header">
            {
              `${i18n.t("logout__logout")} ?`
            }
          </h2>
          <div className="modal-body">
              {i18n.t("logout__body_text")} 
          </div>
          <div className="footer">
            <button className="noThanksButton textPrimaryColor borderPrimaryColor" onClick={handleToggleLogoutModal} >
              {
                i18n.t("logout__cancel")
              }
            </button>

            {
              isInternetDisconnected
              ?
              <button className="disconnectButton backgroundPrimaryColor no-connection-btn" onClick={handleToggleNetworkModal}> 
                {
                  i18n.t("logout__yes_logout")
                }
              </button>
              :
              <button className="disconnectButton backgroundPrimaryColor" onClick={handlelogoutAction}> 
                {
                  i18n.t("logout__yes_logout")
                }
              </button>
            }
          </div>
        </div>
      </Modal>  
    );
  }
}

export default LogoutModal;