import React from "react";

import Modal from "react-modal";

import i18n from "../../../i18n";

class ResourceDetailModal extends React.Component {

  handleRenderScopes = (scope_requested) => {
    return scope_requested.map((req_scope, idx) => (
      <li key={idx}>
        <h4>{req_scope.display_name}</h4>
        {
          req_scope.purpose
          &&
          <p>{req_scope.purpose}</p>
        }
      </li>
    ))
  }

  render() {
    const { isOpen, handleToggleResourceDetailModal, permission } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        ariaHideApp={true}
        overlayClassName="modal-overlay"
        onRequestClose={handleToggleResourceDetailModal}
        contentLabel={i18n.t("resourceDetailsModal__details")}
      >
        <div className="resource-detail textPrimaryColor">
          <h4 className="title">
            {i18n.t("resourceDetailsModal__details")}
          </h4>
          
          <div className="resource-section">
            <h4 className="name">{permission.resource_definition.name}</h4>
            <p>
              <span>{i18n.t('confirmAuth__source')}</span> {permission.data_source.name}
            </p>
          </div>

          {
            permission.resource_definition.description
            &&
            <div div className="res_desciption whats-this-wrapper" >
              <h4>{i18n.t('resourceDetailsModal__whats_this')}</h4>
              <p>{permission.resource_definition.description}</p>

              {/* {
                permission.resource_definition.information_uri
                &&
                <a className="textPrimaryColor" href={permission.resource_definition.information_uri} target="_blank" rel="noopener noreferrer">{i18n.t("consent__learn_more")}</a>
              } */}
            </div>
          }

          {
            permission.scopes_requested && permission.scopes_requested.length
            &&
            <ul className="scope-section">
              {this.handleRenderScopes(permission.scopes_requested)}
            </ul>
          }

          <button onClick={handleToggleResourceDetailModal} className="done-btn textPrimaryColor">{i18n.t("resourceDetailsModal__close")}</button>
        </div>
      </Modal>
    );
  }
}

export default ResourceDetailModal;