import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'focus-visible';
import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'moment/locale/fr';

// disables all console.logs in production
process.env.NODE_ENV === "production" && (console.log = () => {});

ReactDOM.render(<App />, document.getElementById("root"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
