import React, { Component } from "react";

// Component
import SettingsContainer from "../container/SettingsContainer";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

class SettingsPage extends Component {

  render() {
    const {
      handleToggleNetworkModal,
      isInternetDisconnected,
    } = this.props;

    return (
      <div className="settings">
        <Helmet title={i18n.t("settings__title")} />
        <SettingsContainer
          handleToggleNetworkModal={handleToggleNetworkModal} 
          isInternetDisconnected={isInternetDisconnected} 
        />
      </div>
    );
  }
}

export default SettingsPage;
