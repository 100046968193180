import React from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

// Services
import { AuthService } from "../../Services/AuthService";

// Components
import LoginContainer from "../container/LoginContainer";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    if (AuthService.isAuthenticated()) {
      this.props.history.push({
        pathname: window.GLOBAL_PATH+"profile",
        state: this.props.location.state
      });

      return;
    }
  }

  render() {
    
    return (
      <div className="login">
        <Helmet title={i18n.t("login__login_title")} />
        <LoginContainer />
      </div>
    );
  }
}

export default LoginPage;
