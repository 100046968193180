import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

// Components
import ConnectionResourceDetailContainer from "../container/ConnectionResourceDetailContainer";

class ConnectionResourceDetailPage extends Component {

  render() {
    const { handleReload, isInternetDisconnected, handleToggleNetworkModal } = this.props;

    return (
      <div className="connectionDetail connectionResourceDetail">
        <Helmet title={i18n.t("connectionResourceDetail__page_title")} />
        <ConnectionResourceDetailContainer 
          resourceDetail={this.props.location.state} 
          handleReload={handleReload} 
          isInternetDisconnected={isInternetDisconnected}   
          handleToggleNetworkModal={handleToggleNetworkModal} />
      </div>
    );
  }
}

export default ConnectionResourceDetailPage;
