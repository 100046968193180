import React from "react";

// Lib
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';

// Services
import i18n from "../../../i18n";

const ScopeModal = ({ isOpen, proofRequest, handleToggleScopeModal }) => {   

  const formattedScopes = [...proofRequest.attributes, proofRequest.predicates];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleToggleScopeModal}
      ariaHideApp={true}
      overlayClassName="modal-overlay"
      contentLabel={proofRequest.name}
    >
      <div className="modal-wrapper scope-modal-wrapper">
        <div className="header"> 
        
          <h1>{`${i18n.t("proofRequest_page__proof_item_text")} ${proofRequest.name}`}</h1>

          <button className="scope-close" onClick={handleToggleScopeModal} aria-label={i18n.t("resourceDetailsModal__close")}>
            <CloseIcon className="arrow-icon" />
          </button>
        </div>

        <p>{i18n.t("proofRequest_page__scope_modal_sub_heading_1", { stakeholderName: proofRequest.vc_stakeholder?.name, comment: proofRequest.comment })}</p>

        <p className="sub-heading">{i18n.t("proofRequest_page__scope_modal_sub_heading_2")}</p>
        
        {/* Scopes */}

        {
          formattedScopes.length 
          ?
          <div className="scopes-wrapper">   
            {
              formattedScopes.map((scope, idx) => (
                <div key={idx} className="scope-item">
                  <div className="left-side">
                    <strong>{scope}</strong>
                  </div>
                </div>
              ))
            }
          </div>
          :
          null
        }
      </div>
    </Modal>
  );
}

export default ScopeModal;
