import ProfileApi from "./ProfileApi";
import { BrowserStorage } from "./BrowserStorage";

let ProfileService = {};

ProfileService.getMyProfile = function () {
  return ProfileApi.getMyProfile();
}

ProfileService.selfDelete = function (reason) {
  return new Promise((resolve, reject) => {
        ProfileApi.selfDelete(reason).then(
            result => {
                // 3. Remove the history
                BrowserStorage.remove("delete_reason");

                resolve(result);
            },
            error => reject(error)
        );
    });
};

ProfileService.getBrowserLanguage = function () {
  return BrowserStorage.get("i18nextLng");
}

ProfileService.postCredentialConnections = function (c_i) {
  return new Promise((resolve, reject) => {
        ProfileApi.postCredentialConnections(c_i).then(
            result => {
                resolve(result);
            },
            error => reject(error)
        );
    });
};

ProfileService.getCredentialOffers = function (state) {
    
  return new Promise((resolve, reject) => {
        ProfileApi.getCredentialOffers(state).then(
            result => {
                resolve(result);
            },
            error => reject(error)
        );
    });
};

ProfileService.acceptCredentialOffer = function (credOfferId, accept) {
  return new Promise((resolve, reject) => {
        ProfileApi.acceptCredentialOffer(credOfferId, accept).then(
            result => {
                resolve(result);
            },
            error => reject(error)
        );
    });
};

export default ProfileService;
