import React from 'react';
import {Route, Redirect} from 'react-router-dom'
import { AuthService } from './components/Services/AuthService'

export const ProtectedRoute = ({
        component: Component,
        layout: Layout,
        ...rest
    }) => (
    <Route {...rest} render={ (props) => {
            if(AuthService.isAuthenticated()){
                return <Layout><Component {...props}/></Layout>
            } else {
                return <Redirect 
                        to= {{ pathname: window.GLOBAL_PATH,
                        state: { from: props.location }
                    }}
                />
            }
        }
    } />       
);
