import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

// Components
import RequestErrorContainer from "../container/RequestErrorContainer";
import Header from "../../Header";

class RequestErrorPage extends Component {
  render() {
    return (
      <div className="consentQR request-error" >
        <Helmet title={i18n.t("request_error_title")} />
        <Header />

        <RequestErrorContainer />
      </div>
    );
  }
}

export default RequestErrorPage;
