import React from "react";

// Services
import i18n from "../i18n";

// Components
import Spinner from "./Spinner";

// Lib
import DataTable from 'react-data-table-component';
import { v4 as uuidv4 } from 'uuid';

const CustomTable = ({ 
  isSearchable,
  searchResults,
  queryString,
  isLoading,
  isInternetDisconnected,
  tableData,
  handleOnSortClick,
  noDataText,
  customSort,
  fields,
  paginationPerPage,
  sortIcon,
  isMobileVersion
}) => {

  const renderTable = () => {
    if (isLoading && !isInternetDisconnected) {
      return (
        <div className="loader-wrapper">
          <Spinner />
        </div>
      )
    } else {
      return (
        <DataTable
          className={isMobileVersion ? "mobile-table" : ""}
          columns={fields}
          data={tableData}
          noHeader={true}
          responsive={true}
          overflowY={true}
          overflowYOffset="50px"
          pagination={(tableData && paginationPerPage && tableData.length) > paginationPerPage ? true : false}
          sortIcon={sortIcon}
          onSort={handleOnSortClick}
          sortFunction={customSort}
          paginationComponentOptions={paginationOptions}
          noDataComponent={noDataText}
          paginationPerPage={paginationPerPage}
          noTableHead={isMobileVersion}
          keyField={uuidv4()}
        />
      )
    }
  }
    
  // Pagination text
  const paginationOptions = { rowsPerPageText: i18n.t("connections__paginationRow"), rangeSeparatorText: i18n.t("connections__paginationOF") };

  return (
    <div className="connections-tableWrapper">
      {/* Search Results */}
      {
        (isSearchable && queryString && !searchResults.length && !isLoading)
        ?
        <div className="noResultErrorMessage">
          <h3>{i18n.t("connections__noResultsFound")}</h3>
          <p>{i18n.t("connections__couldNotFind")}</p>
        </div>
        :
        renderTable() 
      }
    </div>
  );
}

export default CustomTable;
