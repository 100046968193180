import i18n from "../i18n";

/**
 * looks for an i18n key with prefix, id, postfix combined otherwise returns prefix, postfix together.
 * @param {*} prefix Prefix string before id is added i.e 'profile__new_connection_required_modal'
 * @param {*} id id of currentDataSource: 'currentDataSource.data_source_id'.
 * @param {*} postfix Postfix string after adding id, i.e. '_title'.
 * @param {*} textInterpolationObject i18n intopleration object for fallback text (optional)
 * @returns 
 */
export function CustomLocalizationText(prefix, id, postfix, textInterpolationObject){
    if(i18n.exists(`${prefix}_${id}${postfix}`)){
        return i18n.t(`${prefix}_${id}${postfix}`)
    } else {
        return i18n.t(`${prefix}${postfix}`,textInterpolationObject)
    }


    
}