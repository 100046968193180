import React from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

// Components
import ConsentContainer from "../container/ConsentContainer";

const ConsentPage = ({ isInternetDisconnected, handleToggleNetworkModal, location, match }) => {
  return (
    <div className="consent-page">
      <Helmet title={i18n.t("consent__page_title")} />  

      <ConsentContainer 
        location={location} 
        transactionID={match.params.transactionID} 
        isInternetDisconnected={isInternetDisconnected}   
        handleToggleNetworkModal={handleToggleNetworkModal}         
      />
    </div>
  );
}

export default ConsentPage;
