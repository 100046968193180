import axios from "axios";
import { AuthService } from "./AuthService";

let DatasourceAPI = {};

// !!!: better way to inject url base into request
var getEndpoint = function() {
  return window.config.url;
};

DatasourceAPI.getDatasources = function() {
  return new Promise((resolve, reject) => {
    axios
      .get("/data-sources", {
        baseURL: getEndpoint(),
        params: { type: "RS" },
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAPI.requestConnection = function(dsid, params ={}) {
  return new Promise((resolve, reject) => {
    axios
      .post("/data-sources/" + dsid + "/requests", null, {
        baseURL: getEndpoint(),
        headers: { Authorization: AuthService.authorizationHeader() },
        params
      })

      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAPI.connectionRequestState = function(
  dsid,
  requestId,
  requestSecret
) {
  return new Promise((resolve, reject) => {
    axios
      .get("/data-sources/" + dsid + "/requests/" + requestId, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader(),
          sessionKey: requestSecret
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

export default DatasourceAPI;
