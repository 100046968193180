import React from "react";

// Lib
import TextField from '@material-ui/core/TextField';

const CustomTextfield = ({ className, Name, Required, Label, MaxLength, MinLength, Value, Placeholder, handleOnChange, handleOnBlur }) => {
  
  return (
    <TextField
      className={className}
      variant="outlined"
      placeholder={Placeholder}
      aria-describedby={Name}
      label={Label}
      id={Name}
      required={Required}
      name={Name}
      value={Value || ""}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      InputProps={{
            inputProps: { minLength: MinLength, maxLength: MaxLength },
            className: "text-input",
      }}
    />
  );
}

export default CustomTextfield;
