
/**
 * 
 * @param {Array<DataSource>} datasources: takes in a list of data-sources
 * @param {() => {}} callback: takes in a callback that gets called for every iteration on the filtered/sorted array
 */
export function SortedRequiredDataSources(datasources, callback){
    datasources
    .filter((datasource) => datasource.required)
    .sort((a,b) => (b.required_priority) - (a.required_priority))
    .forEach(callback)
  }