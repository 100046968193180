import React from "react";

// Lib
import Moment from 'react-moment';
import i18n from "../../../i18n";

// Components
import TableDesktopVersion from "../presentation/TableDesktopVersion";
import TableMobileVersion from "../presentation/TableMobileVersion";

// Services
import ProfileService from "../../Services/ProfileService";

const VerifiedCredentialContainer = ({
  verifiedCredentialsDetailsfields,
  verifiedCredentialsActivityfields,
  detailsData,
  credential,
  onSortClick,
  handleOnSortClick,
  paginationPerPage,
  isLoading,
  customVerifiedCredentialActivitySort,
  customVerifiedCredentialDetailsSort,
  isInternetDisconnected,
  uniqueTableIdentifier
}) => {
 
  //##############################################################################
  // Formatting fields data with table settings for Verfified Credentials Details
  //###############################################################################

  const verifiedCredentialsDetailsHeaderfields = verifiedCredentialsDetailsfields && verifiedCredentialsDetailsfields.map(item => {

    // Add custom settings for each column    
    if (item.selector === "type") {
      item.sortable = true;
      item.cell = row => (
        <strong>{row.name}</strong>
      )
    }

    if (item.selector === "information") {
      item.cell = row => (
        <p>{row.value}</p>
      )
    }

    if (item.selector === "mobile" && item.type === "verified_credentials_details") {
      item.cell = row => (
        mobileTableCredentialsDetailsSettings(row)
      )
    }

    return item;
  });

  const mobileTableCredentialsDetailsSettings = (row) => {
    return (
      <div className="mobile-wiew-wrapper">
        <div className="left-side">

          <strong>{row.name}</strong>
            
          <div className="sub-content">
            <p>{row.value}</p>
          </div>
        </div>
      </div>
    )
  }


  //###############################################################################
  // Formatting fields data with table settings for Verfified Credentials Activity
  //###############################################################################

  const verifiedCredentialsActivityHeaderfields = verifiedCredentialsActivityfields && verifiedCredentialsActivityfields.map(item => {

    // Add custom settings for each column    
    if (item.selector === "information") {
      item.sortable = true;
      item.cell = row => (
        <div 
          className="activity-information"
          dangerouslySetInnerHTML = {{ __html: i18n.t(
            row.type === "SHARED" ? "connectionDetail__verfied_credential_activity_table_information_shared" : "connectionDetail__verfied_credential_activity_table_information", { type: row.type, value: row.value  }) }}
        />
      )
    }

    if (item.selector === "activity") {
      item.cell = row => (
        <Moment 
          fromNow
          locale={ProfileService.getBrowserLanguage()}
        >
          {
            row.time
          }
        </Moment> 
      )
    }

    if (item.selector === "mobile" && item.type === "verified_credentials_activity") {
      item.cell = row => (
        mobileTableVerfiedCredentialActivitySettings(row)
      )
    }

    return item;
  });

  const mobileTableVerfiedCredentialActivitySettings = (row) => {
    return (
      <div className="mobile-wiew-wrapper">
        <div className="left-side">

          <div 
            className="activity-information"
            dangerouslySetInnerHTML = {{ __html: i18n.t("connectionDetail__verfied_credential_activity_table_information", { type: row.type, value: row.value  }) }}
          />
            
          <div className="sub-content">
            <Moment 
              fromNow
              locale={ProfileService.getBrowserLanguage()} 
            >
              {
                row.time
              }
            </Moment> 
          </div>
        </div>
      </div>
    )
  }

  const handleSortIconDisplay = (isSorting) => {
    if (isSorting) {
      return (
        <div className="sortIcon textPrimaryColor caretUp">&#9662;</div>
      )
    } else {
      return (
        <div className="sortIcon textPrimaryColor">&#9662;</div>
      )
    }
  }

  return (
    <div className="connectionDetail-serviceProvider verified-credential">
      <div className="connectionDetail-section-wrapper">

        {/* Top Description */}

        <div className="topBar">
          <div 
            className="sub-heading sub-heading--single" 
            dangerouslySetInnerHTML={{ __html: i18n.t('connectionDetail__verfied_credential_subHeading', { issuerName: detailsData.vc_stakeholder.name, interpolation: {escapeValue: true} }) }} 
          />
          
          {/* Revoked Header */}

          {
            credential.revoked
            &&
            <>
              <div className="error">{i18n.t("connectionDetail__verfied_credential_revoked_error")}</div>

              <div 
                className="revoked-error-message" 
                dangerouslySetInnerHTML={{ __html: i18n.t('connectionDetail__verfied_credential_revoked_message', { issuerName: detailsData.vc_stakeholder.name, interpolation: {escapeValue: true} }) }} 
              />
            </>
          }
          
          {/* Credentials Accepted but Not Ready Header */}

          {
            (!credential.revoked && credential.state === "ACCEPTED")
            &&
            <div 
              className="not-issued-message" 
              dangerouslySetInnerHTML={{ __html: i18n.t('connectionDetail__verfied_credential_not_ready_message') }} 
            />
          }
        </div>
        
        {/* VC Details Table */}

        {
          (!credential.revoked && credential.attributes?.length)
          ?
          <>
            <TableDesktopVersion
              isLoading={isLoading}
              isInternetDisconnected={isInternetDisconnected}
              tableData={credential.attributes}
              onSortClick={onSortClick}
              handleOnSortClick={() => handleOnSortClick("VC_DETAILS")}
              customSort={customVerifiedCredentialDetailsSort}
              fields={verifiedCredentialsDetailsHeaderfields}
              paginationPerPage={paginationPerPage}
              sortIcon={handleSortIconDisplay(uniqueTableIdentifier === "VC_DETAILS" && onSortClick)}
            /> 

            {/* </Mobile Version> */}

            <TableMobileVersion
              isLoading={isLoading}
              isInternetDisconnected={isInternetDisconnected}
              tableData={credential.attributes}
              onSortClick={onSortClick}
              handleOnSortClick={() => handleOnSortClick("VC_DETAILS")}
              customSort={customVerifiedCredentialDetailsSort}
              fields={verifiedCredentialsDetailsHeaderfields}
              paginationPerPage={paginationPerPage}
              sortIcon={handleSortIconDisplay(uniqueTableIdentifier === "VC_DETAILS" && onSortClick)}
            />
          </>
          :
          null
        }

        {/* Activity title */}
        
        <div className="activity-bar">
          <div className="heading">
            <h2 className="title">{i18n.t("connectionDetail__verfied_credential_activity_table_information_header")}</h2>
          </div>
        </div>

        {
          (credential.credentialActivity?.length && (credential.state === "READY" || credential.revoked))
          ?
          <>
            {/* VC Details Activity */}
    
            <TableDesktopVersion
              isLoading={isLoading}
              isInternetDisconnected={isInternetDisconnected}
              tableData={credential.credentialActivity}
              onSortClick={onSortClick}
              handleOnSortClick={() => handleOnSortClick("ACTIVITY")}
              customSort={customVerifiedCredentialActivitySort}
              fields={verifiedCredentialsActivityHeaderfields}
              paginationPerPage={paginationPerPage}
              sortIcon={handleSortIconDisplay(uniqueTableIdentifier === "ACTIVITY" && onSortClick)}
            /> 

            {/* </Mobile Version> */}

            <TableMobileVersion
              isLoading={isLoading}
              isInternetDisconnected={isInternetDisconnected}
              tableData={credential.credentialActivity}
              onSortClick={onSortClick}
              handleOnSortClick={() => handleOnSortClick("ACTIVITY")}
              customSort={customVerifiedCredentialActivitySort}
              fields={verifiedCredentialsActivityHeaderfields}
              paginationPerPage={paginationPerPage}
              sortIcon={handleSortIconDisplay(uniqueTableIdentifier === "ACTIVITY" && onSortClick)}
            />
          </>
          :
          <div className="no-activity-error">{i18n.t("connectionDetail__verfied_credential_no_activity_message")}</div>
        }
      </div>
    </div>
  );
}

export default VerifiedCredentialContainer;
