import axios from "axios";

let IdpAPI = {};

// !!!: better way to inject url base into request
var getEndpoint = function() {
    return window.config.url;
};

IdpAPI.getIdps = function() {
  return new Promise((resolve, reject) => {
    axios
      .get("/login/idps", { baseURL: getEndpoint() })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

IdpAPI.requestIdpAuth = function(idpId, autoIdp=false) {
  return new Promise((resolve, reject) => {
    axios
      .post("/login/idps/" + idpId + "/requests", (!autoIdp ? null: {}), {
        baseURL: getEndpoint(),
        headers: {
          excludeInterceptor: true,
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

IdpAPI.requestIdpAuthState = function(idpId, requestId, requestSecret) {
  return new Promise((resolve, reject) => {
    axios
      .get("/login/idps/" + idpId + "/requests/" + requestId, {
        baseURL: getEndpoint(),
        headers: {
          sessionKey: requestSecret,
          excludeInterceptor: true,
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

export default IdpAPI;
