import React from "react";

// Lib
import Helmet from "react-helmet";

// Components
import Header from "../../Header";

// Services
import i18n from "../../../i18n";

// Components
import ProofRequestContainer from "../container/ProofRequestContainer";

const ProofRequestPage = ({ isInternetDisconnected, handleToggleNetworkModal, match, history }) => {
  return (
    <div className="consent-page proof-request-page">
      <Helmet title={i18n.t("proofRequest__page_title")} />  

      <div className="header-wrapper">
        <Header />
      </div>

      <ProofRequestContainer 
        proofID={match.params?.proofRequestID} 
        isInternetDisconnected={isInternetDisconnected}   
        handleToggleNetworkModal={handleToggleNetworkModal} 
        history={history}        
      />
    </div>
  );
}

export default ProofRequestPage;
