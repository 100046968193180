import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";
import Header from "../../Header";
import i18n from "../../../i18n";

//Component
import FindConnectionContainer from "../container/FindConnectionContainer";

class FindConnectionPage extends Component {

  render() {
    const {
      handleToggleNetworkModal,
      isInternetDisconnected,
      handleReload,
    } = this.props;
   
    return (
      <div className="consent-page find-connection">
        <Helmet title={i18n.t("findConnections__title")} />
        
        <div className="header-wrapper">
          <Header />
        </div>
        
        <FindConnectionContainer 
          isLoginFailedError={this.props.location.state && this.props.location.state.error} 
          dataSource={this.props.location.state && this.props.location.state.dataSource} 
          previousPage={this.props.location.state && this.props.location.state.previousPage}
          handleToggleNetworkModal={handleToggleNetworkModal} 
          isInternetDisconnected={isInternetDisconnected} 
          handleReload={handleReload}
        />
      </div>
    );
  }
}

export default FindConnectionPage;