import React, { Component } from "react";

// Components
import ConnectRenewButton from "../../ConnectRenewButton";

// Services
import i18n from "../../../i18n";

class ResourceItem extends Component {

  renderActionButton = (account, source) => {
    const {
      isSendingReq,
      clickedRsID,
      isInternetDisconnected,
      handleToggleNetworkModal,
      useConnectRsAction,
      handleConnectRsAction,
    } = this.props;
   
    let isReconnectBtn = false;
    let isUseConnectionBtn = false;
    let handleSubmitAction = handleConnectRsAction;
    let buttonType = "CONNECT_CONNECTION";
    let tempAccount = {};
    let dsAccountID = "";

    // Check if they have connected Accounts
    if (Object.keys(account).length !== 0) {
      tempAccount = account;
      dsAccountID = account.ds_account_id;

      // 1. Use Connection Button
      if (account.expired === false && !account.disabled) {
        handleSubmitAction = useConnectRsAction;
        isUseConnectionBtn = true;
        buttonType = "USE_CONNECTION";
      }
  
      // 2. Reconnect Button
      if (account.expired === true || account.disabled) {
        isReconnectBtn = true;
        buttonType = "RENEW_CONNECTION";
      }
    } else {
      tempAccount = source;
      dsAccountID = source.data_source_id;
    }

    return (
      <ConnectRenewButton 
        isSendingReq={isSendingReq}
        clickedRsID={clickedRsID}
        dsAccountID={dsAccountID}
        isInternetDisconnected={isInternetDisconnected}
        handleToggleNetworkModal={handleToggleNetworkModal}
        isReconnectBtn={isReconnectBtn}
        isUseConnectionBtn={isUseConnectionBtn}
        handleSubmitAction={handleSubmitAction}
        buttonType={buttonType}
        account={tempAccount}
      />
    )
  }

  renderErrorMessage = (account, source) => {
    const { hasError, clickedRsID } = this.props;

    let errorMessage = "";
    let dsAccountID = (Object.keys(account).length !== 0) ? account.ds_account_id : source.data_source_id;
    
    // If the api fails Error
    if (hasError && (clickedRsID === dsAccountID)) {
      errorMessage = i18n.t("modal__connect_api_error");
    } else {
      if (Object.keys(account).length !== 0) {
        // If the expired Error
        if ((account.expired === true) && !account.disabled) {
          errorMessage = i18n.t("findConnections__reconnect_button_expired_error_msg");
        }
        
        // If the disconnected Error
        if (account.disabled) {
          errorMessage = i18n.t("findConnections__reconnect_button_disconndecte_error_msg");
        }
      }
    }

    return errorMessage;
  }

  render() {
    const { availableResources } = this.props;

    return (
      <ul className="data-sources">
        {
          availableResources.map((source, idx) => (
          <li
            key={idx}
            className={"data-sources-item data-sources-item-multiple"}
          >
            {
                source.connectedAccounts.length > 1 &&
                <div className="source-name-wrapper source-name-wrapper--header">
                <strong>{source.name}</strong>
                </div>
            }

            <ul>
              {
                source.connectedAccounts.map((connectedAccount, i) => (
                  <li key={i} >
                    <div className="source-name-wrapper">
                      {
                        source.connectedAccounts.length > 1 
                        ?
                        <p>{connectedAccount.nickname}</p>
                        :
                        <p>{source.name}</p>
                      }

                      <p className="reconnect-error">
                        {
                          this.renderErrorMessage(connectedAccount, source)
                        }
                      </p>
                    </div>

                    { 
                      this.renderActionButton(connectedAccount, source)
                    }
                  </li>
                ))
              }
            </ul>
          </li>
        ))}
      </ul>
    );
  }
}

export default ResourceItem;
