import React from "react";

// Components
import Spinner from "../../Spinner";

// Lib
import Modal from "react-modal";

// Services
import i18n from "../../../i18n";

class SubmissionRedirectModal extends React.Component {
  handleRedirectToSP = () => {
    const { errorRedirectUrl } = this.props;

    window.location.assign(errorRedirectUrl);
  }

  render() {
    const { isOpen, toggleSubmissionModal, hasError, errorMessage, errorMessageTitle, isErrorRedirecting } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        ariaHideApp={true}
        overlayClassName="modal-overlay"
        contentLabel={i18n.t("submissionModal__processing")}
      >
        {
          !hasError
          ?
          <div className="resource-detail success-modal">
            <h4 className="success-text">{i18n.t('submissionModal__processing')}</h4>
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          </div>
          :
          <div className="modal-wrapper logout-wrapper network-editor submission-error">
            <div className="header">
              <h2>{errorMessageTitle}</h2>
            </div>
            <div className="modal-body">
                {errorMessage}
            </div>
            <div className="footer">
              {
                isErrorRedirecting
                ?
                <button className="disconnectButton redirect-btn backgroundPrimaryColor" onClick={this.handleRedirectToSP}> 
                  {
                    i18n.t("submissionModal__close")
                  }
                </button>
                :
                <button className="disconnectButton backgroundPrimaryColor" onClick={toggleSubmissionModal}> 
                  {
                    i18n.t("submissionModal__close")
                  }
                </button>
              }
            </div>
          </div>
        }

      </Modal>
    );
  }
}

export default SubmissionRedirectModal;