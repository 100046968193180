import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

// Components
import ConnectionSPDetailContainer from "../container/ConnectionSPDetailContainer";

class ConnectionSPDetailPage extends Component {

  render() {
    const { handleReload, isInternetDisconnected, handleToggleNetworkModal } = this.props;

    return (
      <div className="connectionDetail connectionResourceDetail">
        <Helmet title={i18n.t("ConnectionSPDetail__page_title")} />
        <ConnectionSPDetailContainer 
          permissionDetail={this.props.location.state} 
          handleReload={handleReload} 
          isInternetDisconnected={isInternetDisconnected}   
          handleToggleNetworkModal={handleToggleNetworkModal}   
        />
      </div>
    );
  }
}

export default ConnectionSPDetailPage;
