import React, { Component } from "react";

// Lib
import { Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import Header from "../../Header";
import i18n from "../../../i18n";

//Component
import ConfirmAuthorizationContainer from "../container/ConfirmAuthorizationContainer";

class ConfirmAuthorizationPage extends Component {

  render() {
    const { handleToggleNetworkModal, isInternetDisconnected } = this.props;

    // Redirect if no request and devicetoken
    if (!this.props.location.state || !this.props.location.state.request) {
      return (<Redirect to = {window.GLOBAL_PATH} />);
    }

    return (
      <div className="consentQR confirmAuth">
        <Helmet title={i18n.t("confirmAuth__page_title")} />

        <div className="header-wrapper">
          <Header />
        </div>
        
        <ConfirmAuthorizationContainer 
          request={this.props.location.state.request}  
          accessToken={this.props.location.state.deviceToken.access_token} 
          handleToggleNetworkModal={handleToggleNetworkModal} 
          isInternetDisconnected={isInternetDisconnected}        
        />
      </div>
    );
  }
}

export default ConfirmAuthorizationPage;