import i18n from "../../../i18n";

export const handleChecksForErrors = function (DataObj, isLoginFailedError, VCConnectionError, lookup_code, ProofRequestError, selectedDS, walletAccountName, handleAnalyticsTrack, handleToggleErrorModal) {
  // 1. If trying to use a DSA which is laready claimed in another account
    
  if (isLoginFailedError && isLoginFailedError.status === 403) {
    DataObj.hasError = true;
    DataObj.errorTitle = i18n.t("connect_rs__failure_alreadyused_in_other_wallet_403_title");
    DataObj.errorMessage = i18n.t("connect_rs__failure_alreadyused_in_other_wallet_403_desc", {
      rsName: selectedDS.name || selectedDS.data_source.name,
      walletAccountName });
  } else if (lookup_code && lookup_code === "70000") {
    // 2.1 If the VC connections is expired

    DataObj.hasError = true;
    DataObj.errorTitle = i18n.t("profile__vc_connection_expired_title");
    DataObj.errorMessage = i18n.t("profile__vc_connection_expired_description");
    DataObj.errorModalButtonAction = handleToggleErrorModal;
   
    // Analytics
    handleAnalyticsTrack("error", `${i18n.t("profile__vc_connection_expired_title")} - ${i18n.t("profile__vc_connection_expired_description")}`, VCConnectionError);

  } else if (VCConnectionError?.status && VCConnectionError.status !== 401) {
    // 2.1.2 If the VC connections Api fails for some reason except session expires

    DataObj.hasError = true;
    DataObj.errorTitle = i18n.t("profile__vc_connection_generic_error_title");
    DataObj.errorMessage = i18n.t("profile__vc_connection_generic_error_description");
    DataObj.errorModalButtonAction = handleToggleErrorModal;

    // Analytics
    handleAnalyticsTrack("error", `${i18n.t("profile__vc_connection_generic_error_title")} - ${i18n.t("profile__vc_connection_generic_error_description")}`, VCConnectionError);

  } else if (lookup_code && lookup_code === "40530") {

    // 2.2 Request error -  when the flow is already being started on the mobile app or in another web browser

    DataObj.hasError = true;
    DataObj.errorTitle = i18n.t("request_error_title");
    DataObj.errorMessage = i18n.t("request_error_desc");
  } else if (ProofRequestError && lookup_code !== 404) {
    // 2.4 Proof Request offer generic errors
    
    DataObj.hasError = true;
    DataObj.errorTitle = i18n.t("profile__proof_request_generic_error_title");
    DataObj.errorMessage = i18n.t("profile__proof_request_generic_error_description");
    DataObj.errorModalButtonAction = handleToggleErrorModal;
    // Analytics
    handleAnalyticsTrack("error", `${i18n.t("profile__proof_request_generic_error_title")} - ${i18n.t("profile__proof_request_generic_error_description")}`);
  }

  return DataObj;
}