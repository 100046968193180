import React from "react";

// Lib
import i18n from "../../../i18n";

// Components
import ScopeModal from "./ScopeModal";
import ProofItemCredentials from "./ProofItemCredentials";

const ProofItem = ({ proofRequest, isScopeModalOpen, handleToggleScopeModal, isLoadingCredential, toggleErrorModal, selectedCredential }) => {
  return (
    <div className="steps-container">
      <ul className="rslist">
        <li 
          className={"rs-item"}
        >
          <div className="container">
            <div className="header">
              <div className="title-container">
                <div className="title">
                  {`${i18n.t("proofRequest_page__proof_item_text")} ${proofRequest.name}`}
                </div>
             
                {/* Scope Modal Button */}

                <button 
                  className="see-details textPrimaryColor"
                  onClick={handleToggleScopeModal} 
                >
                  { i18n.t("consent__whats_this")}
                </button>
              </div>
            </div>

            {/* Render Rs */}
        
            <ProofItemCredentials 
              isLoadingCredential={isLoadingCredential} 
              toggleErrorModal={toggleErrorModal}
              selectedCredential={selectedCredential}
            />
          </div>     
          
          {/* Scopes Modal */}

          {
            (isScopeModalOpen)
            &&
            <ScopeModal
              isOpen={isScopeModalOpen} 
              proofRequest={proofRequest} 
              handleToggleScopeModal={handleToggleScopeModal}
            />
          }
        </li>
      </ul>
    </div>
  );
}

export default ProofItem;
