import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

// Components
import ConnectionDetailContainer from "../container/ConnectionDetailContainer";

class ConnectionDetailPage extends Component {

  render() {
    const { handleReload, isInternetDisconnected, handleToggleNetworkModal } = this.props;
    
    return (
      <div className="connectionDetail">
        <Helmet title={i18n.t("connectionDetail__page_title")} />
        <ConnectionDetailContainer  
          detailsData={this.props.location.state && this.props.location.state.data} 
          handleReload={handleReload} 
          isInternetDisconnected={isInternetDisconnected}   
          handleToggleNetworkModal={handleToggleNetworkModal}
        />
      </div>
    );
  }
}

export default ConnectionDetailPage;
