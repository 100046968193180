import { isIOS, isAndroid } from 'react-device-detect';

export const detectDevice = () => {
  let device = "web";

  if (isIOS) {
    device = "ios";
  }

  if (isAndroid) {
    device = "android";
  }

  return device;
}


export const searchObjByKeyValue = (keyToSearch, obj, value) => {
  return new Promise((resolve) => {
    let result = {};
    if (obj && obj !== "null" && obj !== "undefined") {
      Object.keys(obj).forEach(item => {
        if (obj[item][keyToSearch] && obj[item][keyToSearch] !== "null" && obj[item][keyToSearch] !== "undefined") {
          if (obj[item][keyToSearch].toString() === value.toString()) {
            result = obj[item];
          }
        }
      });
    }
  
    resolve(result);
  })
}
