import React, { Component } from "react";

// Libraries
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import Moment from 'react-moment';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

class NotificationItem extends Component {
  handleNotificationItemClick = () => {
    const { notification, handleNotificationClick } = this.props;

    handleNotificationClick(notification);
  }

  render() {
    const { notification } = this.props;

    return (
      <div onClick={this.handleNotificationItemClick} className="notification-item-wrapper">
        {
          notification.type === 'Renew Connection'
          ?
          <div className="icon-container icon-container--yellow">
            <ErrorRoundedIcon />
          </div>
          :
          <div className="icon-container icon-container--blue">
            <ArrowRightAltRoundedIcon />
          </div>
        }

        <div className="notification-item">
          <div className="left-data">
            {
              notification.type
            }
          </div>
          <div className="mid-data">
            <div className="title">
              {
                notification.title
              }
            </div>

            <div className="body">
              {
                notification.information
              }
            </div>
          </div>
          <div className="right-data">
            <div>
              <Moment fromNow className="last-active">{notification.lastActive}</Moment>
            </div>
            <ArrowRightIcon className="caretStyle textPrimaryColor" />
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationItem;