import React from "react";

// Lib
import MediaQuery from 'react-responsive';

// Components
import CustomTable from "../../CustomTable";

const TableDesktopVersion = ({
    isLoading,
    isInternetDisconnected,
    tableData,
    onSortClick,
    handleOnSortClick,
    customSort,
    fields,
    paginationPerPage,
    sortIcon
}) => {
    return (
        <MediaQuery minDeviceWidth={751}>
            <CustomTable
                isLoading={isLoading}
                isInternetDisconnected={isInternetDisconnected}
                tableData={tableData}
                onSortClick={onSortClick}
                handleOnSortClick={handleOnSortClick}
                customSort={customSort}
                fields={fields}
                paginationPerPage={paginationPerPage}
                sortIcon={sortIcon}
            />  
        </MediaQuery>
    );
}

export default TableDesktopVersion;
