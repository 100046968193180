import React from "react";

// Lib
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Moment from 'react-moment';
import i18n from "../../../i18n";

// Components
import TableDesktopVersion from "../presentation/TableDesktopVersion";
import TableMobileVersion from "../presentation/TableMobileVersion";

const InformationSourceContainer = ({
  informationSourcefields,
  handleClassnameForDisabled,
  detailsData,
  handleShowRowMenu,
  showRowMenu,
  handleRef,
  handleToggleDisconnectDetailsModal,
  my_activity_configs,
  isLoading,
  isInternetDisconnected,
  onSortClick,
  handleOnSortClick,
  paginationPerPage,
  handleMobileDevice,
  handleDisconnectSourceButtonClick,
  handleReconnectSourceButtonClick,
  handleToggleEditNickNameModal,
  clickedRow,
  history,
  customInformationSourceSort
}) => {
  
  //##################################################################
  // Formatting fields data with table settings for Information source
  //##################################################################

  const informationSourceHeaderFields = informationSourcefields && informationSourcefields.map(item => {

    // Add custom settings for each column
    if (item.selector === "information") {
      item.sortable = true;
      item.cell = row => (
        <React.Fragment>
          {
            row.resource.timesShared === 0
            ?
            <div className="name-button--disabled">
              {
                row.resource.isDisabled
                && 
                <span className="disconnected">{i18n.t("connectionDetail__disconnected")}</span>
              } 
              <div className="informationText--notShared">
                {row.resource.name}
              </div> 
            </div>
            :
            <button 
              className="name-button textPrimaryColor"
              onClick={(e) => handleDataSourceNameClick(e, row)}
            >
              {
                row.resource.isDisabled
                && 
                <span className="disconnected">{i18n.t("connectionDetail__disconnected")}</span>
              } 
              <div className="informationText">
                {row.resource.name}
              </div> 

              <ArrowRightIcon className="caretStyle textPrimaryColor" />
            </button>
          }
        </React.Fragment>
      )
    }
    
    if (item.selector === "recentActivity") {
      item.cell = row => (
        row.resource.recentActivity 
          ? 
        <Moment 
          format="YYYY/MM/DD" 
          className={handleClassnameForDisabled(row.resource.isDisabled, "last-active")} 
        >
          {
            row.resource.recentActivity
          }
        </Moment> 
        : 
        <div className="empty">&#8213;</div>
      )
    }

    if (item.selector === "expires") {
      item.cell = row => (
        row.rot.expires_at
        ? 
        <Moment 
          format="YYYY/MM/DD" 
          className={handleClassnameForDisabled(row.resource.isDisabled, "last-active")} 
        >
          {
            row.rot.expires_at
          }
        </Moment>  
        : 
        <div className="empty">&#8213;</div>
      )
    }
    
    if (item.selector === "actionsMenu") {
      item.cell = row => {
        row.table_type = 'INFORMATION_SOURCE';
        return (<React.Fragment>
          {
            !row.resource.isDisabled && row.resource.timesShared !== 0
            && 
            <button 
              className="actionButton textPrimaryColor actionButton--information-source" 
              onClick={(e) => handleShowRowMenu(e, row)} 
              aria-label="more actions"
            >
              <MoreHorizIcon className="dots" />
            </button>
          }

          { 
            renderInformationSourceMenu(row)
          }
        </React.Fragment>)
      }
    }

    if (item.selector === "mobile") {
      item.cell = row => {
        row.table_type = 'INFORMATION_SOURCE';
        return (
          mobileTableSettings(row)
        )
      }
    }

    return item;
  });

  const mobileTableSettings = (row) => {
    return (
      <div className="mobile-wiew-wrapper">
        <div className="left-side">

          {
            row.resource.timesShared === 0
            ?
            <div className="name-button--disabled">
              {
                row.resource.isDisabled
                && 
                <span className="disconnected">{i18n.t("connectionDetail__disconnected")}</span>
              } 
              <div className="informationText--notShared">
                {row.resource.name}
              </div> 
            </div>
            :
            <button 
              className="name-button textPrimaryColor"
              onClick={(e) => handleDataSourceNameClick(e, row)}
            >
              {
                row.resource.isDisabled
                && 
                <span className="disconnected">{i18n.t("connectionDetail__disconnected")}</span>
              } 
              <div className="informationText">
                {row.resource.name}
              </div> 
              <ArrowRightIcon className="caretStyle textPrimaryColor" />
            </button>
          }
          
          <div className="sub-content">
            
            {
              row.resource.recentActivity 
              ? 
              <div className={handleClassnameForDisabled(row.resource.isDisabled, "time-wrapper")} >
                <span>{i18n.t("connectionDetail__activity")}:</span> 
                <Moment 
                  format="YYYY/MM/DD" 
                >
                  {
                    row.resource.recentActivity
                  }
                </Moment> 
              </div>
              : 
              <div className={handleClassnameForDisabled(row.resource.isDisabled, "time-wrapper")}>
                <span>{i18n.t("connectionDetail__activity")}:</span>
                <div className="empty">&#8213;</div>
              </div>
            }
          </div>
          
        </div>

        <div className="right-side">
          {
            !row.resource.isDisabled && row.resource.timesShared !== 0
            && 
            <button 
              className="actionButton textPrimaryColor actionButton--information-source" 
              onClick={(e) => handleShowRowMenu(e, row)} 
              aria-label="more actions"
            >
              <MoreHorizIcon className="dots" />
            </button>
          }

          { 
            renderInformationSourceMenu(row)
          }
        </div>
      </div>
    )
  }

  const renderInformationSourceMenu = (row) => {
    if (showRowMenu && row.resource.res_def_id === clickedRow.resource.res_def_id) {
      return (
        <div 
          className="menu-wrapper" 
          ref={handleRef}
        >
          <button onClick={handleToggleDisconnectDetailsModal}>{i18n.t("connectionDetail__disconnectInformation")}</button>
        </div>
      )
    }
  }

  // Name click 
  const handleDataSourceNameClick = (e, selectedrow) => {
    // Prevent parent event
    e.stopPropagation();
    
    history.push({
      pathname: window.GLOBAL_PATH + `my-activity/connections/${detailsData.orgInfo.name}/${detailsData.ds_account_id}/${selectedrow.resource.res_def_id}`,
      state: {
        selectedrow,
        detailsData
      }
    })
  }

  // Render Table Header

  const renderTableHeader = () => {
    if (my_activity_configs.multiple_accounts_enable) {
      return  (
        <div className="account-heading">
          <h3>
            {detailsData.nickname || `-`}

            {
              (!detailsData.isDisabled && !detailsData.expired)
              &&
              <button
                onClick={handleToggleEditNickNameModal}
                className="edit-button textPrimaryColor"
              >
                <span>{i18n.t("connectionDetail__edit")}</span>
                <EditRoundedIcon />
              </button>
            } 
          </h3>

          {
            (detailsData.isDisabled || detailsData.expired)
            &&
            <div className="disconnected">
              {i18n.t("connectionDetail__disconnected")}
            </div>
          }

          {/* 
            There shouldn't be a disconnect button in the my activity details page of the required RS.
          */}
          {
            ((!detailsData.isDisabled && !detailsData.expired) && !detailsData.data_source.required)
            ?
            <button 
              className="disconnectButton" 
              onClick={(e) => handleDisconnectSourceButtonClick(e, detailsData)} 
            >
              {
                i18n.t("connectionDetail__disconnect_all")
              }
            </button>
            :
            <button 
              className="disconnectButton reconnectButton textPrimaryColor borderPrimaryColor" 
              onClick={handleReconnectSourceButtonClick} 
            >
              {
                i18n.t("connectionDetail__reconnectInformationSource")
              }
            </button>
          }
        </div>
      )
    }
  }
  
  return (
    <div className="connectionDetail-serviceProvider">
      <div className="connectionDetail-section-wrapper">

        {/* Top Bar */}

        <div className="topBar">
          <div className="heading">
            <h2 className="title">{i18n.t("connectionDetail__informationSource")}</h2>
          </div>

          <div 
            className="sub-heading sub-heading--single" 
            dangerouslySetInnerHTML={{ __html: i18n.t('connectionDetail__informationSubHeading', { name: detailsData.orgInfo.name, interpolation: {escapeValue: true} }) }} 
          />
        </div>

        {/* Table header */}

        {
          renderTableHeader()
        }
        
        {/* Desktop Version */}

        <TableDesktopVersion
          isLoading={isLoading}
          isInternetDisconnected={isInternetDisconnected}
          tableData={detailsData.data_source.resources}
          onSortClick={onSortClick}
          handleOnSortClick={handleOnSortClick}
          customSort={customInformationSourceSort}
          fields={informationSourceHeaderFields}
          paginationPerPage={paginationPerPage}
          sortIcon={onSortClick ? <div className="sortIcon textPrimaryColor caretUp">&#9662;</div> : <div className="sortIcon textPrimaryColor">&#9662;</div>}
        />

        {/* </Mobile Version> */}

        <TableMobileVersion
          handleMobileDevice={handleMobileDevice}
          isLoading={isLoading}
          isInternetDisconnected={isInternetDisconnected}
          tableData={detailsData.data_source.resources}
          onSortClick={onSortClick}
          handleOnSortClick={handleOnSortClick}
          customSort={customInformationSourceSort}
          fields={informationSourceHeaderFields}
          sortIcon={onSortClick ? <div className="sortIcon textPrimaryColor caretUp">&#9662;</div> : <div className="sortIcon textPrimaryColor">&#9662;</div>}
        />
            
      </div>
    </div>
  );
}

export default InformationSourceContainer;
