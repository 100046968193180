import React from "react";

import Modal from "react-modal";

class ErrorModal extends React.Component {
  handleReload = () => {
    window.location.reload();
  }

  render() {
    const { errorTitle, errorMessage, isOpen, handleToggleModal, errorButtonText } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        ariaHideApp={true}
        overlayClassName="modal-overlay"
        contentLabel={errorTitle}
      >
        <div className="modal-wrapper error-modal">
          <h2 className="header">
            {errorTitle}
          </h2>
          <div className="modal-body">
              {errorMessage}
          </div>
          <div className="footer">
            <button onClick={handleToggleModal ? handleToggleModal : this.handleReload} className="okBtn  backgroundPrimaryColor">{errorButtonText}</button>
          </div>
        </div>
      </Modal>
    );
  }

}

export default ErrorModal;