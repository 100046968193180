import React, { Component } from "react";

// Lib
import i18n from "../../../i18n";
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';

// Components
import Spinner from "../../Spinner";

class DisconnectSourceModal extends Component {

  render() {
    const {
      isDisconnectSourceModalOpen,
      handleCloseDisconnectSourceModal,
      handleDisconnectDataSource,
      handleDisconnectClientServiceProvider,
      detailsData,
      selectedClient,
      table_type,
      isSendingReq,
      isInternetDisconnected,
      handleToggleNetworkModal
    } = this.props;
  
    return (
      <Modal
        isOpen={isDisconnectSourceModalOpen}
        ariaHideApp={true}
        onRequestClose={handleCloseDisconnectSourceModal}
        overlayClassName="modal-overlay"
        contentLabel={i18n.t("connectionDetail__disconnect_all")}
      >
        <div className="modal-wrapper disconnect-Details-modal">
          <button className="close-icon" onClick={handleCloseDisconnectSourceModal} aria-label={i18n.t("connectionDetail__aria_label_close_button")}>
            <CloseIcon className="arrow-icon" />
          </button>

          <h2 className="header">
            {i18n.t("connectionDetail__disconnect_all")}
          </h2>
          <div className="modal-body">
            {
              table_type === "SERVICE_PROVIDER"
              ?
              <div className="sub-heading-top" dangerouslySetInnerHTML={{ __html: i18n.t('connectionDetail__informationModalBodyTop', { connectionName: selectedClient.name, interpolation: {escapeValue: true} }) }} />
              :
              <div className="sub-heading-top" dangerouslySetInnerHTML={{ __html: i18n.t('connectionDetail__informationModalBodyTop', { connectionName: `${detailsData.data_source?.name} - ${detailsData.nickname}`, interpolation: {escapeValue: true} }) }} />
            }
            <div className="sub-heading-bottom">
              {i18n.t("connectionDetail__informationModalBodyBottom")} 
            </div>
          </div>
          <div className="footer">
            <button 
              className="noThanksButton textPrimaryColor borderPrimaryColor"
              onClick={handleCloseDisconnectSourceModal}
              disabled={isSendingReq} 
            >
              {
                i18n.t("connectionDetail__cancel")
              }
            </button>

            {
              isInternetDisconnected
              ?
              <button 
                className="disconnectButton backgroundPrimaryColor"
                onClick={handleToggleNetworkModal} 
              >
                {
                  i18n.t("connectionDetail__disconnect")
                }
              </button>
              :
              isSendingReq
              ?
              <button className="disconnectButton backgroundPrimaryColor button--spinner"> 
                <Spinner />
              </button>
              :
              <button className="disconnectButton backgroundPrimaryColor" onClick={table_type === "SERVICE_PROVIDER" ? handleDisconnectClientServiceProvider : handleDisconnectDataSource}> 
                {
                  i18n.t("connectionDetail__disconnect_all")
                }
              </button>
            }
          </div>
        </div>
      </Modal>
    );
  }
}

export default DisconnectSourceModal;
