import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

// Components
import ProfileContainer from "../container/ProfileContainer";

class ProfilePage extends Component {
  render() {
    const { isInternetDisconnected, handleToggleNetworkModal, location } = this.props;
    
    return (
      <div className="profile">
        <Helmet title={i18n.t("profile__profile_title")} />
        <ProfileContainer 
          lookup_code={location.state?.lookup_code} 
          ProofRequestError={location.state?.ProofRequestError}
          VCConnectionError={location.state?.VCConnectionError}
          VCConnectionDetails={location.state?.VCConnectionDetails}
          dataSource={location.state?.dataSource} 
          isLoginFailedError={location.state?.error} 
          isInternetDisconnected={isInternetDisconnected}   
          handleToggleNetworkModal={handleToggleNetworkModal}
          isVCConnectionAdded={location.state?.isVCConnectionAdded}
        />
      </div>
    );
  }
}

export default ProfilePage;