import React, { Component } from "react";

// Library
import AnalyticsService from "analytics-web";
import { withRouter } from 'react-router-dom';

// Components
import NotificationItem from "../presentation/NotificationItem";
import ErrorModal from "../../ErrorModal";
import ConnectionRequiredModal from "../presentation/ConnectionRequiredModal";
import EditNicknameModalConsent from "../../EditNicknameModalConsent";
import NewAccountConnectionModal from "../../NewAccountConnectionModal";
import IconSuccessModal from "../../IconSuccessModal";
import CredentialNotification from "../presentation/CredentialNotification";
import CredentialOfferModal from "../presentation/CredentialOfferModal";
import DeclineModal from "../../DeclineModal";

// Services
import ProfileService from "../../Services/ProfileService";
import DatasourceService from "../../Services/DatasourceService";
import ProofRequestsService from "../../Services/ProoofRequestsService";
import { AuthService } from "../../Services/AuthService";
import i18n from "../../../i18n";
import { handleChecksForErrors } from "../services";

// Utils
import  { delegatedAccountsCheck } from  "../../../utils/DelegatedAccounts";
import PromiseAllsettled from "../../../utils/PromiseAllsettled";
import { isDataSourceConnectedToAccounts } from "../../../utils/isDataSourceConnected";
import { SortedRequiredDataSources } from "../../../utils/SortedRequiredDataSources";
import { CustomLocalizationText } from "../../../utils/CustomLocalizationText";

// Images
import PlaceholderLogoImg from "../../../assets/logo_placeholder.png";

class ProfileContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      error: null,
      isSecure: true,
      isLoadingProfile: true,
      profile: {
        email: "",
        name: "",
        idp_name: "",
        logo_url: "",
        sub: "",
        verified_sources: []
      },
      lookup_code: this.props.lookup_code,
      hasError: false,
      errorTitle: "",
      errorMessage: "",
      errorButtonText: i18n.t("ok"),
      profile_configs: window.config.profile_configs,

      isOpenConnectionRequiredModal: false,
      isExpiredConnectionRequired: false,
      connectionRequiredModalTitle: "",
      connectionRequiredModalBody: "",
      connectionRequiredModalButton1: "",
      connectionRequiredModalButton2: "",
      requiredDatasource: this.props.dataSource || {},      
      isSendingReq: false,
      connectedAccounts: [],
      datasourcesList: [],
      errorModalButtonAction: this.handleToggleErrorModal,

      changeNickname: false,
      editNicknameTitle: "",
      editNicknameDesc: "",
      changeNicknameValues: {},
      expiredAccountWithNewCredModalOpen: false,

      walletAccountName: window.config.wallet_account_name,
      my_activity_configs: window.config.my_activity_configs,

      VCConnectionAddedModalToggle: this.props.isVCConnectionAdded,
      selectedOffer: {},
      credentialOfferModalToggle: false,
      declineModalToggle: false,
      expiredOfferModal: false,
      credentialOffersList: [],
      proofRequestsList: [],
    };
  }

  componentDidMount = () => {
    const { isInternetDisconnected, dataSource } = this.props;
    let { 
      connectedAccounts,
      profile_configs
    } = this.state;
    // })

    //Analytics on Load
    this.handleAnalyticsTrack("onLoad_event");

    // ==================================
    // Api calls For Required Connections
    // ==================================

    // Api calls if internet is active and without any errors
    if (!isInternetDisconnected) { 

      // 0. Get User Profile Api

      ProfileService.getMyProfile().then(myProfile => {
        this.setState({ profile: myProfile })
      });
      
      let promises = [];

      //====== Verifier Credentials Offer Start =========
      //Call the function so that it fetch first time right after mounting the component
      this.FetchVCOffersList()

      //Polling
      this.VCOffersPolling = setInterval(this.FetchVCOffersList, profile_configs.verifiableCredentialOfferInterval);

      //====== Verifier Credentials Offer End =========

     // ====== Verifier Credentials Proof Request Start =========
      //Call the function so that it fetch first time right after mounting the component
      
      this.FetchVCProofRequestList()

      //Polling
      this.VCProofRequestsPolling = setInterval(this.FetchVCProofRequestList, profile_configs.verifiableCredentialProofRequestInterval);

      //====== Verifier Credentials Proof Request End =========
      
      //1. Get all Data sources
  
      promises.push(new Promise((resolve, reject) => {
        DatasourceService.getAllDatasources().then(datasources => {
          resolve(datasources);
        }).catch(e => {
          reject(e)
        })
      }));
      
      //2. Get user Data sources
  
      promises.push(new Promise((resolve, reject) => {
        DatasourceService.getMyDatasourceAccounts().then(accounts => {
          // 2.1 Sort based on disabled enabled
          const sortedDsAccounts = accounts.sort((a, b) => Boolean(a.disabled) - Boolean(b.disabled));
  
          // 2.1 Check expiry
          sortedDsAccounts.map(account => DatasourceService.isAccountExpired(account));
  
          resolve(sortedDsAccounts);
        }).catch(e => {
          reject(e)
        })
      }));

      //Get connected accounts history

      promises.push(new Promise((resolve, reject) => {
        DatasourceService.getConnectedAccountsHistory().then(result => {
          resolve(result);
        }).catch(e => {
          reject(e)
        })
      }))

      //4. Call to get the relations

      if (dataSource && !dataSource.error) { 
        promises.push(
          new Promise((resolve, reject) => {
            DatasourceService.getDatasourceByIDRelations(dataSource.data_source.data_source_id).then((result) => {
              resolve(result);
            }).catch(e => {
              reject(e)
            })
          })
        );
      }

      return Promise.all(promises).then((results) => { 
        const datasourcesList = results[0];
        connectedAccounts =  results[1];
        const connectedAccountsHistory = results[2];
        const getRelationsByID = results[3];
        
        let res = {
          connectedAccounts,
          datasourcesList,
          getRelationsByID
        };

        // Checks for Success Logins or Errors

        res = this.handleChecksForErrorsAndSuccessLogins(connectedAccountsHistory, res);

        this.setState({
          hasError: res.hasError,
          errorTitle: res.errorTitle,
          errorMessage: res.errorMessage,
          errorModalButtonAction: res.errorModalButtonAction,
          isLoadingProfile: false,
          isOpenConnectionRequiredModal: res.isOpenConnectionRequiredModal,
          isExpiredConnectionRequired: res.isExpiredConnectionRequired,
          connectionRequiredModalTitle: res.connectionRequiredModalTitle,
          connectionRequiredModalBody: res.connectionRequiredModalBody,
          connectionRequiredModalButton1: res.connectionRequiredModalButton1,
          connectionRequiredModalButton2: res.connectionRequiredModalButton2,
          requiredDatasource: res.requiredDatasource,

          changeNickname: res.changeNickname,
          editNicknameTitle: res.editNicknameTitle,
          editNicknameDesc: res.editNicknameDesc,
          changeNicknameValues: res.changeNicknameValues,
          expiredAccountWithNewCredModalOpen: res.expiredAccountWithNewCredModalOpen,

          connectedAccounts,
          datasourcesList,
        })
      }).catch((e) => {
        if (e.status && e.status !== 401) {
          this.setState({
            isLoading: false,
            hasError: true,
            errorTitle: i18n.t("profile__connection_required_error_title"),
            errorMessage: i18n.t("profile__connection_required_error_body"),
            errorButtonText: i18n.t("profile__connection_required_error_button"),
            errorModalButtonAction: null,
            isOpenConnectionRequiredModal: false,
          })
        }
      })
    }
  }

  // =========================
  // VC Offers on Load Logics
  // =========================

  componentWillUnmount = () => {
    clearInterval(this.VCOffersPolling);
    clearInterval(this.VCProofRequestsPolling);
  }

  FetchVCOffersList = () => {
    const { credentialOffersList } = this.state;
    if (!credentialOffersList?.length) {
    
      return ProfileService.getCredentialOffers("PENDING").then(offers => {

        if (offers.length) {
          clearInterval(this.VCOffersPolling);
        }
  
        this.setState({ credentialOffersList: offers })
      }).catch(e => {
        clearInterval(this.VCOffersPolling);
  
        // Todo do something for error to retrieve the offerslist
      })
    }
  }

  FetchVCProofRequestList = () => {
    const { proofRequestsList } = this.state;
    if (!proofRequestsList?.length) {
      return ProofRequestsService.getPendingProofRequests("PENDING").then(proofRequests => {

        if (proofRequests.length) {
          clearInterval(this.VCProofRequestsPolling);
        }
  
        this.setState({ proofRequestsList: proofRequests })
      }).catch(e => {
        clearInterval(this.VCProofRequestsPolling);
  
        // Todo do something for error to retrieve the offerslist
      })
    }
  }

  // ========================================
  // Required Connection Modal Actions  Start 
  // ========================================

  handleChecksForErrorsAndSuccessLogins = (connectedAccountsHistory, DataObj) => {
    let { 
      lookup_code, 
      walletAccountName,
      hasError,
      errorTitle,
      errorMessage,
      errorModalButtonAction,
      requiredDatasource
    } = this.state;

    const {
      dataSource,
      isLoginFailedError,
      VCConnectionError,
      ProofRequestError
    } = this.props;
    
    let selectedDS = DatasourceService.getSelectedDs();
    
    handleChecksForErrors(DataObj, isLoginFailedError, VCConnectionError, lookup_code, ProofRequestError, selectedDS, walletAccountName, this.handleAnalyticsTrack, this.handleToggleErrorModal);


    // 3. If No errors
    
    if (!DataObj.hasError) {
      // 3.1 Checks for required DS
  
      DataObj = this.handleRequiredConnectionCheck(DataObj.datasourcesList, DataObj.connectedAccounts, requiredDatasource, DataObj.getRelationsByID);
      
      // 3.2 Checks for login credentials
  
      if (dataSource) {
  
        // Analytics
        const analyticText = dataSource.selected_connection_type === "RENEW_CONNECTION" ? "Expired required data source modal - Renew" : "Required data source modal - Log in";
        this.handleAnalyticsTrack("onClick_event", analyticText);

        DataObj = this.handleCheckLoginCredentials(connectedAccountsHistory, selectedDS, DataObj, hasError, errorTitle, errorMessage, errorModalButtonAction);
      }
    }

    return DataObj;
  }

  handleRequiredConnectionCheck = (datasources, connectedAccounts, requiredDatasource, getRelationsByID) => {
    let { 
      isOpenConnectionRequiredModal, 
      connectionRequiredModalTitle,
      connectionRequiredModalBody,
      connectionRequiredModalButton1,
      connectionRequiredModalButton2, 
      isExpiredConnectionRequired,
    } = this.state;

    let result = {
      isOpenConnectionRequiredModal, 
      connectionRequiredModalTitle,
      connectionRequiredModalBody,
      connectionRequiredModalButton1,
      connectionRequiredModalButton2, 
      isExpiredConnectionRequired,
      requiredDatasource,
      connectedAccounts,
      abortLoops: false,
      getRelationsByID
    };

    // Filters, sorts, and loops through all required connections.
    SortedRequiredDataSources(datasources, (filteredDataSource) => {
      const foundConnectedAccount = connectedAccounts.filter(connectedAcc => filteredDataSource.data_source_id === connectedAcc.data_source.data_source_id);
      // 1.1 if Required Ds is connected but expired
      this.handleCheckCaseForConnectedRequiredDSExpiry(foundConnectedAccount, requiredDatasource, result);
      
      // 1.2 if Required DS is not connected
      // Check if the Datsource is there in connected account arr
      if(!isDataSourceConnectedToAccounts(filteredDataSource, connectedAccounts)){
        this.handleCheckCaseForNotConnectedRequiredDS(filteredDataSource, requiredDatasource, result);
      }
    })

    return result;
  }

  handleCheckCaseForConnectedRequiredDSExpiry = (foundConnectedAccount, requiredDatasource, DataObj) => {
    if(DataObj.abortLoops) return;

    if (foundConnectedAccount.length && !foundConnectedAccount.find(foundAcc => foundAcc.expired === false)) {
      
      // Modal Content
      DataObj.isOpenConnectionRequiredModal = true;
      DataObj.isExpiredConnectionRequired = true;
      DataObj.connectionRequiredModalTitle = i18n.t("profile__expired_connection_required_modal_title", { dsName: foundConnectedAccount[0].data_source.name });
      DataObj.connectionRequiredModalBody = i18n.t("profile__expired_connection_required_modal_body", { dsName: foundConnectedAccount[0].data_source.name });
      DataObj.connectionRequiredModalButton1 = i18n.t("profile__expired_connection_required_modal_button1");
      DataObj.connectionRequiredModalButton2 =  i18n.t("profile__expired_connection_required_modal_button2");
      DataObj.requiredDatasource = ((Object.keys(requiredDatasource).length === 0) || requiredDatasource.error) ? foundConnectedAccount[0] : requiredDatasource;
      DataObj.abortLoops = true;
    } 

    return DataObj;
  }

  handleCheckCaseForNotConnectedRequiredDS = (currentDataSource, requiredDatasource, DataObj) => {
    if(DataObj.abortLoops) return;

    // Modal Content
    DataObj.isOpenConnectionRequiredModal = true;

    const prefix = "profile__new_connection_required_modal";

    const connectionRequiredModalTitle = CustomLocalizationText(
      prefix,
      currentDataSource.data_source_id,
      "_title",
      {
        dsName: currentDataSource.name,
      }
    );
    const connectionRequiredModalBody = CustomLocalizationText(
      prefix,
      currentDataSource.data_source_id,
      "_body",
      {
        dsName: currentDataSource.name,
      }
    );
    const connectionRequiredModalButton2 = CustomLocalizationText(
      prefix,
      currentDataSource.data_source_id,
      "_button"
    );
    

    DataObj.connectionRequiredModalTitle = connectionRequiredModalTitle;
    DataObj.connectionRequiredModalBody = connectionRequiredModalBody;
    DataObj.connectionRequiredModalButton1 = i18n.t("profile__new_connection_required_modal_button_logout");
    DataObj.connectionRequiredModalButton2 = connectionRequiredModalButton2;
    DataObj.requiredDatasource.data_source = ((Object.keys(requiredDatasource).length === 0) || requiredDatasource.error) ? currentDataSource : requiredDatasource.data_source;
    DataObj.abortLoops = true;
    
  }


  handleCheckLoginCredentials = (connectedAccountsHistory, selectedDS, requiredConnectionState, hasError, errorTitle, errorMessage, errorModalButtonAction) => {
    let { 
      requiredDatasource,
      changeNickname,
      expiredAccountWithNewCredModalOpen,
      changeNicknameValues
    } = this.state;

    let result = {
      changeNickname,
      editNicknameTitle: i18n.t("find_connection_edit_nickname_modal__title", { dsName: requiredConnectionState?.requiredDatasource?.data_source?.name }),
      editNicknameDesc: i18n.t("edit_nickname_modal__new_connection_desc"),
      changeNicknameValues,
      expiredAccountWithNewCredModalOpen,
      hasError, 
      errorTitle, 
      errorMessage,
      errorModalButtonAction,
      isOpenConnectionRequiredModal: requiredConnectionState.isOpenConnectionRequiredModal,
      isExpiredConnectionRequired: requiredConnectionState.isExpiredConnectionRequired,
      connectionRequiredModalTitle: requiredConnectionState.connectionRequiredModalTitle,
      connectionRequiredModalBody: requiredConnectionState.connectionRequiredModalBody,
      connectionRequiredModalButton1: requiredConnectionState.connectionRequiredModalButton1,
      connectionRequiredModalButton2: requiredConnectionState.connectionRequiredModalButton2,
      requiredDatasource: requiredConnectionState.requiredDatasource,
    };
    
    if (requiredDatasource && requiredDatasource.ds_account_id) {
     
      // 0 Check for delegated accounts
      
      const delegatedAccounts = delegatedAccountsCheck(requiredConnectionState.connectedAccounts, requiredDatasource, requiredConnectionState.getRelationsByID);
      
      result.requiredDatasource.delegatedAccounts = delegatedAccounts;

      // 0.1 Add default nickname for the connected account

      result.changeNicknameValues = { 
        [requiredDatasource.ds_account_id]: { nickname: requiredDatasource.nickname } 
      };

      // 0.2 Add default nickname for delegated accounts

      if (delegatedAccounts.length) {
        delegatedAccounts.forEach(delegatedAccount => {
          result.changeNicknameValues = { 
            ...result.changeNicknameValues,
            [delegatedAccount.ds_account_id]: { nickname: delegatedAccount.nickname }
          };
        })
      }

      // 1. Open Edit Nickname modal for a new connection
        result = this.handleOpenEditNicknameModalForNewConnection(requiredDatasource, result)

      if (connectedAccountsHistory && connectedAccountsHistory.length) {
      
        const foundConnectedAccount = connectedAccountsHistory.filter(account => (account.ds_account_id === requiredDatasource.ds_account_id));

        // 2. IF Same Credentials to renew
        if (requiredDatasource.ds_account_id === selectedDS.ds_account_id) {
          if (requiredDatasource.selected_connection_type === "RENEW_CONNECTION") {
            result.changeNickname = true;
            result.editNicknameTitle = i18n.t("edit_nickname_modal__renew_connection_title");
            result.editNicknameDesc = i18n.t("edit_nickname_modal__renew_connection_desc");
            result.isOpenConnectionRequiredModal = false;
            result.isExpiredConnectionRequired = false;
          } 
        } else {

          // 3. IF New Credentials to connect/renew Check Cases

          result = this.handleNewCredentialsToConnectRenewChecks(foundConnectedAccount, result);

        }
          
        // 4. IF New Credentials to Renew a connection

        result = this.handleNewCredentialsToRenewConnection(foundConnectedAccount, requiredDatasource, result);
      }    
    }

    return result;
  }

  // ==============================================
  // handleCheckLoginCredentials Secondary Methods
  // ==============================================

  handleOpenEditNicknameModalForNewConnection = (requiredDatasource, DataObj) => {

    if (requiredDatasource.selected_connection_type !== "RENEW_CONNECTION" && !requiredDatasource.expired) {

      DataObj.changeNickname = true;
      DataObj.isOpenConnectionRequiredModal = false;
      DataObj.isExpiredConnectionRequired = false;
    }

    return DataObj;
  }

  handleNewCredentialsToRenewConnection = (foundConnectedAccount, requiredDatasource, DataObj) => {

    if (!foundConnectedAccount.length && requiredDatasource.selected_connection_type === "RENEW_CONNECTION") {
      DataObj.expiredAccountWithNewCredModalOpen = true;
      DataObj.changeNickname = false;
      DataObj.isOpenConnectionRequiredModal = false;
      DataObj.isExpiredConnectionRequired = false;
    }

    return DataObj;
  }

  handleNewCredentialsToConnectRenewChecks = (foundConnectedAccount, DataObj) => {

    // 3.1 IF New Credentials to connect/renew but those cred already used in another which is connected
    
    if (foundConnectedAccount.length) {
      if (!foundConnectedAccount[0].expired && !foundConnectedAccount[0].disabled) {
        DataObj.hasError = true;
        DataObj.errorTitle = i18n.t("connect_rs__failure_already_connected_title");
        DataObj.errorMessage = i18n.t("connect_rs__failure_already_connected_desc");
        DataObj.errorModalButtonAction = this.handleToggleErrorModal;
        DataObj.changeNickname = false;
        DataObj.isOpenConnectionRequiredModal = false;
        DataObj.isExpiredConnectionRequired = false;
      } else {

        // 3.2. IF New Credentials to connect/renew but those cred already used in another which is not connected

        DataObj.expiredAccountWithNewCredModalOpen = true;
        DataObj.changeNickname = false;
        DataObj.isOpenConnectionRequiredModal = false;
        DataObj.isExpiredConnectionRequired = false;
      }
    } 

    return DataObj;
  }

  // =================================================
  // handleCheckLoginCredentials Secondary Methods END
  // ==================================================


  handlelogoutAction = async (e) => {
    e.preventDefault();

    await AuthService.logout();

    this.handleAnalyticsTrack("onClick_event", `Expired required data source modal - Do it later`);
  }

  handleConnectRsAction = async (connectionType) => {
    const { requiredDatasource, connectedAccounts } = this.state;
    
    try {
      // Spinner
      this.setState({
        isSendingReq: true
      });

      // Api Call

      const result = await DatasourceService.requestConnection(requiredDatasource.data_source.data_source_id, "PROFILE_PAGE", connectedAccounts, requiredDatasource, connectionType);
      
      document.location = result.auth_request;
    } catch (e) {
      if (e.status && e.status !== 401) {
        this.setState({ 
          isSendingReq: false,
          hasError: true,
          errorTitle: i18n.t("findConnections_connection_failure_title"),
          errorMessage: i18n.t("findConnections_connection_failure_desc"),
        })
      }
    }
  } 

  // Onchange for nickname

  handleOnNicknameChange = (e) => {
    const { changeNicknameValues } = this.state;
    const { name, value } = e.target; 

    this.setState({ changeNicknameValues: { ...changeNicknameValues, [name]: { "nickname": value } } })
  }

  handleEditNickNameAction = async (nicknameValues) => {
    let { 
      requiredDatasource, 
      datasourcesList, 
      connectedAccounts, 
    } = this.state;

    let DataObj = {
      connectedAccounts,
      hasError: false,
      changeNickname: false,
      errorTitle: "",
      errorMessage: "",
      nicknameValues,
    }
    
    let res = {
      requiredDatasource
    }

    // Remove Browser History
    this.handleRemoveHistory();
    
    // Clearing browser state 
    window.history.replaceState("state", null);

    // Spinner
    this.setState({ isSendingReq: true });

    let promisesArr = [];
    
    for (let connectedAccount of connectedAccounts) { 
      for (const key in nicknameValues) {
        if ((connectedAccount.ds_account_id === key) && (connectedAccount.nickname !== nicknameValues[key].nickname)) {
          promisesArr.push(
            new Promise((resolve, reject) => {
              DatasourceService.editNickname(key, nicknameValues[key].nickname).then((result) => {
                resolve(result);
              })
              .catch((e) => {
                e.ds_account_id = key;
                reject(e);
              });
            })
          );
        }
      }
    }

    // Get the result for all the nickname methods
    if (promisesArr.length) {

      await PromiseAllsettled(promisesArr).then(allResults=> {
        
        // 1. Update the Nickname

        DataObj = this.handleUpdateNicknameForList(DataObj, allResults);
  
        // 2. Filter If we have a error except 409
        const except409ErrorFilter = allResults.filter(item => ((item.status === "rejected") && (item.reason.status !== 409)));
        
        if (except409ErrorFilter.length) {
          DataObj.changeNickname = false;
          DataObj.hasError = true;
          DataObj.errorTitle = i18n.t("edit_nickname_modal__failure_nickname_update_title");
          DataObj.errorMessage = i18n.t("edit_nickname_modal__failure_nickname_update_desc");
        }
      });
    } else {
      DataObj.requiredDatasource = {};
    }

    // Checks for required DS
    if (!DataObj.changeNickname) {
      res = this.handleRequiredConnectionCheck(datasourcesList, DataObj.connectedAccounts, requiredDatasource);
    }

    this.setState({
      connectedAccounts: DataObj.connectedAccounts,
      changeNickname: DataObj.changeNickname,
      changeNicknameValues: DataObj.nicknameValues,
      isSendingReq: false,
      hasError: DataObj.hasError,
      errorTitle: DataObj.errorMessage,
      errorMessage: DataObj.errorMessage,

      isOpenConnectionRequiredModal: res.isOpenConnectionRequiredModal,
      isExpiredConnectionRequired: res.isExpiredConnectionRequired,
      connectionRequiredModalTitle: res.connectionRequiredModalTitle,
      connectionRequiredModalBody: res.connectionRequiredModalBody,
      connectionRequiredModalButton1: res.connectionRequiredModalButton1,
      connectionRequiredModalButton2: res.connectionRequiredModalButton2,
      requiredDatasource: res.requiredDatasource,
    })
  }

  //===========================================
  // Secondary functions for editnickname Start
  //===========================================

  handleUpdateNicknameForList = (DataObj, allResults) => {
    DataObj.connectedAccounts.map((connectedAccount) => {
      for (let resultItem of allResults) { 

        // For Fulfiled succesful calls
        if (resultItem.status === "fulfilled" && connectedAccount.ds_account_id === resultItem.value.ds_account_id) {

          //Analytics
          this.handleAnalyticsTrack("onClick_event", `Submit edit nickname with ${resultItem.value.nickname} for ${resultItem.value.ds_account_id}`);

          connectedAccount.nickname = resultItem.value.nickname;
        }

        // For Failed 409 error calls

        if (resultItem.status === "rejected") {
          DataObj.hasError = false;
          DataObj.changeNickname = true;

          if (resultItem.reason.status === 409) {
            DataObj.nicknameValues[resultItem.reason.ds_account_id].error = i18n.t("edit_nickname_modal__failure_nickname_409_title");
          } 
        }
      }
      
      return connectedAccount;
    });

    return DataObj;
  }

  //===========================================
  // Secondary functions for editnickname End
  //===========================================

  handleRemoveHistory = () => {
    DatasourceService.removeConnectedAccountsHistory();
  }

  handleDisconnectExpiredAccountWithNewCredModal = async () => {
    let { requiredDatasource, datasourcesList, connectedAccounts } = this.state;

    // Remove Browser History
    this.handleRemoveHistory();

    try {
      const result = await DatasourceService.disableMyDatasourceAccount(requiredDatasource.ds_account_id);

      // Clearing browser state 
      window.history.replaceState("state", null);
 
      // Update the local state
      connectedAccounts = connectedAccounts.filter(connectedAccount => connectedAccount.ds_account_id !== result.ds_account_id);
   
      // Checks for required DS
      const res = this.handleRequiredConnectionCheck(datasourcesList, connectedAccounts, requiredDatasource);

      this.setState({
        expiredAccountWithNewCredModalOpen: false,
        isOpenConnectionRequiredModal: res.isOpenConnectionRequiredModal,
        isExpiredConnectionRequired: res.isExpiredConnectionRequired,
        connectionRequiredModalTitle: res.connectionRequiredModalTitle,
        connectionRequiredModalBody: res.connectionRequiredModalBody,
        connectionRequiredModalButton1: res.connectionRequiredModalButton1,
        connectionRequiredModalButton2: res.connectionRequiredModalButton2,
        requiredDatasource: res.requiredDatasource,
        connectedAccounts
      });
    } catch (error) {

      if (error.status !== 401) {
        this.setState({
          hasError: true,
          errorTitle: i18n.t("connectionDetail__failure_disconnect_title"),
          errorMessage: i18n.t("connectionDetail__failure_disconnect_desc"),
          isSendingReq: false,
          expiredAccountWithNewCredModalOpen: false,
        })
      }
    }  
  }

  handleCloseExpiredAccountWithNewCredModal = () => {
    this.setState({
      changeNickname: true,
      expiredAccountWithNewCredModalOpen: false,
    })
  }


  // ========================================
  // END 
  // ========================================

  renderCardProfileName = () => {

    const { isLoadingProfile, profile, profile_configs } = this.state;

    if (profile_configs && profile_configs.name) {
      return (
        <div className="profile-user-name">
          { 
            !profile.name && !isLoadingProfile
            ?
            <span>-</span>
            : 
            profile.name
          }
        </div>
      )
    }
  }

  renderNotifications = (notifications, numberOfNotificationToShow) => {
    let renderNotification = notifications;

    // Only render first two

    if (notifications.length > numberOfNotificationToShow) {
      renderNotification = notifications.slice(0, numberOfNotificationToShow);
    }

    return renderNotification.map(notification => (
      <NotificationItem 
        key={notification.id}
        notification={notification}
        handleNotificationClick={this.handleNotificationClick}
      />
    ))
  }

  handleNotificationClick = (notificationItem) => {
    console.log("notificationItem:", notificationItem)
  }

  handleBubbleClick = () => {
    console.log("HIt Bubble")
  }

  handleToggleErrorModal = () => {
    const { datasourcesList, connectedAccounts } = this.state;

    // Clearing browser state 
    window.history.replaceState("state", null);

    // Checks for required DS
    const res = this.handleRequiredConnectionCheck(datasourcesList, connectedAccounts, {});

    this.setState({ 
      lookup_code: "", 
      hasError: false,

      isOpenConnectionRequiredModal: res.isOpenConnectionRequiredModal,
      isExpiredConnectionRequired: res.isExpiredConnectionRequired,
      connectionRequiredModalTitle: res.connectionRequiredModalTitle,
      connectionRequiredModalBody: res.connectionRequiredModalBody,
      connectionRequiredModalButton1: res.connectionRequiredModalButton1,
      connectionRequiredModalButton2: res.connectionRequiredModalButton2,
      requiredDatasource: res.requiredDatasource,
    })
  }

  // Tracking

  handleAnalyticsTrack = (type, action, reason) => {
    AnalyticsService.track(type, {
      context: 'Wallet',
      page: "Profile Page",
      actions: action,
      url: window.location.hostname + window.location.pathname,
      reason
    })
  }

  // Add Connection Redirect

  handleFindConnections = () => {
    // Analytics
    this.handleAnalyticsTrack("onClick_event", `Add Connection button`);

    this.props.history.push({ 
      pathname: window.GLOBAL_PATH + "my-account/find-connections",
      state: {
        previousPage: "PROFILE_PAGE"
      }
    })
  }

  // Toggle VC Connection add modal

  handleVCConnectionAddedModalToggle = () => {
    const { VCConnectionDetails } = this.props;
    const { VCConnectionAddedModalToggle } = this.state;

    // Clearing browser state 
    window.history.replaceState("state", null);

    // Analytics
    this.handleAnalyticsTrack("onClick_event", `${i18n.t("profile__vc_added_modal_title")} - ${i18n.t("profile__vc_added_modal_description")}`, VCConnectionDetails);
    
    this.setState({ VCConnectionAddedModalToggle: !VCConnectionAddedModalToggle })
  }

  // Credential Notification Bar Action Button

  handleCredentialOfferNotificationAction = (selectedOffer) => {
    this.setState({
      selectedOffer,
      credentialOfferModalToggle: true
    })
  }

  // Credential Offer Modal Actions

  handleToggleCredentialOfferModal = () => {
    const { credentialOfferModalToggle } = this.state;

    this.setState({ credentialOfferModalToggle: !credentialOfferModalToggle })
  }

  handleCredentialOfferButtonAction = async (accepted) => {
    let { 
      selectedOffer, 
      credentialOffersList, 
      isSendingReq, 
      credentialOfferModalToggle 
    } = this.state;

    this.setState({ isSendingReq: true });
    
    const filterOffers = credentialOffersList.filter(item => item.credential_offer_id !== selectedOffer.credential_offer_id);

    try {
      await ProfileService.acceptCredentialOffer(selectedOffer.credential_offer_id, accepted);

      // Redirect to connection details page after acceptance
      if (accepted) {
        this.handleAfterOfferAcceptanceRedirectToDetailsPage(selectedOffer);
        return;
      }

      this.setState({
        credentialOfferModalToggle: false,
        declineModalToggle: false,
        credentialOffersList: filterOffers,
        isSendingReq: false
      });
    } catch (error) {

      isSendingReq = false;
      credentialOfferModalToggle = false;
      const hasError = true;
      let errorTitle;
      let errorMessage;
      let errorModalButtonAction;

      // Generic ErrorModal

      if (error.status !== 401) {
        errorTitle = i18n.t("profile__connection_required_error_title");
        errorMessage = i18n.t("profile__connection_required_error_body");
        errorModalButtonAction = this.handleToggleErrorModal;
      }

      // Expired Offer ErrorModal

      if (error.lookup_code === "70000") {
        errorTitle = i18n.t("profile__credential_offer_details__cred_offer_expired_title");
        errorMessage = i18n.t("profile__credential_offer_details__cred_offer_expired_description");
        credentialOffersList = filterOffers;
      }

      this.setState({
        hasError,
        errorTitle,
        errorMessage,
        errorModalButtonAction,
        isSendingReq,
        credentialOfferModalToggle,
        credentialOffersList,
        declineModalToggle: false
      })
    }  
  }

  handleAfterOfferAcceptanceRedirectToDetailsPage = (selectedOffer) => {
    const { history } = this.props;

    // Just to detect on connection Details page from where use has come from
    selectedOffer.prevRoute = "PROFILE_PAGE";

    history.push({ 
      pathname: window.GLOBAL_PATH + `my-activity/connections/${selectedOffer.vc_stakeholder.name}/${selectedOffer.vc_stakeholder.vc_stakeholder_id}`,
      state: {
        data: selectedOffer
      }
    })
  }

  handleCredentialOfferDeclineBtnClick = () => {
    this.setState({ credentialOfferModalToggle: false, declineModalToggle: true })
  }

  handleCredentialOfferToggleDeclineModal = () => {
    const { declineModalToggle } = this.state;

    this.setState({ declineModalToggle: !declineModalToggle, credentialOfferModalToggle: declineModalToggle  })
  }

  // Proof Requests Actions
  handleProofRequestNotificationAction = (proofRequest) => {
    const { history } = this.props;
    
    history.push(window.GLOBAL_PATH + `proof-request/${proofRequest.id}`);
  }

  render() {
    const {
      profile,
      // isSecure,
      notifications,
      hasError,
      errorTitle,
      errorMessage,
      errorButtonText,
      errorModalButtonAction,
      profile_configs,
      isLoadingProfile,
      isSendingReq,

      isOpenConnectionRequiredModal,
      isExpiredConnectionRequired,
      connectionRequiredModalTitle,
      connectionRequiredModalBody,
      connectionRequiredModalButton1,
      connectionRequiredModalButton2,
      requiredDatasource,

      changeNickname,
      editNicknameTitle,
      editNicknameDesc,
      changeNicknameValues,
      expiredAccountWithNewCredModalOpen,

      my_activity_configs,

      VCConnectionAddedModalToggle,
      credentialOffersList,
      credentialOfferModalToggle,
      selectedOffer,
      declineModalToggle,
      proofRequestsList
    } = this.state;
    
    const { isInternetDisconnected, handleToggleNetworkModal } = this.props;

    const logoImg = profile.logo_url ? profile.logo_url : PlaceholderLogoImg;
   
    return (
      <div className="profile-user">
        <h1>{i18n.t("sidebar__my_account")}</h1>

        {/* Add COnenction Button */}

        {
          my_activity_configs.multiple_accounts_enable
          ?
          <button className="addButton backgroundPrimaryColor" onClick={this.handleFindConnections}>{i18n.t("connections__add_button")}</button>
          :
          null
        }

        {/* Line Break */}

        <div className="breakLine" />

        {/* Profile User Box */}

        <div
          className="profile-user-wrapper"
          style={{ background: (profile_configs && profile_configs.gradient_color_1 && profile_configs.gradient_color_2)
            ?
            `linear-gradient(to bottom, ${profile_configs.gradient_color_1}, ${profile_configs.gradient_color_2} 92%)`
            :
            `var(--primary-color)`}} 
        >
          <div className="logo-section">
            {
              isLoadingProfile
              ?
              <div className="placeholder-div" />
              :
              <img
                src={logoImg}
                alt={profile.idp_name + " logo"}
                className="identity-verifier-logo"
                height="42"
                width="42" 
              />
            }
          </div>

          {
            this.renderCardProfileName()
          }
          
          <div className="profile-user-verified">
            {
              profile.verified_sources.length
              ?
              <div className="profile-user-verified-by" key={profile.verified_sources[0].name}>
                <p className="profile-user-titles">
                  {i18n.t("profile__verified_by")}
                </p>
                <div className="profile-user-dynamic-info">
                  <img
                    src={profile.verified_sources[0].logo_url}
                    alt={profile.verified_sources[0].name + " logo"}
                    className="identity-verifier-logo"
                  />
                </div> 
              </div>
              :
              null
            }

            {/* <div className="profile-user-add-verifier-wrapper">
              <AddCircleOutlineRoundedIcon className="verifier-symbol textPrimaryColor" />
              <a href="/profile" className="brand-link textPrimaryColor borderPrimaryColor">{i18n.t('profile__add_verifier')}</a>
            </div> */}
          </div>
        </div>

         {/* VC Notification Bar */}

         {
          credentialOffersList?.length
          ?
          credentialOffersList.map(offer => (
              <CredentialNotification 
                key={offer.credential_offer_id}
                description={i18n.t("profile__credential_offer_notifcation_bar", { issuerName: offer.vc_stakeholder?.name })}
                handleOnClickAction={() => this.handleCredentialOfferNotificationAction(offer)}
                issuerName={offer.vc_stakeholder?.name}
              />
            )
          )
          :
          null
        }

        {/* Proof Request Notification Bar */}

        {
          proofRequestsList?.length
          ?
          proofRequestsList.map(proofRequest => (
              <CredentialNotification
                key={proofRequest.id}
                issuerName={proofRequest.vc_stakeholder?.name}
                description={i18n.t("profile__proof_request_notifcation_bar", { spName: proofRequest.vc_stakeholder?.name })}
                handleOnClickAction={() => this.handleProofRequestNotificationAction(proofRequest)}
              />
            )
          )
          :
          null
        }

        {/* Profile Description */}

        {
          (profile_configs && profile_configs.description)
          &&
          <div className="profile-desc" dangerouslySetInnerHTML={{ __html: i18n.t('profile__description') }} />
        }

        {/* Security Bubble */}

        {/* <Bubble handleBubbleClick={this.handleBubbleClick} isSecure={isSecure} /> */}

        {/* Notifications */}
        {
          (profile_configs && profile_configs.notifications)
          &&
          <div className="notifications-wrapper">
            {
              notifications.length 
              ?
              <React.Fragment>
                <h2>{i18n.t("profile__notifications")}</h2>
                {
                  this.renderNotifications(notifications, profile_configs.numberOfNotificationToShow)
                }
              </React.Fragment>
              :
              <div className="empty-wrapper">
                <div className="title">{i18n.t("profile__notifications_title")}</div>
                <div className="body">{i18n.t("profile__notifications_body")}</div>
              </div>
            }

            {
              notifications.length > profile_configs.numberOfNotificationToShow
              &&
              <button className="notification-history textPrimaryColor">{i18n.t("profile__notifications_history")}</button>
            }

          </div>
        }
        
        {
          hasError
          &&
          <ErrorModal 
            isOpen={hasError}
            errorTitle={errorTitle}
            errorMessage={errorMessage} 
            errorButtonText={errorButtonText} 
            handleToggleModal={errorModalButtonAction}
          />
        }

        {
          isOpenConnectionRequiredModal 
          &&
          <ConnectionRequiredModal 
            isOpen={isOpenConnectionRequiredModal}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            isSendingReq={isSendingReq}
            modalTitle={connectionRequiredModalTitle}
            modalBody={connectionRequiredModalBody}
            modalButton1={connectionRequiredModalButton1}
            modalButton2={connectionRequiredModalButton2}
            handlelogoutAction={this.handlelogoutAction}
            handleConnectRsAction={this.handleConnectRsAction}
            isExpiredConnectionRequired={isExpiredConnectionRequired}
          />
        }

        {
          window.config.nickname_screen
          &&
          changeNickname
          &&
          <EditNicknameModalConsent
            isOpen={changeNickname}
            handleEditNicknameAction={this.handleEditNickNameAction}
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            title={editNicknameTitle}
            description={editNicknameDesc}
            nicknameValues={changeNicknameValues}
            handleOnChange={this.handleOnNicknameChange}
            dsName={requiredDatasource.data_source.name}
            logo_url={requiredDatasource.data_source.style.logo_url}
            dsAccount={requiredDatasource}
          />
        }

        {
          expiredAccountWithNewCredModalOpen
          && 
          <NewAccountConnectionModal
            isOpen={expiredAccountWithNewCredModalOpen}
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            dataSource={requiredDatasource}
            handleCloseExpiredAccountWithNewCredModal={this.handleCloseExpiredAccountWithNewCredModal}
            handleDisconnectExpiredAccountWithNewCredModal={this.handleDisconnectExpiredAccountWithNewCredModal}
          />
        }

        {       
          VCConnectionAddedModalToggle
          &&
          <IconSuccessModal
            isOpen={VCConnectionAddedModalToggle} 
            showIcon={false}
            titleText={i18n.t("profile__vc_added_modal_title")}
            bodyText={i18n.t("profile__vc_added_modal_description")}
            buttonText={i18n.t("profile__vc_added_modal_btn")} 
            handleButtonAction={this.handleVCConnectionAddedModalToggle} 
          />
        }

        { 
          credentialOfferModalToggle
          &&
          <CredentialOfferModal
            isOpen={credentialOfferModalToggle}
            selectedOffer={selectedOffer}
            handleAcceptOfferAction={this.handleCredentialOfferButtonAction}
            handleDeclineOfferAction={this.handleCredentialOfferDeclineBtnClick}
            handleToggleCredentialOfferModal={this.handleToggleCredentialOfferModal}
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
          />
        }

        {
          declineModalToggle
          &&
          <DeclineModal
            isOpen={declineModalToggle}
            handleToggleDeclineModal={this.handleCredentialOfferToggleDeclineModal}
            handleDeclineAction={() => this.handleCredentialOfferButtonAction(false)}
            isDeclineRequest={isSendingReq}
            title={i18n.t("profile__credential_offer_details__decline_modal_title")}
            description={i18n.t('profile__credential_offer_details__decline_modal_description')}
          />
        }
      </div>
    );
  }
}

export default withRouter(ProfileContainer);
