import i18n from "../../../i18n";

// Services
import DatasourceService from "../../Services/DatasourceService";


// Get VC for Stakeholder
export const handleFetchStakeholdersForConnection = (VCConnectionsList) => {
  return new Promise(async (resolve) => { 
    const stakeHoldersList = await DatasourceService.getStakeholdersList();

      VCConnectionsList.forEach(vcConnection => {

        // Filter with stakeholder_id
        const filteredStakeHolder = stakeHoldersList?.length && stakeHoldersList.filter(stakeholder => vcConnection.vc_stakeholder_id === stakeholder.vc_stakeholder_id)

        if (filteredStakeHolder?.length) {
          vcConnection.vc_stakeholder = filteredStakeHolder[0];
        }
    })

    resolve(VCConnectionsList);
  })
}


// Get Verifiable Credentials
export const handleCheckVerifiableCredentialRevocationStatus = (VCConnectionsList) => {
  return new Promise(async (resolve) => { 

    // Need this for loop coz otherwise foreach/map or other loops won't wait for await call to finish
    for (let i = 0; i < VCConnectionsList.length; i++) { 
      // If there are credentials available 
      // Check for revocation status

      if (VCConnectionsList[i].credentials?.length) {

        // Sort by received at time of the  credential
        VCConnectionsList[i].credentials.sort((a, b) => new Date(b.received_at) - new Date(a.received_at));

        await Promise.resolve(handleCheckRevocationForEachCredential(VCConnectionsList[i])); 
      }
    }
   
    resolve(VCConnectionsList);
  })
}

export const handleCheckRevocationForEachCredential = (vcConnection) => {
  let tempRevoked = { revoked: false };

  return new Promise(async (resolve) => {

    // Need this for loop coz otherwise foreach/map or other loops won't wait for await call to finish
    for (let i = 0; i < vcConnection.credentials.length; i++) {
      try {
        tempRevoked = await DatasourceService.checkVerifiableCredentialRevocationStatus(vcConnection.credentials[i].identifier);

        vcConnection.credentials[i].revoked = tempRevoked.revoked;
      } catch (e) {
        console.log(e)
      }
    }

    // ==============================================
    // Just picking first credential for the MVP
    // ==============================================

    vcConnection.credentials = vcConnection.credentials.slice(0, 1);

    resolve(vcConnection);
  })
}

export const handleUpdateVCConnectionTags = (VCConnectionsList, result) => {
  return new Promise(async (resolve, reject) => {

    let orgType;
    let tempVcConnectionList = [];

    tempVcConnectionList = await Promise.resolve(handleFormatVCConnectionlistWithIssuerVerifier(VCConnectionsList));

    tempVcConnectionList.forEach(vcConnection => {
      
      // Add VC Connection to the table only when it has stakeholder
      if (vcConnection.vc_stakeholder) {
        // If isIssuer True 

        if (vcConnection.vc_stakeholder.issuer) {
          orgType = i18n.t("connectionDetail__informationSource");
        }

        // If IsVerifier True

        if (vcConnection.vc_stakeholder.verifier) {
          orgType = i18n.t("connectionDetail__serviceProvider");
        }

        vcConnection.orgType = orgType;

        result = [...result, vcConnection];
      }
    })

    resolve(result);
  })
}

// Format Issuer and Verifier
// THe case Would be if it is both we are splitting into two objects.
  
export const handleFormatVCConnectionlistWithIssuerVerifier = (VCConnectionsList) => {
  return new Promise((resolve) => {
    const issuer = [];
    const verifier = [];

    VCConnectionsList.forEach(item => {
      // If is only Issuer 

      if (item.vc_stakeholder.issuer === true && !item.vc_stakeholder.verifier) {
        issuer.push(item);
      }

      // If Is only Verifier

      if (item.vc_stakeholder.verifier === true && !item.vc_stakeholder.issuer) {
        verifier.push(item);
      }

      // If Issuer And Verifier Both

      if (item.vc_stakeholder.issuer === true && item.vc_stakeholder.verifier === true) {
        let tempIsIssuerItem = JSON.parse(JSON.stringify(item));
        let tempIsVerifierItem = JSON.parse(JSON.stringify(item));
        
        tempIsIssuerItem.vc_stakeholder.verifier = false;
        tempIsIssuerItem.vc_stakeholder.issuer = true;
        issuer.push(tempIsIssuerItem)

        tempIsVerifierItem.vc_stakeholder.verifier = true;
        tempIsVerifierItem.vc_stakeholder.issuer = false;
        verifier.push(tempIsVerifierItem);
      }
    });
    
    const result = [...issuer, ...verifier];

    resolve(result);
  })
}