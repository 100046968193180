import React, { useState, useEffect } from "react";

// Components
import DeclineModal from "../../DeclineModal";
import ErrorModal from "../../ErrorModal";
import TOUandPP from "../../TOUandPP";
import ActionButtons from "../../ConsentPage/presentation/ActionButtons";
import ProofItem from "../presentation/ProofItem";
import Spinner from "../../Spinner";

// Services
import ProofRequestsService from "../../Services/ProoofRequestsService";
import DatasourceService from "../../Services/DatasourceService";

// Lib
import i18n from "../../../i18n";
import { Redirect } from "react-router-dom";
import AnalyticsService from "analytics-web";

// Utils
import PromiseAllsettled from "../../../utils/PromiseAllsettled";

const ProofRequestContainer = ({ isInternetDisconnected, handleToggleNetworkModal, proofID, history }) => {
  const [proofRequest, setProofRequest] = useState({});
  const [isSendingReq, setSendingReq] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState("");
  const [isOpenDeclineModal, setToggleDeclineModal] = useState(false);
  const [isDeclineRequest, setDeclineRequest] = useState(false);
  const [lookup_code, setLookupCode] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorTitle, seteErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorButtonText] = useState(i18n.t("ok"));
  const [isScopeModalOpen, toggleScopeModal] = useState(false);
  const [isLoadingCredential, setLoadingCredential] = useState(true);
  const [selectedCredential, setSelectedCredential] = useState({});

  useEffect(() => {
    let isMounted = true;

    //Analytics
    handleAnalyticsTrack("onLoad_event");

    const handleFetchProofRequest = () => {
      const promises = [];
  
      // 1. Api call to get the Proof Request
  
      promises.push(new Promise((resolve, reject) => {
        ProofRequestsService.getPendingProofRequestByID(proofID).then(result => {
          resolve(result);
        }).catch(e => {
          reject(e)
        })
      }))
  
      // 2. Api call to get the verifiable Credentials
  
      promises.push(new Promise((resolve, reject) => {
        ProofRequestsService.getValidVerifiableCredentials(proofID).then(verfiableCredential => {
          resolve(verfiableCredential);
        }).catch(e => {
          reject(e)
        })
      }))

      return PromiseAllsettled(promises).then(async (result) => {
        let proof_request = result[0];
        const valid_credentials = result[1];
       
        // 1. Successful Api calls Proof Request & Valid Credentials

        if (proof_request.status === 'fulfilled') {
          proof_request = proof_request.value;
        }

        if (valid_credentials.status === 'fulfilled') {
          proof_request.valid_credentials = valid_credentials.value;

          // 1.0 Sort by latest credentials using the received_at time
          
          proof_request.valid_credentials.sort((a, b) => new Date(b.received_at) - new Date(a.received_at));
          
          // 1.1 Check for Revocation Status

          proof_request = await Promise.resolve(handleCheckRevocationForEachCredential(proof_request));
          
          // 1.2 get the Valid Credentials
          // Check for State 
          // Check for revocation status

          await Promise.resolve(handleGetAutoPopulateValidCredential(proof_request));

          setLoadingCredential(false);
        }
        
        // 2. If Successful update the state
        if (isMounted) { 
          setProofRequest(proof_request);
          setLoading(false);

          // 2.1 If the request state is Abadoned/rejected/accepted Return back

          if (proof_request.state !== "PENDING") {
            setLookupCode(404);
            setRedirectTo(window.GLOBAL_PATH + 'profile');
          }
        }

        // 3. Error Cases IF Unable to load Proof request
        // 3.1 Redirect back to profile with error

        const except401ErrorFilterForProofApi = Boolean((proof_request.status === "rejected") && (proof_request.reason?.status !== 401))
              
        if (except401ErrorFilterForProofApi) {
          if (isMounted) { 
            setLookupCode(proof_request.reason.status);
            setRedirectTo(window.GLOBAL_PATH + 'profile');
          }
        }

        // 3. Error Cases IF Unable to load Credentials
        // 3.1 Redirect back to profile with error

        const except401ErrorFilterForCredentialApi = Boolean((valid_credentials.status === "rejected") && (valid_credentials.reason.status !== 401))
              
        if (except401ErrorFilterForCredentialApi) {
          if (isMounted) { 
            setLookupCode(valid_credentials.reason.data.lookup_code);
            setLoadingCredential(false);
          }
        }
      }) 
    }

    // fetch Proof Request & Credentials
    handleFetchProofRequest();

    return () => {
      isMounted = false;
    };
  }, [proofID]);

  // Check for Revocation Status

  const handleCheckRevocationForEachCredential = (proof_request) => {
    let tempRevoked = false;
  
    return new Promise((resolve) => { 
      proof_request.valid_credentials.forEach(async (credential) => {
        tempRevoked = await DatasourceService.checkVerifiableCredentialRevocationStatus(credential.identifier);
        
        credential.revoked = tempRevoked.revoked;
        resolve(proof_request);
      })
    })
  }

  // Autopopulate First Valid Credential

  const handleGetAutoPopulateValidCredential = (proof_request) => {
    return new Promise((resolve) => { 
      for (let valid_credential of proof_request.valid_credentials) {
        if (!valid_credential.revoked && (valid_credential.state === "READY")) { 
          setSelectedCredential(valid_credential);
          break; 
        }
      }

      resolve();
    })
  } 

  // ========================
  // Accept or Decline Action
  // ========================

  const pushPermissionsAction = async (is_decline_request) => {
    // 1. Add Loader to the buttons
    setSendingReq(!is_decline_request);
    setDeclineRequest(is_decline_request)

    // 2. Analytics
    handleAnalyticsTrack("onClick_event", is_decline_request ? `Declined request` : `Allow button`);

    let accepted = true;

    // 3. Decline Case 
    if (is_decline_request) {
      accepted = false;
    } 
    
    try {
      // 4. Check for credentials Revocation Status
      const isRevoked = await DatasourceService.checkVerifiableCredentialRevocationStatus(selectedCredential.identifier);

      // 4.1 If the selected credential is revoked throw error modal

      if (isRevoked.revoked) {
        setSendingReq(false);
        setHasError(true);
        seteErrorTitle(i18n.t("proofRequest_page__revoked_credential_error_title"));
        setErrorMessage(i18n.t("proofRequest_page__revoked_credential_error_description"));
        setErrorModalButtonAction(false);

        //  Analytics
        handleAnalyticsTrack("error", `${i18n.t("proofRequest_page__revoked_credential_error_title")} - ${i18n.t("proofRequest_page__revoked_credential_error_description")}`);

        return;
      }

      // 5. Api CAll
      
      const result = await ProofRequestsService.acceptProofRequest(proofID, accepted, selectedCredential.identifier);

      // 6. After Success Redirect
      
      if (result.status === 200) {
        if (accepted === true) {
          proofRequest.orgType = i18n.t("connectionDetail__serviceProvider");

          proofRequest.prevRoute = "CONSENT_PAGE";
  
          // Redirect to connection details
          history.push({
            pathname: window.GLOBAL_PATH+`my-activity/connections/${proofRequest.vc_stakeholder.name}/${proofRequest.vc_stakeholder.vc_stakeholder_id}`,
            state: {
              data: proofRequest
            }
          });
        } else {
          // Declined Request return back to profile page

          history.push(window.GLOBAL_PATH+'profile');
        }
      }
      
    } catch (e) {
      if (e.status && e.status !== 401) {

        //  Analytics
        handleAnalyticsTrack("error", "", e);

        setSendingReq(false);
        setHasError(true);
        seteErrorTitle(i18n.t("consent__sending_auth_error_title"));
        setErrorMessage(i18n.t("consent__sending_auth_error_desc"));
        setErrorModalButtonAction(false);
        setToggleDeclineModal(false);
        setSendingReq(false);
        setDeclineRequest(false);
      }
    }
  }

  // Toggle Decline Modal

  const handleToggleDeclineModal = () => {

    //Analytics
    if (isOpenDeclineModal) {
      handleAnalyticsTrack("onClick_event", `Cancel in Decline modal`, "");
    } else {
      handleAnalyticsTrack("onClick_event", `Decline button`, "");
    }

    setToggleDeclineModal(!isOpenDeclineModal);
  }

  // Toggle Error Modal

  const toggleErrorModal = (title, message) => {
    setHasError(!hasError);
    seteErrorTitle(title);
    setErrorMessage(message);
  }

  const [errorModalButtonAction, setErrorModalButtonAction] = useState(true);

  // Toggle Scope Modal
  const handleToggleScopeModal = () => {
    if (!isScopeModalOpen) {
      // Analytics
      handleAnalyticsTrack("onClick_event", `${proofRequest.name} - What is this button`, "");
    }
    
    toggleScopeModal(!isScopeModalOpen)
  };

  // Tracking

  const handleAnalyticsTrack = (type, action, reason) => {
    AnalyticsService.track(type, {
      context: 'Wallet',
      page: "Proof Request Page",
      actions: action,
      url: window.location.hostname + window.location.pathname,
      reason
    })
  }
  
  // For Error Scenarios Return Back to Profile Page
  // If there is a issue loading proof requests

  if (redirectTo) {
    return (
      <Redirect to=
        {{ 
          pathname: redirectTo, 
          state: { 
            ProofRequestError: true, 
            lookup_code
          }, 
        }} 
      />
    );
  }

  // Show Loader while page loads
  
  if (isLoading) { 
    return (
      <div className="redirect-router-spinner-wrapper proof-request-spinner">
        <Spinner />
      </div>
    )
  }
 
  return (
    <div className="wrapper">
      <div className="consent">
        <h1>
          {i18n.t("proofRequest_page__header", { spName: proofRequest.vc_stakeholder?.name })}
        </h1>

        {
          Object.keys(proofRequest).length !== 0
          &&
          <ProofItem 
            proofRequest={proofRequest} 
            isScopeModalOpen={isScopeModalOpen}
            handleToggleScopeModal={handleToggleScopeModal}
            isLoadingCredential={isLoadingCredential}
            toggleErrorModal={toggleErrorModal}
            selectedCredential={selectedCredential}
          />
        }

        {/* Actions Buttons */}
        
        <ActionButtons 
          readyToSubmit={Object.keys(selectedCredential).length !== 0}
          isInternetDisconnected={isInternetDisconnected}
          handleToggleNetworkModal={handleToggleNetworkModal}
          isSendingReq={isSendingReq}
          pushPermissionsAction={pushPermissionsAction}
          handleToggleDeclineModal={handleToggleDeclineModal}
        />

        {/* TOU and PP text */}
        
        <TOUandPP 
          clientName={proofRequest.vc_stakeholder?.name}
          tos_uri={proofRequest.vc_stakeholder?.tos_uri}
          policy_uri={proofRequest.vc_stakeholder?.policy_uri}
        />

        {/* MODALS  */}

        {
          isOpenDeclineModal
          &&
          <DeclineModal 
            isOpen={isOpenDeclineModal}
            handleToggleDeclineModal={handleToggleDeclineModal}
            handleDeclineAction={() => pushPermissionsAction(true)}
            isDeclineRequest={isDeclineRequest}
            title={i18n.t("consent__decline_title")}
            description={i18n.t('consent__decline_desc', { client: proofRequest.vc_stakeholder?.name })}
          />
        }

        {
          hasError
          &&
          <ErrorModal 
            isOpen={hasError}
            errorTitle={errorTitle}
            errorMessage={errorMessage}
            errorButtonText={errorButtonText}
            handleToggleModal={errorModalButtonAction ? toggleErrorModal : null}
          />
        }
      </div>
    </div>
  )
}

export default ProofRequestContainer;
