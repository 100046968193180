import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const DefaultRoute = ({
        ...rest
    }) => (
    <Route 
        {...rest} 
        render={ () => <Redirect to={{ pathname: window.GLOBAL_PATH }} />}   
    />       
);