import axios from "axios";
import { AuthService } from "./AuthService";

let TransactionAPI = {};

// !!!: better way to inject url base into request
var getEndpoint = function() {
  return window.config.url;
};

TransactionAPI.getDetailsUser = function(txid) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/tx/${txid}/user`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

TransactionAPI.getDetails = function (txid, device_access_token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/tx/${txid}`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: `Bearer ${device_access_token}`
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

TransactionAPI.postPermissions = function(txid, permissions) {
  return new Promise((resolve, reject) => {
    axios
      .post("/tx/" + txid + "/permissions", permissions, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader(),
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
    });
  };
  
  TransactionAPI.redirectAfterPermissionSubmit = function (transaction_id, accessToken, permission_code) {
 
    return new Promise((resolve, reject) => {
      axios
      .get(`/tx/${transaction_id}/redirect`, {
        baseURL: getEndpoint(),
        params: { permission_code },
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : AuthService.authorizationHeader(),
          excludeInterceptor: Boolean(accessToken),
        }
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error.response));
  });
};

TransactionAPI.deviceAuthorizaton = function (params) {
  
  return new Promise((resolve, reject) => {
    axios.post('/device/authorize', params, {
      baseURL: getEndpoint(),
      headers: {
        "X-DEVICE": "web",
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

TransactionAPI.deviceToken = function (params) {
  return new Promise((resolve, reject) => {
    axios.post('/device/token', params, {
        baseURL: getEndpoint()
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

TransactionAPI.getClientInfoByClientID = function (clienID) {
  return new Promise((resolve, reject) => {
    axios.get(`/registry/clients/${clienID}`, {
        baseURL: getEndpoint()
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

export default TransactionAPI;
