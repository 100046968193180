import axios from "axios";
import { AuthService } from "./AuthService";

let DatasourceAccountsAPI = {};

// !!!: better way to inject url base into request
var getEndpoint = function() {
  return window.config.url;
};

DatasourceAccountsAPI.getMyDatasourceAccounts = function() {
  return new Promise((resolve, reject) => {
    axios
      .get("/me/ds-accounts?include_resources=true&include_disabled=true", {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.editNickname = function (dsAccountID, nickname) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/me/ds-accounts/${dsAccountID}/nickname`, { nickname }, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.disableMyDatasourceAccount = function (dsAccountID) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/me/ds-accounts/${dsAccountID}/disable`, "",{
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.getOrganizationAccounts = function () {
  return new Promise((resolve, reject) => {
    axios
      .get("/me/organizations", {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.getOrganizationById = function (orgID) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/organizations/${orgID}`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.getPermissions = function () {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/permissions`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.disablePermission = function (permissionID) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/me/permissions/${permissionID}/disable`, "", {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};


DatasourceAccountsAPI.disableBulkPermissions = function (permissionsIdArray) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/me/permissions/disable`, permissionsIdArray, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.getPermissionById = function (permissionsId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/permissions/${permissionsId}`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.disableEnrolledClient = function (enrolledClientId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/me/enrolled_client/${enrolledClientId}/permissions/disable`, "", {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.disableDSPermissions = function (dsaIdentifier) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/me/ds-accounts/${dsaIdentifier}/permissions/disable`, "", {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader(),
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.getDatasourceByIDRelations = function(data_source_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/data-sources/${data_source_id}/relations`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.getVcConnectionsList = function() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/verifiable-credentials/connections`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.getVcConnectionsByID = function(cloudAgentConnectionId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/verifiable-credentials/connections/${cloudAgentConnectionId}`, {
        baseURL: getEndpoint(),
        params: { "expand-credentials": true },
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.getStakeholdersList = function() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/verifiable-credentials/stakeholders`, {
        baseURL: getEndpoint()
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.getVerifiableCredentialActivity = function(verifiableCredentialId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/verifiable-credentials/credentials/${verifiableCredentialId}/activity`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAccountsAPI.checkVerifiableCredentialRevocationStatus = function(verifiableCredentialId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/verifiable-credentials/credentials/${verifiableCredentialId}/revocation-status`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

export default DatasourceAccountsAPI;
