import React from "react";

// Components
import CustomTable from "../../CustomTable";

// Lib
import MediaQuery from 'react-responsive';

const DesktopVersion = ({ searchResults, queryString, isLoading, isInternetDisconnected, organizationsList, tableData, connectionHeaderFields, onSortClick, customSort, limit, handleOnSortClick }) => {
 
  return (
    <MediaQuery minDeviceWidth={751}>
      <CustomTable
        isSearchable={true}
        searchResults={searchResults}
        queryString={queryString}
        isLoading={isLoading}
        isInternetDisconnected={isInternetDisconnected}
        organizationsList={organizationsList}
        tableData={tableData}
        customSort={customSort}
        fields={connectionHeaderFields}
        paginationPerPage={limit}
        handleOnSortClick={handleOnSortClick}
        sortIcon={onSortClick ? <div className="sortIcon textPrimaryColor caretUp">&#9662;</div> : <div className="sortIcon textPrimaryColor">&#9662;</div>}
      />
    </MediaQuery>
  );
}

export default DesktopVersion;
