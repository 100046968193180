import React from "react";

// Components
import RsListItem from "./RsListItem";

const RsList = ({ rslist, request, acceptAccessToResource, connectedAccounts, datasources, isScopeModalOpen, scopeModalID, shouldDateSourceSelectorModalOpen, consent_configs, isInternetDisconnected, handleToggleNetworkModal, handleToggleScopeModal, handleToggleChange, onDataSourceSelect, closeConnectionModal, isLoadingConnection, openConnectionModal, handleDataSourceSelectorModalToggle }) => {

  return (
    <div className="steps-container">
      <ul className="rslist">

        {
          rslist.map((resource) => (
            <RsListItem 
              key={resource.resource_definition.res_def_id}
              resource={resource}
              request={request}
              acceptAccessToResource={acceptAccessToResource}
              connectedAccounts={connectedAccounts}
              datasources={datasources}
              isScopeModalOpen={isScopeModalOpen}
              scopeModalID={scopeModalID}
              shouldDateSourceSelectorModalOpen={shouldDateSourceSelectorModalOpen}
              consent_configs={consent_configs}
              isInternetDisconnected={isInternetDisconnected}
              handleToggleNetworkModal={handleToggleNetworkModal}
              handleToggleScopeModal={handleToggleScopeModal}
              handleToggleChange={handleToggleChange}
              onDataSourceSelect={onDataSourceSelect}
              openConnectionModal={openConnectionModal}
              closeConnectionModal={closeConnectionModal}
              isLoadingConnection={isLoadingConnection}
              handleDataSourceSelectorModalToggle={handleDataSourceSelectorModalToggle}
            />
          ))
        }

      </ul>              
    </div>
  );
}

export default RsList;