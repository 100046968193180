import React, { Component } from "react";

// Lib
import i18n from "../i18n";
import Modal from "react-modal";

// Components
import Spinner from "./Spinner";

class NewAccountConnectionModal extends Component {
  render() {
    const {
      isOpen,
      dataSource,
      isInternetDisconnected,
      isSendingReq,
      handleToggleNetworkModal,
      handleCloseExpiredAccountWithNewCredModal,
      handleDisconnectExpiredAccountWithNewCredModal
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        overlayClassName="modal-overlay"
        contentLabel={i18n.t("new_account_connection_modal__title")}
      >
        <div className="modal-wrapper find-connection-modal new-account-connection-modal">
          <h2 className="header">
            {
              i18n.t("new_account_connection_modal__title")
            }
          </h2>

          <div 
            className="modal-body"
            dangerouslySetInnerHTML = {
              {
                __html: i18n.t('new_account_connection_modal__desc', {
                  rsName: dataSource.data_source.name,
                })
              }
            }
          />

          <div className="modal-body-2">
            {
              i18n.t("new_account_connection_modal__desc_2")
            }
          </div>
  
          <div className="footer">
            {
              isInternetDisconnected
              ?
              <React.Fragment>
                <button className="noThanksButton textPrimaryColor borderPrimaryColor" onClick={handleToggleNetworkModal} >
                  {
                    i18n.t("new_account_connection_modal__user_different_connection")
                  }
                </button>
                <button className="disconnectButton backgroundPrimaryColor" onClick={handleToggleNetworkModal}> 
                  {
                    i18n.t("new_account_connection_modal__user_new_connection")
                  }
                </button>
              </React.Fragment>
              :
              isSendingReq
              ?
              <button className="disconnectButton backgroundPrimaryColor button--spinner">
                <Spinner />
              </button>
              :
              <React.Fragment>
                <button className="noThanksButton textPrimaryColor borderPrimaryColor" onClick={handleDisconnectExpiredAccountWithNewCredModal} disabled={isSendingReq} >
                  {
                    i18n.t("new_account_connection_modal__user_different_connection")
                  }
                </button>
                <button className="disconnectButton backgroundPrimaryColor" onClick={handleCloseExpiredAccountWithNewCredModal} disabled={isSendingReq}> 
                  {
                    i18n.t("new_account_connection_modal__user_new_connection")
                  }
                </button>
              </React.Fragment>
            }
          </div>
        </div>
      </Modal>  
    );
  }
}

export default NewAccountConnectionModal;