import React from "react";

// Lib
import Modal from "react-modal";

// Services
import { AuthService } from "./Services/AuthService";
import i18n from "../i18n";

class SessionModals extends React.Component {
  handlelogoutAction = async (e) => {
    e.preventDefault();

    await AuthService.logout();

    window.location.reload();  
  }


  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        ariaHideApp={true}
        overlayClassName="modal-overlay"
        contentLabel={i18n.t("session_modal__title--expiry")}
      >
        <div className="modal-wrapper session-modal">
          <h2 className="header">
            {
              i18n.t("session_modal__title--expiry")
            }
          </h2>
          <div className="modal-body">
              {i18n.t("session_modal__message--expiry")} 
          </div>
          <div className="footer">
            <button onClick={this.handlelogoutAction} className="okBtn  backgroundPrimaryColor">{i18n.t("session_modal__login")}</button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default SessionModals;