import React, { Component } from "react";

// Components
import ResourceDetailModal from "../presentation/ResourceDetailModal";

// Lib
import InputMask from "react-input-mask";

// Serivces
import ConsentRequestService from "../../Services/ConsentRequestService";
import i18n from '../../../i18n';
import SubmissionRedirectModal from "../presentation/SubmissionRedirectModal";

class ConfirmAuthorizationContainer extends Component {
  constructor(props) {
    super(props);
    
    const totalCodeInput = window.config.authorization_code_inputs;

    let inputValues = [];
    // Add default State for the inputs
    for (let i = 1; i <= Number(totalCodeInput); i++) {
      inputValues = {
        ...inputValues,
        [`input${i}`]: ""
      };
    }

    this.state = {
      isResourceDetailModalOpen: false,
      modalID: "",
      isSubmissionModalOpen: false,
      isButtonDisabled: true,
      redirectUrl: "",
      totalCodeInput,
      errorMessage: "",
      errorMessageTitle: "",
      hasError: false,
      inputValues,
      isErrorRedirecting: false,
      errorRedirectUrl: "",
      modalActionBtn: this.toggleSubmissionModal
    };
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      inputValues: {
        ...prevState.inputValues,
        [name]: value
      }
    }))
  }
  
  handleOnKeyPress = (e) => {
    const formattedAuthCode = this.handleInsertedCodeCompile();

    const ENTER_KEY = 13;
    const BACKSPACE_KEY = 8;
    const DELETE_KEY = 46;
    const LEFT_ARROW_KEY = 37;
    const RIGHT_ARROW_KEY = 39;
    
    // Submit form on enter click
    if (e.keyCode === ENTER_KEY && formattedAuthCode) {
      this.handleOnSubmit();
      return;
    }

    // Catch the delete key press
    if ((e.keyCode === BACKSPACE_KEY) || (e.keyCode === LEFT_ARROW_KEY)) {
      // Move to previous input
      
      this.handlePreviousFocus(e);
    } else {

      // Only move to next input if the value is a number
      const isNumberKeyPressed = ((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || (e.keyCode >= 96 && e.keyCode <= 105));
     
      const valueIsNumberCheck = Boolean(e.target.value && isNumberKeyPressed);

      e.currentTarget.isFirstTimeDelete = true;

      if (valueIsNumberCheck || (e.keyCode === RIGHT_ARROW_KEY) || (e.keyCode === DELETE_KEY)) {
        // Move to next input
        // Setimeout hack for safari for select()

        this.handleTabbingFocus(e);
      }
    }
  }
    
  handleTabbingFocus = (e) => {
    const { totalCodeInput } = this.state;

    const currentInputIndex = Number(e.currentTarget.getAttribute('tabIndex')); 

    if (currentInputIndex && e.type !== "click" && currentInputIndex < totalCodeInput) {
      const nextInput = this.inputMap[`${currentInputIndex + 1}`];

       // Safari hack
      setTimeout(() => {
        nextInput.focus();
        nextInput.select();
      }, 20);
    }
  }

  handlePreviousFocus = (e) => {
    const currentInputIndex = Number(e.currentTarget.getAttribute('tabIndex'));
    
    if (!e.currentTarget.isFirstTimeDelete && currentInputIndex > 1) {
      const previousInput = this.inputMap[`${currentInputIndex - 1}`];

      // Safari hack
      setTimeout(() => {
        previousInput.focus();
        previousInput.select();  
      }, 20);
    }

    e.currentTarget.isFirstTimeDelete = false;
  }

  handleInputOnClick = (e) => {
    const currentInputIndex = Number(e.currentTarget.getAttribute('tabIndex'));

    const currentInput = this.inputMap[`${currentInputIndex}`];

    currentInput.select();
  }

  handleInsertedCodeCompile = () => {
    const { totalCodeInput, inputValues } = this.state;

    let formattedAuthCode = "";

    for (let i = 1; i <= Number(totalCodeInput); i++) { 
      formattedAuthCode += inputValues[`input${i}`];
    }

    if (formattedAuthCode && formattedAuthCode.length === totalCodeInput) {
      this.setState({ isButtonDisabled: false });
      return formattedAuthCode;
    }

    this.setState({ isButtonDisabled: true });
  }

  handleOnSubmit = async () => {
    const { accessToken, request } = this.props;
    const { isErrorRedirecting } = this.state;

    const formattedAuthCode = this.handleInsertedCodeCompile();
  
    let tempErrorMessage;
    let tempErrorMessageTitle;
    let hasError = false;
    let tempIsSubmissionModalOpen = true;
    let tempIsErrorRedirecting = isErrorRedirecting;
    let tempRedirectUrl;
    let tempErrorRedirectUrl;

    this.setState({ isSubmissionModalOpen: tempIsSubmissionModalOpen })

    try {
      const result = await ConsentRequestService.redirectAfterPermissionSubmit(request.transaction_id, accessToken, formattedAuthCode)
      
      // Too many attempts error
      if (result.status === 200 && result.data.error) {
        if (result.data.error === "TOO_MANY_ATTEMPTS") {
          tempErrorMessage = i18n.t('submissionModal__201_error_desc', { client: request.client.name });
          tempErrorMessageTitle = i18n.t('submissionModal__201_error');
          hasError = true;
          tempIsErrorRedirecting = true;
          tempErrorRedirectUrl = result.data.redirect_url;
        }

        if (result.data.error === "TRANSACTION_EXPIRED") {
          tempErrorMessage = i18n.t('submissionModal__401_error_desc', { client: request.client.name });
          tempErrorMessageTitle = i18n.t('submissionModal__401_error');
          hasError = true;
          tempIsErrorRedirecting = true;
          tempErrorRedirectUrl = result.data.redirect_url;
        }

        if (result.data.error === "PERMISSION_CODE_EXPIRED") {
          tempErrorMessage = i18n.t('submissionModal__401_error_desc', { client: request.client.name });
          tempErrorMessageTitle = i18n.t('submissionModal__401_error');
          hasError = true;
          tempIsErrorRedirecting = true;
          tempErrorRedirectUrl = ConsentRequestService.CancelTransaction(request.transaction_id);
        }

        if (result.data.error === "REQUEST_DENIED") {
          tempErrorMessage = i18n.t('submissionModal__404_error_desc');
          tempErrorMessageTitle = i18n.t('submissionModal__404_error');
          hasError = true;
          tempIsErrorRedirecting = true;
          tempErrorRedirectUrl = result.data.redirect_url;
        }

          if (result.data.error === "DEVICE_CODE_EXPIRED") {
          tempErrorMessage = i18n.t('submissionModal__404_error_desc');
          tempErrorMessageTitle = i18n.t('submissionModal__404_error');
          hasError = true;
          tempIsErrorRedirecting = true;
          tempErrorRedirectUrl = result.data.redirect_url;
        }
      } else {
        tempRedirectUrl = result.data.redirect_url;
        tempIsSubmissionModalOpen = false;
      }

      this.setState({
        isSubmissionModalOpen: tempIsSubmissionModalOpen,
        redirectUrl: tempRedirectUrl,
        errorRedirectUrl: tempErrorRedirectUrl,
        hasError,
        isErrorRedirecting: tempIsErrorRedirecting,
        errorMessage: tempErrorMessage,
        errorMessageTitle: tempErrorMessageTitle,
      })
      
    } catch (e) {   

      if (e.status && e.status === 400) {
        tempErrorMessage = i18n.t('submissionModal__400_error_desc');
        tempErrorMessageTitle = i18n.t('submissionModal__400_error');
      }

      if (e.status && e.status === 401) {
        tempErrorMessage = i18n.t('submissionModal__401_error_desc', { client: request.client.name });
        tempErrorMessageTitle = i18n.t('submissionModal__401_error');
        tempIsErrorRedirecting = true;
        tempErrorRedirectUrl = ConsentRequestService.CancelTransaction(request.transaction_id);
      }

      if (e.status && e.status === 403) {
        tempErrorMessage = i18n.t('submissionModal__403_error_desc');
        tempErrorMessageTitle = i18n.t('submissionModal__403_error');
      }

      if (e.status && e.status === 404) {
        tempErrorMessage = i18n.t('submissionModal__404_error_desc');
        tempErrorMessageTitle = i18n.t('submissionModal__404_error');
      }
      
      this.setState({
        hasError: true,
        errorMessage: tempErrorMessage,
        errorMessageTitle: tempErrorMessageTitle,
        isErrorRedirecting: tempIsErrorRedirecting,
        errorRedirectUrl: tempErrorRedirectUrl,
        redirectUrl: tempRedirectUrl,
      })
    }
  }

  renderTransactionDetails = (request) => {
    const { isResourceDetailModalOpen, modalID } = this.state;
    // Upudate the permissions with resource_definition
    request.requested_resources.map(req_res => {
      
      request.permissions.map(permission => {
        
        if (permission.resource.res_def_id === req_res.resource_definition.res_def_id) {
          
          permission.resource_definition = req_res.resource_definition;
          permission.scopes_requested = req_res.scopes_requested;
        }
        
        return permission;
      })
      return req_res;
    })

    return request.permissions.map(permission => (
      <li key={permission.permission_id}>
        <div className="left-side">
          <p className="name">{permission.resource && permission.resource.name}</p>
          <p>
            <span>{i18n.t('confirmAuth__source')}</span> {permission.data_source && permission.data_source.name}
          </p>
        </div>

        <div className="right-side">
          <button onClick={this.handleToggleResourceDetailModal.bind(this, permission.permission_id)} className="details_btn textPrimaryColor">{i18n.t('confirmAuth__details')}</button>
        </div>

        {
          (isResourceDetailModalOpen && permission.permission_id === modalID)
          &&
          <ResourceDetailModal 
            isOpen={isResourceDetailModalOpen} 
            handleToggleResourceDetailModal={this.handleToggleResourceDetailModal} 
            permission={permission}   
          />
        }
      </li>
    ))
  }

  handleToggleResourceDetailModal = (modalID) => {
    const { isResourceDetailModalOpen } = this.state;

    this.setState({  isResourceDetailModalOpen: !isResourceDetailModalOpen, modalID })
  }

  renderAuthCodeInputs = () => {
    const { totalCodeInput, inputValues } = this.state;

    let renderInputs = [];
    let formattedInputMap = {};

    for (let i = 1; i <= Number(totalCodeInput); i++) {
      renderInputs.push(
        <InputMask
          key={i} 
          mask="9" 
          value={inputValues[`input${i}`]}
          maskPlaceholder={null} 
          onChange={this.handleOnChange} 
          name={`input${i}`}
          tabIndex={i}
          className="input-group-field" 
          onKeyUp={this.handleOnKeyPress} 
          ref={this.handleRef}
          onClick={this.handleInputOnClick}
          autoComplete="none"
          aria-label="Code input"
        />
      );
      
      formattedInputMap[i] = this[`inputRef${i}`];
    }

    this.inputMap = formattedInputMap;
    return renderInputs;
  }

  handleRef = (e) => {
    const tabIndex = e && e.getAttribute('tabIndex');
   
    const formattedRefName = `inputRef${tabIndex}`;
    this[formattedRefName] = e;
    this.inputMap[tabIndex] = e;
  }

  toggleSubmissionModal = () => {
    const { isSubmissionModalOpen, totalCodeInput } = this.state;
    let tempInputValue = {};

    for (let i = 1; i <= Number(totalCodeInput); i++) {
      tempInputValue = {
        ...tempInputValue,
        [`input${i}`]: "" 
      }
    }

    this.setState({
      isSubmissionModalOpen: !isSubmissionModalOpen,
      hasError: false,
      errorMessage: "",
      isButtonDisabled: true,
      redirectUrl: "",
      inputValues: tempInputValue
    })
  }
  
  render() {
    const {
      isSubmissionModalOpen,
      isButtonDisabled,
      redirectUrl,
      hasError,
      errorMessage,
      errorMessageTitle,
      isErrorRedirecting,
      errorRedirectUrl,
      modalActionBtn
    } = this.state;
    
    const { request, handleToggleNetworkModal, isInternetDisconnected } = this.props;

    // Sort request
    request.permissions.sort((a, b) => a.resource.name.toLowerCase().localeCompare(b.resource.name.toLowerCase()));

    if (redirectUrl) {
      // Clearing browser state for browser back button click after successful submission
      window.history.replaceState("state", null);
      window.localStorage.clear();

      window.location.assign(redirectUrl);
    }

    const walletAccountName = window.config.wallet_account_name;

    return (
      <div className="qr-wrapper confirmAuthContainer">
        <div className="qr-container">
          <h1>
            <div 
              dangerouslySetInnerHTML = {
                {
                  __html: i18n.t('confirmAuth__title', {
                    walletName: walletAccountName,
                    clientName: request.client.name
                  })
                }
              }
            />
          </h1>

          <h2>
            {i18n.t('confirmAuth__instruction')}
          </h2>

          <div 
            className="sign-up-wrapper"
            dangerouslySetInnerHTML = {
              {
                __html: i18n.t('confirmAuth__instrucion_desc', {
                  clientName: request.client.name
                })
              }
            }
          />

          <ul>
            {
              request.permissions && request.permissions.length
              &&
              this.renderTransactionDetails(request)
            }
          </ul>

          <div className="code_section">
            <p className="code_desc">
              {i18n.t('confirmAuth__code_desc')}
            </p>

            <h4>{i18n.t('confirmAuth__auth_code')}</h4>

            <form autoComplete="none">
              <input type="hidden" value="something" autoComplete="on" />
              {
                this.renderAuthCodeInputs()
              }
            </form>
            
            {
              isInternetDisconnected
              ?
              <button onClick={handleToggleNetworkModal} disabled={isButtonDisabled} className={isButtonDisabled ? "btn backgroundPrimaryColor disabled" : "btn backgroundPrimaryColor"}>
                {i18n.t('confirmAuth__submit')}
              </button>
              :
              <button onClick={this.handleOnSubmit} disabled={isButtonDisabled} className={isButtonDisabled ? "btn backgroundPrimaryColor disabled" : "btn backgroundPrimaryColor"} >
                {i18n.t('confirmAuth__submit')}
              </button>
            }
            <button className="code_what_if textPrimaryColor">{i18n.t('confirmAuth__what_if')}</button>
          </div>
          
        </div>

        {
          isSubmissionModalOpen
          &&
          <SubmissionRedirectModal 
            isOpen={isSubmissionModalOpen} 
            toggleSubmissionModal={modalActionBtn} 
            hasError={hasError}
            errorMessage={errorMessage} 
            errorMessageTitle={errorMessageTitle}
            isErrorRedirecting={isErrorRedirecting}
            errorRedirectUrl={errorRedirectUrl}
          />
        }
      </div>
    );
  }
}

export default ConfirmAuthorizationContainer;