import React, { Component } from "react";

// Lib
import CloseIcon from '@material-ui/icons/Close';

// Services
import i18n from "../i18n";

class SearchBar extends Component {
  render() {
    const {
      handleOnChange,
      queryString,
    } = this.props;

    return (
      <div className="connections-search-Container">
        <span className="icon-search"></span>

        <div className="input-wrapper">
          <input 
            className="search-input"
            name="search"
            type="text" 
            onChange={handleOnChange} 
            placeholder={i18n.t('connections__search')} 
            maxLength="100"
            value={queryString}
            aria-label="Search"
          />
        </div>

        {
          queryString
          &&
          <button onClick={handleOnChange}>
            <CloseIcon className="textPrimaryColor" />
          </button>
        }
      </div>
    );
  }
}

export default SearchBar;
