import React, { useState, useEffect } from "react";

// Libs
import { Redirect } from "react-router-dom";
import qs from "query-string";

// Services
import ProfileService from "./Services/ProfileService";

// Components
import Spinner from "./Spinner";

const VCRedirectRouter = ({ location }) => {
  const [error, setError] = useState({});
  const [redirectTo, setRedirectTo] = useState("");
  const [isVCConnectionAdded, setVCConnectionAdded] = useState(false);
  const [VCConnectionDetails, setVCConnectionDetails] = useState({});
  
  useEffect(() => {
    let isMounted = true;
    
    //====== Verifier Credentials Start =========
    // 0.1. Verifier Credentials Connection check
    // if c_i available in url
    // make the post call for connection
    const handleVCConnectionCheck = async () => {  
      try {
        const queryParts = qs.parse(location.search);

        const result = await ProfileService.postCredentialConnections(queryParts.c_i);
        if (isMounted) {
          setVCConnectionAdded(true)
          setVCConnectionDetails(result)
          setRedirectTo(window.GLOBAL_PATH + 'profile')
        }
      } catch (e) {
        if (isMounted) {
          setError(e.data)
          setRedirectTo(window.GLOBAL_PATH + 'profile')
        }
      }
    }
    
    handleVCConnectionCheck();
    
    return () => {
      isMounted = false;
    };
  }, [location]);

  if (redirectTo) {
    return (
      <Redirect to=
        {{ 
          pathname: redirectTo, 
          state: { 
            VCConnectionError: error, 
            VCConnectionDetails,
            isVCConnectionAdded,
            lookup_code: error?.lookup_code
          }, 
        }} 
      />
    );
  }

  return (
    <div className="redirect-router-spinner-wrapper">
      <Spinner />
    </div>
  );
}

export default VCRedirectRouter;