// Services
import DatasourceService from "../../Services/DatasourceService";
import ProofRequestsService from "../../Services/ProoofRequestsService";

// ===========================
// VC CONNECTION FUNCTIONS 
// ===========================

export const handleVCConnectionLogicOnLoad = (detailsData) => {
  return new Promise(async (resolve) => { 
    let getConnectionByID = {};
    let connectionID = detailsData.prevRoute === "PROFILE_PAGE" ? detailsData.connection_id : detailsData.id;
    
    // 1. Get the Connection BY ID

    getConnectionByID = await DatasourceService.getVcConnectionsByID(connectionID);
    
    // 1.1 Add back the stakeholder info once we fetch by ID

    getConnectionByID.vc_stakeholder = detailsData.vc_stakeholder;

    // 2. IF there are credentials Available
    // Get Revocation Status and
   
    if (getConnectionByID.credentials?.length) {
      getConnectionByID = await Promise.resolve(handleVCConnectionCredentialActivityAndRevocationStatus(getConnectionByID));
      
      // 2.1 Filter and use only active credentials
      if (getConnectionByID.credentials?.length > 1) {

        // 2.1.1 Sort by received at time of the  credential
        getConnectionByID.credentials.sort((a, b) => new Date(b.received_at) - new Date(a.received_at));

        const filteredCred = getConnectionByID.credentials.filter(credential => (credential.state === "ACCEPTED" || !credential.revoked))
        
        // 2.1.2 If active credential available then pick it up
        // Only the first active one
        if (filteredCred.length) {

          getConnectionByID.credentials = filteredCred.slice(0, 1);
        } else {
  
          // otherwise pick the latest revoked credential
          getConnectionByID.credentials = getConnectionByID.credentials.slice(0, 1);
        }
      }
    }
  
    resolve(getConnectionByID);
  })
}

export const handleVCConnectionCredentialActivityAndRevocationStatus = (getConnectionByID) => { 
  let credentialActivity = [];
  let tempRevoked = false;
    
  return new Promise(async (resolve) => { 

    // Need this for loop coz otherwise foreach/map or other loops won't wait for await call to finish
    for (let i = 0; i < getConnectionByID.credentials.length; i++) {
      try {
        // 2.1 Get the Revocation Status of Each Credential 

        tempRevoked = await Promise.resolve(DatasourceService.checkVerifiableCredentialRevocationStatus(getConnectionByID.credentials[i].identifier));

        // 2.2 Get the Activity of Each Credential 

        credentialActivity = await DatasourceService.getVerifiableCredentialActivity(getConnectionByID.credentials[i].identifier);

        // 2.3 Sort by time
        credentialActivity.sort((a, b) => new Date(b.time) - new Date(a.time));

      } catch (e) {
        console.log(e)
      }
      
      getConnectionByID.credentials[i].revoked = tempRevoked.revoked;
      getConnectionByID.credentials[i].credentialActivity = credentialActivity;
    }
    
    resolve(getConnectionByID)
  })
}


// ===========================
// Proof Requests FUNCTIONS 
// ===========================

export const handleProofRequestLogicOnLoad = (detailsData) => {
  return new Promise(async (resolve) => { 
    let proof_requests = [];
    let connectionID = detailsData.prevRoute === "CONSENT_PAGE" ? detailsData.connection_id : detailsData.id;

    // 1. Get the list of Proof Requests

    proof_requests = await ProofRequestsService.getPendingProofRequests("ACCEPTED");
    
    // 2. Filter Requests based on the Connection ID
    proof_requests = proof_requests.filter(proof_Request => connectionID === proof_Request.connection_id);
    
    // 2.1 Sort by received at time of the  credential
    proof_requests.sort((a, b) => new Date(b.received_at) - new Date(a.received_at));

    // 2.2 Pick the first one
    if (proof_requests.length) {
      proof_requests = proof_requests.slice(0, 1);
    }
    
    detailsData.proof_requests = proof_requests;

    resolve(detailsData);
  })
}