import React from "react";

// Lib
import i18n from "../../../i18n";
import Modal from "react-modal";
import InputLabel from '@material-ui/core/InputLabel';

// Components
import CustomTextfield from "../../CustomTextfield";
import CustomSelect from "../../CustomSelect";
import Spinner from "../../Spinner";

const DeleteAccountModal = ({ isOpen, deleteReasons, handleToggleDeleteModal,
 handleDeleteFormOnChange, handleDeleteFormSelectOnChange, deleteFormInputs, isInternetDisconnected, handleToggleNetworkModal, handleDeleteAction, isSendingReq, handleInputOnBlur })  => {

  let formattedDeleteReasons = deleteReasons.map(item => ({ label: i18n.t(item), value: item }));
  
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={true}
      overlayClassName="modal-overlay"
      contentLabel={i18n.t("settings__delete_account_title")}
    >
      <div className="modal-wrapper delete-modal">
        <h2 className="header">
          {
            `${i18n.t("settings__delete_account_title")}?`
          }
        </h2>
        <div className="modal-body">
          <div className="description-section"
            dangerouslySetInnerHTML={{
              __html: i18n.t('settings__delete_account_modal_description')
            }}
          />
          
          <div className="form-group">
              <InputLabel htmlFor="reason">{i18n.t("settings__delete_account_modal_reason_title")}</InputLabel>
              <CustomSelect
                className="reason-select"
                Name="reason"
                Required={true} 
                Value={deleteFormInputs.reason}
                SelectOptions={formattedDeleteReasons}
                handleOnChange={handleDeleteFormSelectOnChange}
                Placeholder={i18n.t("settings__delete_account_modal_select_default_option")}
              />
          </div>

          <div className="form-group">
              <InputLabel htmlFor="deleteField">{i18n.t("settings__delete_account_modal_delete_input_title")}</InputLabel>
              <CustomTextfield 
                className="delete-textfield"
                Name="deleteField"
                Required={true} 
                Value={deleteFormInputs.deleteField}
                handleOnChange={handleDeleteFormOnChange}
                handleOnBlur={handleInputOnBlur}
              />
          </div>
        </div>

        <div className="footer">
          
          {/* Internet Disconnected */}

          {
            isInternetDisconnected
            &&
            <button 
              className="disconnectButton backgroundPrimaryColor" 
              onClick={handleToggleNetworkModal}
              disabled={deleteFormInputs.isInvalidInputs}
            > 
              {
                i18n.t("settings__delete_account_modal_delete_account_btn_text")
              }
            </button>
          }
          
          {/* Spinner while sending request */}

          {
            isSendingReq
            &&
            <button className="disconnectButton backgroundPrimaryColor button--spinner">
              <Spinner />
            </button>
          }

          {
            (!isInternetDisconnected && !isSendingReq)
            &&
            <button 
              className="disconnectButton backgroundPrimaryColor" 
              onClick={handleDeleteAction}
              disabled={deleteFormInputs.isInvalidInputs}
            > 
              {
                i18n.t("settings__delete_account_modal_delete_account_btn_text")
              }
            </button>
          }

          <button className="noThanksButton textPrimaryColor borderPrimaryColor" onClick={handleToggleDeleteModal} >
            {
              i18n.t("logout__cancel")
            }
          </button>
        </div>
      </div>
    </Modal>  
  );
}

export default DeleteAccountModal;