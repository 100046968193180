// This is used in ConsentContainer

import React from "react";

// Lib
import i18n from "../i18n";
import Modal from "react-modal";

// Components
import Spinner from "./Spinner";

const EditNicknameModalConsent = ({
  isOpen,
  title,
  description,
  handleOnChange,
  nicknameValues,
  isSendingReq,
  isInternetDisconnected,
  handleToggleNetworkModal,
  handleEditNicknameAction,
  dsName,
  logo_url,
  dsAccount
}) => {
  

  // Check if any input is empty

  const isFinishButtonDisabled = Object.values(nicknameValues).some(item => item.nickname === '');
  
  // Render footer 

  const renderFooterButtons = () => {
    let tempButtons;

    if(isInternetDisconnected) {
      tempButtons = (
        <button
          className="disconnectButton backgroundPrimaryColor"
          onClick={handleToggleNetworkModal}
        >
          {i18n.t("edit_nickname_modal__submit_button")}
        </button>
      );
    } else if (isSendingReq) {
      tempButtons = (
        <button className="disconnectButton backgroundPrimaryColor button--spinner">
          <Spinner />
        </button>
      );
    } else {
      tempButtons = (
        <button
          className="disconnectButton backgroundPrimaryColor"
          onClick={() => handleEditNicknameAction(nicknameValues)}
          disabled={isFinishButtonDisabled || isSendingReq}
        >
          {i18n.t("edit_nickname_modal__submit_button")}
        </button>
      );
    }

    return tempButtons; 
  }

  const renderModalBody = () => { 
      return (
          <div className="modal-body">
            { renderNicknameBodySection() }
            { renderDelegatedAccountsSection() }
          </div>
      )
  }

  const renderNicknameBodySection = () => {
      return (
        <div>
          <div
            className="header-section"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <div className="input-wrapper">
            <input
              className="search-input edit-input"
              name={dsAccount.ds_account_id}
              type="text"
              onChange={handleOnChange}
              placeholder={i18n.t("edit_nickname_modal__input_placeholder")}
              value={nicknameValues[dsAccount.ds_account_id] ? nicknameValues[dsAccount.ds_account_id].nickname : ""}
              maxLength="30"
            />
            
            {
              nicknameValues[dsAccount.ds_account_id]
              &&
              <span className={nicknameValues[dsAccount.ds_account_id].error ? "error error-editModal" : "error"}>
                {nicknameValues[dsAccount.ds_account_id].error}
              </span>
            }
          </div>
        </div>
      )
  }
  // Render Delegated Section
  const renderDelegatedAccountsSection = () => {
    
    if (dsAccount.delegatedAccounts && dsAccount.delegatedAccounts.length) {
      return (
        <div className="delegated-wrapper">
          <h3>{i18n.t("edit_nickname_modal__delegated_title")}</h3>
          <span
            className="sub-heading"
            dangerouslySetInnerHTML={{ __html: i18n.t("edit_nickname_modal__delegated_subtitle", { dsName }) }}
          />

          {
            renderDelegatedAccounts()
          }
        </div> 
      )
    }
  }
  
  // Render Delegated Accounts

  const renderDelegatedAccounts = () => { 
    return (
      dsAccount.delegatedAccounts.map(delegatedAcc => (
        <div className="input-wrapper" key={delegatedAcc.ds_account_id}>
          <input
            className="search-input edit-input"
            name={delegatedAcc.ds_account_id}
            type="text"
            onChange={handleOnChange}
            placeholder={i18n.t("edit_nickname_modal__input_placeholder")}
            value={nicknameValues[delegatedAcc.ds_account_id] ? nicknameValues[delegatedAcc.ds_account_id].nickname : ""}
            maxLength="30"
          />

          {
            nicknameValues[delegatedAcc.ds_account_id]
            &&
            <span className={nicknameValues[delegatedAcc.ds_account_id].error ? "error error-editModal" : "error"}>
              {nicknameValues[delegatedAcc.ds_account_id].error}
            </span>
          }
        </div>
      ))
    )
  }
    
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      overlayClassName="modal-overlay white-bg-modal-overall"
      contentLabel={title}
    >
      <div className="modal-wrapper edit-nickname-modal">
        {/* Header Section */}

        <div className="header">
          {/* SHow Logo if present */}

          {
            logo_url
            &&
            <img src={logo_url} className="icon-img" alt="" role="presentation" />
          }

          {/* Show Success Icon for successful renew connection if logo is not there */}
          
          {
            (dsAccount.selected_connection_type === "RENEW_CONNECTION" && !logo_url)
            &&
            <span className="icon-iconCheck"></span>
          }

          <h2 dangerouslySetInnerHTML={{ __html: title }} />
        </div>

        {/* Middle Content Section */}
        { renderModalBody() }

        {/* Footer Section */}

        <div className="footer">
          {
            renderFooterButtons()
          }

          <p 
            className="footer-desc"
            dangerouslySetInnerHTML = {{ __html: i18n.t("edit_nickname_modal__footer_description", { dsName }) }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditNicknameModalConsent;
