import React from "react";

// Components
import ResourceItem from "../presentation/ResourceItem";

// Services
import DatasourceService from "../../Services/DatasourceService";
import Modal from "react-modal";
import i18n from "../../../i18n";

// Lib
import CloseIcon from '@material-ui/icons/Close';

class DataSourceSelectorModal extends React.Component {
  state = {
    availableResources: [],
    isSendingReq: false,
    hasError: false,
    clickedRsID: null,
  };


  componentDidMount = () => {   
    const { datasources, connectedAccounts, resource } = this.props;
    this.setState({availableResources: DatasourceService.handleSources(datasources, connectedAccounts, resource)})
  }


  componentDidUpdate = (prevProps) => {
    // Rerendering of this component if the props changes
    if (prevProps.datasources !== this.props.datasources || prevProps.connectedAccounts !== this.props.connectedAccounts) {
      this.setState({availableResources: DatasourceService.handleSources(this.props.datasources, this.props.connectedAccounts, this.props.resource)})
    }
  }

  handleConnectRsAction = async (account, connectionType) => {
    const { connectedAccounts, transaction_id } = this.props;
    
    const tempDataSourceID = account.data_source ? account.data_source.data_source_id : account.data_source_id;

    const tempClickedRsID = account.ds_account_id ? account.ds_account_id : account.data_source_id;
    
    this.setState({
      isSendingReq: true,
      clickedRsID: tempClickedRsID,
      hasError: false
    });
    
    await DatasourceService.requestConnection(tempDataSourceID, "", connectedAccounts, account, connectionType, transaction_id)
      .then(result => (
        document.location = result.auth_request
      ))
      .catch(error => {
        if (error.status !== 401) {
          this.setState({
            hasError: true,
            clickedRsID: tempClickedRsID,
            isSendingReq: false
          })
        }
      }
    );
  };


  useConnectRsAction = (chosenSource) => {
    const { resource } = this.props;

    this.props.onDataSourceSelect(chosenSource, resource);
  };


  render() {
    const {
      availableResources,
      isSendingReq,
      hasError,
      clickedRsID
    } = this.state;

    const {
      resourceName,
      isOpen,
      onRequestClose,
      isInternetDisconnected,
      handleToggleNetworkModal
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={true}
        className="data-source-selector-modal"
        overlayClassName="modal-overlay"
        contentLabel={i18n.t("modal__title")}
      >
        <button className="data-source-modal-exit" onClick={onRequestClose} aria-label={i18n.t("resourceDetailsModal__close")}>
          <CloseIcon className="arrow-icon" />
        </button>

        <h2 className="modal-title">{i18n.t("modal__title")}</h2>
        
        <div className="modal-subtitle">{i18n.t("modal__subtitle")} <strong>{resourceName}</strong></div>

        {
          availableResources.length
          ?
          <ResourceItem
            availableResources={availableResources}
            hasError={hasError}
            isSendingReq={isSendingReq}
            clickedRsID={clickedRsID}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            useConnectRsAction={this.useConnectRsAction}
            handleConnectRsAction={this.handleConnectRsAction}
          />
          :
          null
        }
      </Modal>
    );
  }
}

export default DataSourceSelectorModal;
