import React from "react";

// Lib
import MediaQuery from 'react-responsive';

// Components
import CustomTable from "../../CustomTable";

const TableMobileVersion = ({ 
    handleMobileDevice, 
    isLoading, 
    isInternetDisconnected, 
    tableData, 
    onSortClick,
    handleOnSortClick,
    customSort,
    fields,
    sortIcon
}) => {
    return (
        <MediaQuery maxDeviceWidth={750} onChange={handleMobileDevice}>
            <CustomTable
                isLoading={isLoading}
                isInternetDisconnected={isInternetDisconnected}
                tableData={tableData}
                onSortClick={onSortClick}
                handleOnSortClick={handleOnSortClick}
                customSort={customSort}
                fields={fields}
                sortIcon={sortIcon}
                isMobileVersion={true}
            />  
        </MediaQuery>
    );
}

export default TableMobileVersion;
