import axios from "axios";
import { AuthService } from "./AuthService";

let ProofRequestsApi = {};

var getEndpoint = function() {
  return window.config.url;
};

ProofRequestsApi.getPendingProofRequests = function (state) {  
  return new Promise((resolve, reject) => {
    axios
      .get("/me/verifiable-credentials/proof-requests", {
        baseURL: getEndpoint(),
        params: { state },
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

ProofRequestsApi.getPendingProofRequestByID = function (proofID) {  
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/verifiable-credentials/proof-requests/${proofID}`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};


ProofRequestsApi.getValidVerifiableCredentials = function (proofID) {  
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/verifiable-credentials/proof-requests/${proofID}/valid-verifiable-credentials`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};



ProofRequestsApi.acceptProofRequest = function (proofID, accept, credential_id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/me/verifiable-credentials/proof-requests/${proofID}?accept=${accept}`, { credential_id }, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error.response));
  });
};

export default ProofRequestsApi;
