import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

//Component
import ConsentQRContainer from "../container/ConsentQRContainer";

class ConsentQRPage extends Component {

  render() {

    const { isInternetDisconnected, handleToggleNetworkModal } = this.props;

    return (
      <div className="consentQR">
        <Helmet title={i18n.t("consentqr__page_title")} />
        <ConsentQRContainer 
          isInternetDisconnected={isInternetDisconnected}
          handleToggleNetworkModal={handleToggleNetworkModal} 
          isLoginFailed={this.props.location.state && this.props.location.state.isLoginFailed} 
        />
      </div>
    );
  }
}

export default ConsentQRPage;