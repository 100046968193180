import React from "react";

// Lib
import i18n from "../../../i18n";

// Components
import Spinner from "../../Spinner";

const ActionButtons = ({ readyToSubmit, isInternetDisconnected, handleToggleNetworkModal, isSendingReq, pushPermissionsAction, handleToggleDeclineModal }) => {

  const renderConsentButton = () => {

    if (isInternetDisconnected) {
      return (
        <button 
          className="consent-auth backgroundPrimaryColor"
          onClick={handleToggleNetworkModal} 
        >
          {i18n.t("consent__auth_btn")}
        </button>
      )
    } else if (isSendingReq) {
      return (
        <button
          className="consent-auth button--spinner backgroundPrimaryColor"
        >
          <Spinner />
        </button>
      )
    } else {
      return (
        <button
          className={!readyToSubmit ? "consent-auth consent-auth-disabled" : "consent-auth backgroundPrimaryColor"}
          disabled={!readyToSubmit ? true : false}
          onClick={pushPermissionsAction.bind(this, false)}
        >
          {i18n.t("consent__auth_btn")}
        </button>
      )
    }
  }
  
  const renderDeclineButton = () => { 
    if (isInternetDisconnected) {
      return (
        <button 
        className="consent-decline textPrimaryColor"
        onClick={handleToggleNetworkModal} 
        >
          {i18n.t("consent__decline_btn")}
        </button>
      )
    } else {
      return (
        <button className="consent-decline" onClick={handleToggleDeclineModal}>
          {i18n.t("consent__decline_btn")}
        </button>
      )
    }
  }

  return (
    <>
       {/* Consent Button */}

        {
          renderConsentButton()
        }
        
        {/* Decline Button */}

        {
          renderDeclineButton()
        }
    </>
  );
}

export default ActionButtons;