import React from "react";

// Services
import i18n from "../i18n";

// Components
import Spinner from "./Spinner";

const ConnectRenewButton = ({ isSendingReq, clickedRsID, dsAccountID, isInternetDisconnected, handleToggleNetworkModal, isReconnectBtn, isUseConnectionBtn, handleSubmitAction, buttonType, account, isAddButton, isConnectedText }) => {

  const renderActionButtons = (btnAction) => {
    let btnText;
    let tempClassName = "connect connect-button textPrimaryColor borderPrimaryColor";

    if (isReconnectBtn) {
      btnText = i18n.t("modal__reconnect_btn");
      tempClassName = "connect reconnect-button";

    } else if (isUseConnectionBtn) {
      btnText = i18n.t("modal__connection_btn");
      tempClassName = "connected useConnection-btn textPrimaryColor borderPrimaryColor";

    } else if (isAddButton) {
      btnText = i18n.t("findConnections__add_connection_button");

    } else {
      btnText = i18n.t("modal__connect_btn");
      
    }

    return (
      <button
        className = {tempClassName}
        onClick={btnAction}
      >
        { btnText }
      </button>
    )
  }

  const renderButtons = () => {
    let tempButton;

    if (isInternetDisconnected) {
      tempButton = renderActionButtons(handleToggleNetworkModal);

    } else if (isSendingReq && (clickedRsID === dsAccountID)) {
      tempButton = (
        <button className={isReconnectBtn ? "connect reconnect-button button--spinner" : "connect connect-button button--spinner button--spinner-bg textPrimaryColor borderPrimaryColor"}> 
          <Spinner />
        </button>
      );

    } else if (isConnectedText) {
      tempButton = (
        <div className="connect connect-button">
          {i18n.t("findConnections__connected")}
        </div>
      );

    } else {
      tempButton = renderActionButtons(() => handleSubmitAction(account, buttonType));

    }

    return tempButton;
  }

  return (
    renderButtons()
  )
}

export default ConnectRenewButton;
