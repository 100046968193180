import React from "react";

// Lib
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const CredentialNotification = ({ handleOnClickAction, description }) =>  {
    return (
        <button onClick={handleOnClickAction} className="credential-notification">
            <span dangerouslySetInnerHTML={{ __html: description }} />

            <div className="icon">
              <ArrowRightIcon className="caretStyle textPrimaryColor" />
            </div>
        </button>
    )
}

export default CredentialNotification;
