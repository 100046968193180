import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

// Components
import ConnectionsContainer from "../container/ConnectionsContainer";

class ConnectionsPage extends Component {
  render() {

    const { handleReload, isInternetDisconnected } = this.props;
    return (
      <div className="connections">
        <Helmet title={i18n.t("connections__title")} />
        <ConnectionsContainer handleReload={handleReload} isInternetDisconnected={isInternetDisconnected} />
      </div>
    );
  }
}

export default ConnectionsPage;
