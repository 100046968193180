import React from "react";
import i18n from "../i18n";

// Libraries
import { NavLink } from "react-router-dom";

// Components
import packageJson from '../../package.json';
import Header from "./Header";

class Sidebar extends React.Component {
    render() {
        const { handleDrawerToggle } = this.props;

        return (
            <React.Fragment>              
                <nav className="sidebar" aria-label="sidebar">
                    <div className="header-wrapper header-wrapper--sidebar">
                        <Header handleDrawerToggle={handleDrawerToggle} isSidebar={true} />
                    </div>

                    <div className="nav-item">
                        <NavLink className="profile" activeClassName="active" to={window.GLOBAL_PATH+"profile"} onClick={handleDrawerToggle}>
                            {/* <div className="backdrop" /> */}
                            <div className="content-wrapper">
                                <span className="icon-icon-my-account"></span>
                                <p>{i18n.t('sidebar__my_account')}</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className="nav-item">
                        <NavLink className="connections" activeClassName="active" to={window.GLOBAL_PATH+"my-activity"} onClick={handleDrawerToggle}>
                            {/* <div className="backdrop" /> */}
                            <div className="content-wrapper content-wrapper--activity">
                                <span className="icon-icon-my-activity-2"></span>
                                <p>{i18n.t('sidebar__my_activity')}</p>
                            </div>           
                        </NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink className="connections" activeClassName="active" to={window.GLOBAL_PATH+"settings"} onClick={handleDrawerToggle}>
                            {/* <div className="backdrop" /> */}
                            <div className="content-wrapper content-wrapper--activity">
                                <span className="icon-icon-settings-3"></span>
                                <p>{i18n.t('sidebar__settings')}</p>
                            </div>           
                        </NavLink>
                    </div>
                  
                    {/* Version Number */}
                    <div className='app-version'>{packageJson.version}</div> 
                </nav>
            </React.Fragment>
        );
    }
}

export default Sidebar;
