import React, { Component } from "react";

// Libs
import { Redirect } from "react-router-dom";
import qs from "query-string";

// Services
import ConsentRequestService from "./Services/ConsentRequestService";

// Components
import Spinner from "./Spinner";
class AsRequestRouter extends Component {
  constructor(props) {
    super(props);

    // TODO: if not authenticated redirect to the login & qr page
    const queryParts = qs.parse(this.props.location.search);
  
    // TODO: if not query redirect _somewhere_
    // added resource for no session flow to skip /consent-request page 
    this.state = {
      requestId: queryParts.token,
      client: queryParts.client,
      redirectTo: null,
      resource: queryParts.resources,
      loadSpinner: true
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevState?.redirectTo !== this.state.redirectTo) {
      const {redirectTo} = this.state;
      if(redirectTo && /^((http|https):\/\/)/.test(redirectTo) && !this.props.isInternetDisconnected) {
        window.location.assign(redirectTo);
        return;
      }
      if(redirectTo && redirectTo !== null) {
        this.setState({loadSpinner: !Boolean(redirectTo)})
      }
    }
  }

  componentDidMount = () => {
    // TODO: pull the token off of the query param
    return ConsentRequestService.receiveRequestWithToken(this.state.requestId, this.state.client, this.state.resource).then(
      result => {
        this.setState({ redirectTo: result });
      }
    );
  }

  render() {
    const { redirectTo, loadSpinner } = this.state;
    if (loadSpinner) {
      return (
        <div className="redirect-router-spinner-wrapper">
        <Spinner />
      </div>
      )
    }
    return <Redirect to={{ pathname: redirectTo }} />;
  }
}

export default AsRequestRouter;