import React from "react";

const EmptyScreenMessage = ({ title, body, actionBtn }) => {
  return (
    <div className = "noResultErrorMessage" >
      <h3>{title}</h3>

      <p>{body}</p>

      {
        actionBtn
      }
    </div>
  );
}

export default EmptyScreenMessage;
