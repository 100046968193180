import React from "react";

// Components
import SearchBar from "../../SearchBar";

// Lib
import i18n from "../../../i18n";

const HeaderSection = ({ handleOnChange, queryString }) => {
  return (
    <>
      <div className="connections-titleBar">
        <h1>{i18n.t("connections__title")}</h1>

      </div>

      {/* Search Bar */}

      <SearchBar 
        handleOnChange={handleOnChange}
        queryString={queryString}
      /> 
      <p className="description">{i18n.t("connections__description")}</p>
    </>
  );
}

export default HeaderSection;
