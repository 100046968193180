import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';

// Services
import DatasourceService from "../../Services/DatasourceService";
import { handleVCConnectionLogicOnLoad, handleProofRequestLogicOnLoad } from "../services";

//Components
import DisconnectDetailsModal from "../presentation/DisconnectDetailsModal";
import DisconnectSourceModal from "../presentation/DisconnectSourceModal";
import Spinner from "../../Spinner";
import ErrorModal from "../../ErrorModal";
import EditNicknameModal from "../../EditNicknameModal";
import ServiceProviderContainer from "./ServiceProviderContainer";
import InformationSourceContainer from "./InformationSourceContainer";
import VerifiedCredentialContainer from "./VerifiedCredentialContainer";
import ProofRequestContainer from "./ProofRequestContainer";

// Lib
import i18n from "../../../i18n";
import AnalyticsService from "analytics-web";
import { orderBy } from 'lodash';
class ConnectionDetailContainer extends Component {

  state = {
    serviceProviderfields: [],
    informationSourcefields: [],
    verifiedCredentialsDetailsfields: [],
    verifiedCredentialsActivityfields: [],

    isServiceProvider: false,
    isInformationSource: false,
    isVCConnection:  false,
    isProofRequest: false,
    detailsData: {},

    isDisconnectSourceModalOpen: false,
    selectedClient: {},

    isDisconnectDetailsModalOpen: false,

    showRowMenu: false,
    clickedRow: {},

    onSortClick: false,
    uniqueTableIdentifier: "",

    isLoading: true,

    inModalMessage: false,
    hasError: false,
    errorTitle: "",
    errorMessage: "",
    errorButtonText: i18n.t("ok"),

    isSendingReq: false,
    isEditNickNameModalOpen: false,
    my_activity_configs: window.config.my_activity_configs
  }

  componentDidMount = async () => {
    let { isInternetDisconnected, detailsData } = this.props;

    // These are required for lib table
    let isSmallScreen = false;

    // CHeck the responsiveness
    if (window.innerWidth <= 750) {
      isSmallScreen = true;
    }

    this.handleMobileDevice(isSmallScreen);

    // 1. Get Org by ID

    let isServiceProvider = false;
    let isInformationSource = false;
    let isVCConnection = false;
    let isProofRequest = false;

    if (detailsData && !isInternetDisconnected) {

      try {

        //===============================================
        // 2. Logics for Verifiable Credential Connection
        //===============================================
       
        if (detailsData.vc_stakeholder) {
          isVCConnection = true;
        
          // Boolean to check for Verifier i.e Proof request
          if (detailsData.orgType === i18n.t("connectionDetail__serviceProvider")) {
            isProofRequest = true;
            
            detailsData = await Promise.resolve(handleProofRequestLogicOnLoad(detailsData));
          }

          if (!isProofRequest) {
            detailsData = await Promise.resolve(handleVCConnectionLogicOnLoad(detailsData));
          }
        }
     
        //=====================================================
        // 3. Logics for Information Source && Service Provider
        //=====================================================
        
        if (!isVCConnection) { 

          //=============
          // 1. API CALLS
          //=============

          const apiResult = await Promise.resolve(this.handleApiCallsOnLoad(detailsData));

          // 1.1 If information source

          if (detailsData.orgType === i18n.t("connectionDetail__informationSource")) {
            isInformationSource =  true;

            detailsData = await Promise.resolve(this.handleInformationSourceLogicOnLoad(detailsData, apiResult.dsAccounts, apiResult.allPermissions));
          } 
      
          // 1.2 If service provider
      
          if (detailsData.orgType === i18n.t("connectionDetail__serviceProvider")) {
            isServiceProvider = true;

            detailsData = await Promise.resolve(this.handleServiceProviderLogicOnLoad(detailsData, apiResult.org, apiResult.dsAccounts));
          }
        }
            
        //Analytics

        this.handleAnalyticsTrack("onLoad_event");
      
        this.setState({
          isServiceProvider,
          isInformationSource,
          isVCConnection,
          isProofRequest,

          detailsData,
          isLoading: false,
        })
      } catch (e) {
        if (e.status && e.status !== 401) {
          this.setState({
            isLoading: false,
            hasError: true,
            errorTitle: i18n.t("connectionDetail__failure_load_title"),
            errorMessage: i18n.t("connectionDetail__failure_load_desc")
          })
        } 
      }
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        errorTitle: i18n.t("connectionDetail__failure_load_title"),
        errorMessage: i18n.t("connectionDetail__failure_load_desc")
      })
    }
  }

  // Api Calls for Information Source and Service Provider

  handleApiCallsOnLoad = (detailsData) => {
    return new Promise(async (resolve, reject) => {
      let org = {};
      let dsAccounts = [];
      let allPermissions = [];

      try {
        // # Get org by id 
        org = await DatasourceService.getOrganizationById(detailsData.orgInfo.id);
        
        // # Get all accounts
        dsAccounts = await DatasourceService.getMyDatasourceAccounts();
    
        // # Get all permissions
        allPermissions = await DatasourceService.getPermissions();
  
        resolve({
          org,
          dsAccounts,
          allPermissions
        });
      } catch (error) {
        reject(error)
      }
    })
  }

  // ===========================
  // SERVICE PROVIDER FUNCTIONS 
  // ===========================

  handleServiceProviderLogicOnLoad = (detailsData, org, dsAccounts) => {
    return new Promise((resolve) => {
      if (org?.clients?.length) {
    
        let uniquePermissions = [];
  
        
        org.clients.map(client => {
          
          // 3.1 Group the permissions based on data sources

          if (client.permissions?.length) {
            // 3.1.1 Sort by latest permissions
           client.permissions.sort((a, b) => new Date(b.created) - new Date(a.created));

            // 3.1.2 Get the unique resources if the dataSource is same
            uniquePermissions = this.handleGetUniqueResourcesForSameDatasource(client);
          }
         
          // 3.2 Find the disabled permisisons for a client and update isDisabled.
          uniquePermissions = this.handleUpdateDisabledPermissionsForClient(dsAccounts, uniquePermissions, client)
          
          // 3.3 Check if all the permission for a client disabled.
          const isAllThePermissionsDisabled = client.permissions && client.permissions.length &&  client.permissions.filter(permission => !permission.disabled)
  
          // 3.4 Sort by same data source name
          uniquePermissions.length && uniquePermissions.sort((a, b) => a.resource.name.localeCompare(b.resource.name));
  
          // 3.5 Sort by disabled
          uniquePermissions.length && uniquePermissions.sort((a, b) => a.isDisabled - b.isDisabled);
          
          client.isDisabled = !isAllThePermissionsDisabled?.length ? true : false;
          client.uniquePermissions = uniquePermissions;
        
          return client;
        })
  
        // 5.2 Sort By Name all Clients.....
        org.clients.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // names must be equal
          return 0;
        });
  
        detailsData.clients = org.clients;
      }

      resolve(detailsData);
    })  
  }

  // ===========================================================
  // SECONDARY MEHODS FOR handleServiceProviderLogicOnLoad start
  // ===========================================================
  
  // Get the unique resources if the dataSource is same

  handleGetUniqueResourcesForSameDatasource = (client) => {
    return (
      client.permissions.reduce((unique, permission) => {
        if (!unique.some(obj => obj.resource.name === permission.resource.name && obj.data_source.name === permission.data_source.name && obj.ds_account_id === permission.ds_account_id)) {
          unique.push(permission);
        }
        
        return unique;
      }, [])
    )
  }

  // Find the disabled permisisons for a client and update isDisabled

  handleUpdateDisabledPermissionsForClient = (dsAccounts, uniquePermissions, client) => {
    dsAccounts?.length && dsAccounts.forEach(account => {
      uniquePermissions?.length && uniquePermissions.forEach(uniqPerm => {
        // 3.2.1 Add Nickname
        if (account.ds_account_id === uniqPerm.ds_account_id) {
          uniqPerm.nickname = account.nickname;
        }
        
        // 3.2.2 Add client to unique identifier 
        uniqPerm.client =   {
          identifier: client.identifier,
          name: client.name,
        };

        let disabledPermissionsArray= [];
        
        // 3.2.3 Disbaled ones  
        client.permissions?.length && client.permissions.map(permission => {
          if ((uniqPerm.resource.type === permission.resource.type) && (uniqPerm.data_source.data_source_id === permission.data_source.data_source_id) && (uniqPerm.ds_account_id === permission.ds_account_id)) {
            disabledPermissionsArray.push(permission);
          }

          return permission;
        })

        const filterDisabledClientsPermissions = disabledPermissionsArray.length && disabledPermissionsArray.filter(disabledPermission => !disabledPermission.disabled);
        
        uniqPerm.isDisabled = !filterDisabledClientsPermissions.length ? true : false;
      });
    })

    return uniquePermissions;
  }

  // =========================================================
  // SECONDARY MEHODS FOR handleServiceProviderLogicOnLoad end
  // =========================================================

  handleDisconnectServiceProviderDetailsModalSubmitButton = (e) => {
    // Prevent parent event
    e.stopPropagation();

    const { clickedRow, detailsData } = this.state;
    
    let formattedPermissionsID = [];
    const formattedPermissionsIdArray = {
      "permission_ids": formattedPermissionsID
    };
    
    // Spinner
    this.setState({ isSendingReq: true });
    // 1. Disable Unique permission
    detailsData.clients.map(client => {
      if (client.identifier === clickedRow.client.identifier) {
       
        // 1.1 Unique Permissions disabled
        
        client.uniquePermissions.map(uniq => {
        
          if (uniq.permission_id === clickedRow.permission_id) {
            uniq.isDisabled = true;
          }
          return uniq;
        })

        // 1.2 Permissions disabled
        client.permissions.map(permission => {
          if ((permission.resource.type === clickedRow.resource.type) && (permission.ds_account_id === clickedRow.ds_account_id)) {
            formattedPermissionsID.push(permission.permission_id);
          }
          return permission;
        })
          
        // 1.3 Client Check All unique Permissions disabled
        const isAllDisabled = client.uniquePermissions.filter(uniquePermission => !uniquePermission.isDisabled);
        client.isDisabled = isAllDisabled.length ? false : true;
      }
      return client;
    })
    
    // 3. Bulk revoke
    formattedPermissionsID.length && this.handleDisableBulkPermissionApi(formattedPermissionsIdArray);
    
    //Analytics
    this.handleAnalyticsTrack("onClick_event", `Disconnect information ${clickedRow.resource.name} for ${detailsData.orgInfo.name} - ${clickedRow.client.name}`);

    this.setState({
      isDisconnectDetailsModalOpen: false,
      detailsData,
      showRowMenu: false,
      isSendingReq: false
    })
  }

  // Handling modal toggle for Service Provider source click

  handleDisconnectServiceProviderSourceButtonClick = (e, selectedClient) => {
    // Prevent parent event
    e.stopPropagation();

    this.setState({
      isDisconnectSourceModalOpen: true,
      selectedClient: selectedClient,
      table_type: "SERVICE_PROVIDER",
      showRowMenu: false
    })
  }

  // Disable a Service Provider client

  handleDisconnectClientServiceProvider = async (e) => {
    // Prevent parent event
    e.stopPropagation();

    const { selectedClient, detailsData } = this.state;

    // SPinner
    this.setState({ isSendingReq: true });
    
    await DatasourceService.disableEnrolledClient(selectedClient.identifier).then(disabledClient => {
      // 1. Update isDisabled to all the Unique permissions array
      detailsData.clients.map(client => {
        if (client.identifier === selectedClient.identifier) {
          client.uniquePermissions.map(permission => {
            permission.isDisabled = true;
            return permission;
          })
        }

        // 1.1 Update the isDisabled for the client
        client.isDisabled = true;

        return client;
      })

      //Analytics
      this.handleAnalyticsTrack("onClick_event", `Disconnect all ${detailsData.orgInfo.name} - ${selectedClient.name}`);

      this.setState({
        isDisconnectSourceModalOpen: false,
        showRowMenu: false,
        selectedClient: {},
        detailsData,
        isSendingReq: false
      });

    }).catch(error => {
      if (error.status !== 401) {
        this.setState({
          isDisconnectSourceModalOpen: false,
          showRowMenu: false,
          selectedClient: {},
          hasError: true,
          errorTitle: i18n.t("connectionDetail__failure_disconnect_title"),
          errorMessage: i18n.t("connectionDetail__failure_disconnect_desc"),
          isSendingReq: false
        })
      } else {
        this.setState({
          isDisconnectSourceModalOpen: false,
          showRowMenu: false,
          selectedClient: {},
          isSendingReq: false
        })
      }  
    })
  }

  // Sorting for Information Source

  customInformationSourceSort = (rows, field, direction) => {
    const handleField = row => {
      if (field === "information") {
        return row.resource.name.toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  }
  
  // =============================
  // INFORMATIONS SOURCE FUNCTIONS 
  // =============================

  handleInformationSourceLogicOnLoad = (detailsData, dsAccounts, allPermissions) => {
    return new Promise((resolve) => {
      // 2.1 Update Filter the ds accounts based on org name
            
      dsAccounts?.length && dsAccounts.map(account => {
        if (account.ds_account_id === detailsData.ds_account_id) { 
          account.orgInfo = detailsData.orgInfo;
          account.orgType = detailsData.orgType;
          detailsData = account;
        }
        
        return account;
      })

      // 2.2 Check expiry

      DatasourceService.isAccountExpired(detailsData);

      
      let tempResources = detailsData.data_source.resources;
      
      if (tempResources.length) {
        
        // 2.3 Combine the resources with same type

        tempResources = this.handleCombineResourcesWithSameType(tempResources);

        // 2.4 Get the times shared and recent activity for specific data sources using resource type.
        
        tempResources = this.handleGetSharedAndRecentActivityForDatasource(detailsData, allPermissions, tempResources);

    
        // 2.5 Sort with shared permissions on top...

        tempResources = tempResources.sort((a, b) => b.resource.timesShared - a.resource.timesShared);
      }
    
      // 2.6 Disable all resources if disabled/ Expired acccount..

      if (detailsData.disabled || detailsData.expired) {
        tempResources.map(res => {
          res.resource.isDisabled = true;
          return res;
        });
        
        detailsData.isDisabled = true;
      }
      
      // 2.7 Update the resources

      detailsData.data_source.resources = tempResources;

      resolve(detailsData);
    })
  }

  // =============================================================
  // SECONDARY MEHODS FOR handleInformationSourceLogicOnLoad start
  // =============================================================

  // Combine the resources with same type

  handleCombineResourcesWithSameType = (tempResources) => {
    return (
      tempResources.reduce((newArray, currentItem) => {
        const found = newArray.find(a => a.resource.type === currentItem.resource.type);
        if (!found){
          return [...newArray, currentItem];
        } else {
          return newArray;
        }
      }, [])
    )
  }

  // Get the times shared and recent activity for specific data sources using resource type.
  
  handleGetSharedAndRecentActivityForDatasource = (detailsData, allPermissions, tempResources) => {
    let filterDisabledResources = [];

    return (
      tempResources.map(res => {
        let lastActiveTime;
        let timesShared = 0;
        let accountPermissions = [];

        allPermissions?.length && allPermissions.map(permission => {

          if ((detailsData.ds_account_id === permission.ds_account_id) && (res.resource.type === permission.resource.type)) {
            // 2.4.1 Number of times shared
            timesShared += 1;
        
            // 2.4.2 Add permission to the array
            accountPermissions.push(permission);
          }
          
          return permission;
        })
          
        
        if (accountPermissions.length) {
          // 2.5 Get the latest Permission time

          accountPermissions.sort((a, b) => new Date(b.created) - new Date(a.created));
          lastActiveTime = accountPermissions[0].created;

          // 2.6 Find the disabled resource

          filterDisabledResources = accountPermissions.filter(permission => !permission.disabled)
        }
        
        res.resource.permissions = accountPermissions;
        res.resource.isDisabled = (!filterDisabledResources.length && accountPermissions.length) ? true : false;
        res.resource.recentActivity = lastActiveTime || null;
        res.resource.timesShared = timesShared;
        res.ds_account_id = detailsData.ds_account_id;
        res.rot = detailsData.rot;

        return res;
      })
    )
  }

  // ===========================================================
  // SECONDARY MEHODS FOR handleInformationSourceLogicOnLoad end
  // ===========================================================

  // Disable Permission
  handleDisconnectInformationSourceDetailsModalSubmitButton = async (e) => {
    // Prevent parent event
    e.stopPropagation();

    let {
      clickedRow,
      detailsData,
    } = this.state;
    
    // SPinner
    this.setState({ isSendingReq: true });

    // 1. Get all the permissions id in an array
    let formattedPermissionsID = [];

    clickedRow.resource.permissions.map(item => {
      formattedPermissionsID.push(item.permission_id);
      return item;
    });
   
    const formattedPermissionsIdArray = {
      "permission_ids": formattedPermissionsID
    };
  
    const successfullPermisisonDisable = await this.handleDisableBulkPermissionApi(formattedPermissionsIdArray);
    detailsData.data_source.resources.map(res => {

      if ((detailsData.ds_account_id === clickedRow.ds_account_id) && (res.resource.type === clickedRow.resource.type)) {
        res.resource.permissions = successfullPermisisonDisable;
        res.resource.isDisabled = true;

        //Analytics
        this.handleAnalyticsTrack("onClick_event", `Disconnect information ${res.resource.name} for ${detailsData.orgInfo.name} - ${detailsData.nickname}`);
      }
      return res;
    });

    this.setState({
      showRowMenu: false,
      isDisconnectDetailsModalOpen: false,
      clickedRow: {},
      detailsData,
      isSendingReq: false
    })
  }

  // Handling modal toggle for disconnect source buttons

  handleDisconnectSourceButtonClick = (e) => {
    // Prevent parent event
    e.stopPropagation();
    
    this.setState({
      isDisconnectSourceModalOpen: true,
      showRowMenu: false,
      table_type: 'INFORMATION_SOURCE'
    })
  }
  
  // Reconnect Data source button

  handleReconnectSourceButtonClick = () => {
    console.log("Reconnect Now")
  }

  // Disconnect Account

  handleDisconnectDataSourceAction = async (e) => {
    // Prevent parent event
    e.stopPropagation();

    let { detailsData } = this.state;

    this.setState({ isSendingReq: true })
    
    try {
      // # Step 1 Disable Account
      await DatasourceService.disableMyDatasourceAccount(detailsData.ds_account_id).then(disabledAccount => {
        
        detailsData.data_source.resources.map(res => {
          
          res.resource.isDisabled = true;
          return res;
        });
       
        detailsData.isDisabled = true;
        detailsData.disabled = disabledAccount.disabled;
      });

      //Analytics
      this.handleAnalyticsTrack("onClick_event", `Disconnect all ${detailsData.orgInfo.name} - ${detailsData.nickname}`);

      this.setState({ 
        detailsData,
        isDisconnectSourceModalOpen:  false,
        showRowMenu: false,
        selectedClient: {},
        isSendingReq: false
      })
    } catch (error) {

      if (error.status !== 401) {
        this.setState({
          isDisconnectSourceModalOpen: false,
          showRowMenu: false,
          selectedClient: {},
          hasError: true,
          errorTitle: i18n.t("connectionDetail__failure_disconnect_title"),
          errorMessage: i18n.t("connectionDetail__failure_disconnect_desc"),
          isSendingReq: false
        })
      } else {
        this.setState({
          isDisconnectSourceModalOpen: false,
          showRowMenu: false,
          selectedClient: {},
          isSendingReq: false
        })
      }
    }
  }

  // Sorting for Service Provider

  customServiceProviderSort = (rows, field, direction) => {
    const handleField = row => {
      if (field === "information") {
        return row.resource.name.toLowerCase();
      }

      if (field === "source") {
        return row.data_source.name.toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  }

  // Sorting for Verified Credential Details

  customVerifiedCredentialDetailsSort = (rows, field, direction) => {
    const handleField = row => {
      if (field === "type") {
        return row.name.toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  }

  // Sorting for Verified Credential Activity

  customVerifiedCredentialActivitySort = (rows, field, direction) => {
    const handleField = row => {
      if (field === "information") {
        return row.type.toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  }

  // NICKNAME Methods

  handleToggleEditNickNameModal = () => {
    const { isEditNickNameModalOpen } = this.state;
    
    this.setState({
      isEditNickNameModalOpen: !isEditNickNameModalOpen,
      errorTitle: "",
      errorMessage: "",
    })
  }

  handleEditNickNameAction = async (nickname) => {
    let { clickedRow, detailsData } = this.state;

    // Spinner
    this.setState({ isSendingReq: true });

    let tempHasError = false;
    let tempInModalMessage = false;
    let tempIsEditNickNameModalOpen = false;
    let tempClickedRow = clickedRow;
    let tempErrorMessage;
    let tempErrorMessageTitle;

    if (detailsData.nickname !== nickname) {

      await DatasourceService.editNickname(detailsData.ds_account_id, nickname).then(res => {
        
        detailsData.nickname = res.nickname;
        tempClickedRow = {};

        //Analytics
        this.handleAnalyticsTrack("onClick_event", `Submit edit nickname with ${res.nickname}`);
      }).catch(e => {
        tempHasError = true;
        tempErrorMessageTitle = i18n.t('edit_nickname_modal__failure_nickname_update_title');
        tempErrorMessage = i18n.t('edit_nickname_modal__failure_nickname_update_desc');

        if (e.status && e.status === 409) {
          tempHasError = false;
          tempIsEditNickNameModalOpen = true;
          tempInModalMessage = true;
          tempErrorMessage = i18n.t("edit_nickname_modal__failure_nickname_409_title");
          tempErrorMessageTitle = "";
        }
      })
    }

    this.setState({
      detailsData,
      isEditNickNameModalOpen: tempIsEditNickNameModalOpen,
      clickedRow: tempClickedRow,
      isSendingReq: false,
      inModalMessage: tempInModalMessage,
      hasError: tempHasError,
      errorTitle: tempErrorMessageTitle,
      errorMessage: tempErrorMessage,
    })
  }

  // ===========================
  //  COMMON FUNCTIONS 
  // ===========================

  // Row clicks
  handleToggleDisconnectDetailsModal = (e) => {
    // Prevent parent event
    e.stopPropagation();

    const { isDisconnectDetailsModalOpen } = this.state;
   
    this.setState({ isDisconnectDetailsModalOpen:  !isDisconnectDetailsModalOpen, showRowMenu: false });
  }

  // Disable Bulk Permission Api
  handleDisableBulkPermissionApi = (permissionsIDArray) => {
    return (
      DatasourceService
      .disableBulkPermissions(permissionsIDArray)
      .then(res => res)
      .catch(e => {
        if (e.status && e.status !== 401) {
          this.setState({
            showRowMenu: false,
            isDisconnectDetailsModalOpen: false,
            clickedRow: {},
            hasError: true,
            errorTitle: i18n.t("connectionDetail__failure_disconnect_title"),
            errorMessage: i18n.t("connectionDetail__failure_disconnect_desc")
          })
        } else {
          this.setState({
            showRowMenu: false,
            isDisconnectDetailsModalOpen: false,
            clickedRow: {},
          })
        }
      })
    )
  }

  // Toggle Disconnect source modal

  handleCloseDisconnectSourceModal = (e) => {
    // Prevent parent event
    e.stopPropagation();

    this.setState({ isDisconnectSourceModalOpen:  false, client: {} })
  }

  // Row Action menu functions

  handleShowRowMenu = (e, selectedrow, table_type) => {

    // Prevent parent event
    e.stopPropagation();
    const { showRowMenu, clickedRow } = this.state;
    
    let isAlreadyOpen = !showRowMenu;

    // One menu is open and user click to open to open next one...
    if (table_type === "SERVICE_PROVIDER") {
      if (showRowMenu && clickedRow.permission_id !== selectedrow.permission_id) {
        isAlreadyOpen = true;
      }
    } else {
      if (showRowMenu && clickedRow.resource.res_def_id !== selectedrow.resource.res_def_id) {
        isAlreadyOpen = true;
      }
    }

    this.setState({
      showRowMenu: isAlreadyOpen,
      clickedRow: selectedrow,
      table_type,
    });

  }

  // Close three dots menu

  handleCloseMenu = () => {
    this.setState({
      showRowMenu: false,
      clickedRow: {},
    });
  }

  // Sorting Function

  handleOnSortClick = (uniqueTableIdentifier) => {
    const { onSortClick } = this.state;
    
    this.setState({
      onSortClick: !onSortClick,
      uniqueTableIdentifier
    })
  }

  handleRef = (element) => {
    this.dropdownMenu = element;
  }

  // Toggle Error modal

  handleToggleErrorModal = () => {
    const { hasError } = this.state;

    this.setState({ hasError: !hasError })
  }

  // Table Fields

  handleMobileDevice = (matches) => {  
    let  {
      serviceProviderfields,
      informationSourcefields,
      verifiedCredentialsDetailsfields,
      verifiedCredentialsActivityfields
    } = this.state;
    
    if (matches) {
      serviceProviderfields = informationSourcefields = [{
        selector: "mobile",
        minWidth: 'unset',
        hide: "md"
      }];

      verifiedCredentialsDetailsfields = [{
        selector: "mobile",
        minWidth: 'unset',
        hide: "md",
        type: "verified_credentials_details"
      }];

      verifiedCredentialsActivityfields = [{
        selector: "mobile",
        minWidth: 'unset',
        hide: "md",
        type: "verified_credentials_activity"
      }];
    } else {
      // These are required for lib table

      serviceProviderfields = [
        {
          name: i18n.t("connectionDetail__information"),
          selector: "information",
          grow: 2,
        }, {
          name: i18n.t("connectionDetail__source"),
          selector: "source",
        }, {
          name: i18n.t("connectionDetail__expires"),
          selector: "expires",
        },
        {
          selector: 'actionsMenu',
          ignoreRowClick: true,
          allowOverflow: true,
          maxWidth: '50px',
          minWidth: '50px',
          right: true,
          button: true,
        }
      ];

      informationSourcefields = [{
        name: i18n.t("connectionDetail__information"),
        selector: "information",
        grow: 2,
      }, {
        name: i18n.t("connectionDetail__activity"),
        selector: "recentActivity",
      }, {
        name: i18n.t("connectionDetail__expires"),
        selector: "expires",
      }, {
        selector: 'actionsMenu',
        ignoreRowClick: true,
        allowOverflow: true,
        right: true,
        button: true,
      }];

      verifiedCredentialsDetailsfields = [{
        name: i18n.t("connectionDetail__verfied_credential_details_table_type_header"),
        selector: "type",
      }, {
        name: i18n.t("connectionDetail__verfied_credential_details_table_information_header"),
        selector: "information",
      }];

      verifiedCredentialsActivityfields = [{
        name: i18n.t("connectionDetail__verfied_credential_activity_table_type_header"),
        selector: "information",
      }, {
        name: i18n.t("connectionDetail__verfied_credential_activity_table_information_header"),
        selector: "activity",
      }];
    }
    
    this.setState({
      serviceProviderfields,
      informationSourcefields,
      verifiedCredentialsDetailsfields,
      verifiedCredentialsActivityfields
    })
  }

  //#######################################################
  // Helper Functions
  //#######################################################

  handleClassnameForDisabled = (isDisabled, tempClassName) => {
    let tempClass = tempClassName;

    if (Boolean(isDisabled) === true) {
      tempClass = `${tempClassName} ${tempClassName}--disabled`;
    }

    return tempClass;
  }

  handleHeaderInfoBasedOnData = (isHeader) => {
    const { isInformationSource, detailsData } = this.state;
   
    if (isInformationSource) {
      return (
        `${detailsData?.orgInfo.name} - ${detailsData.nickname}`
      )
    }
    
    if (detailsData?.vc_stakeholder) {
      
      // Title Name for VC by using credential name
      
      if (isHeader) {
        // If Information Source and credentials Available
        if (detailsData?.credentials?.length && !detailsData?.proof_requests?.length) {
          return (
            detailsData.credentials.length && detailsData.credentials[0].name
          )
        } 
        
        // If Proof Request 
        if (detailsData?.proof_requests?.length) {
          return (
            detailsData?.vc_stakeholder.name
          )
        }
      } else {

        // Breadcrumb Name for VC using stakeholder name

        return (
          detailsData?.vc_stakeholder.name
        )
      }
    }
    
    return detailsData?.orgInfo?.name;
  }

  // Tracking

  handleAnalyticsTrack = (type, action, reason) => {

    const { detailsData } = this.props;
  
    // For Either Service Provider or VC
    let pageName = detailsData.orgInfo?.name || detailsData.vc_stakeholder?.name;
      
    // For Information SOurce
    if (detailsData.orgType === i18n.t("connectionDetail__informationSource") && (!detailsData.vc_stakeholder)) {
      pageName = `${detailsData.orgInfo.name} - ${detailsData.nickname}`;
    }
    
    AnalyticsService.track(type, {
      context: 'Wallet',
      page: `${pageName} Connection Details Page`,
      actions: action,
      url: window.location.hostname + window.location.pathname,
      reason
    })
  }

  // Render BreadCrumb

  renderBreadCrumb = () => {
    return  (
      <>
        <div className="connectionDetail-breadcrumb">
          <Link to={window.GLOBAL_PATH+"my-activity"} className="breadcrumb-link textPrimaryColor">{i18n.t("connections__title")}</Link>
          <span className="icon-chevron"></span>
          <p className="textPrimaryColor">{this.handleHeaderInfoBasedOnData()}</p>
        </div>

        <div className="connections-titleBar">
          <h1>{this.handleHeaderInfoBasedOnData(true)}</h1>
        </div>
      </>
    )
  }

  render() {
    let {
      serviceProviderfields,
      informationSourcefields,
      verifiedCredentialsDetailsfields,
      verifiedCredentialsActivityfields,

      isServiceProvider,
      isInformationSource,
      isVCConnection,
      isProofRequest,
      detailsData, 
      uniqueTableIdentifier,

      isDisconnectSourceModalOpen,
      isDisconnectDetailsModalOpen,

      showRowMenu,

      clickedRow,
      table_type,

      selectedClient,
      onSortClick,

      isLoading,

      hasError,
      errorTitle,
      errorMessage,
      errorButtonText,

      isSendingReq,

      isEditNickNameModalOpen,
      inModalMessage,
      my_activity_configs
    } = this.state;
   
    const {
      handleReload,
      isInternetDisconnected,
      handleToggleNetworkModal,
      history
    } = this.props;

    // Spinner while loading the data

    if (isLoading && !isInternetDisconnected) {
      return <div className="connectionDetail--spinner-wrapper"><Spinner /></div>
    }

    // IF Internet disconnected

    if (isInternetDisconnected && !detailsData.clients && !detailsData.data_source) {
      return (
        <div className="noResultErrorMessage">
          <h3>{i18n.t("connectionDetail__failure_no_network_title")}</h3>
          <p>{i18n.t("connectionDetail__failure_no_network_desc")}</p>
          <button className="btn backgroundPrimaryColor" onClick={handleReload}>{i18n.t("retry")}</button>
        </div>
      )
    }

    // IF No Valid Verified Credentials

    if (isVCConnection && ((!isProofRequest && !detailsData.credentials?.length) || (isProofRequest && !detailsData.proof_requests?.length))) {
      return (
        <>
          { this.renderBreadCrumb() }

          <div className="noResultErrorMessage">
            <h3>{i18n.t("connectionDetail__credential_not_valid_error_title")}</h3>
            <p>{i18n.t("connectionDetail__credential_not_valid_error_description")}</p>
          </div>
        </>
      )
    }
  
    return (
      <div className="connectionDetail-container " onClick={this.handleCloseMenu}>

        {/* breadcrumb */}

        {
          this.renderBreadCrumb()
        }


        {/* ==================== */}
        {/* Information Source */}
        {/* ==================== */}

        {
          isInformationSource
          &&
          <InformationSourceContainer
            informationSourcefields={informationSourcefields}
            handleClassnameForDisabled={this.handleClassnameForDisabled}
            detailsData={detailsData}
            handleShowRowMenu={this.handleShowRowMenu}
            showRowMenu={showRowMenu}
            handleRef={this.handleRef}
            handleToggleDisconnectDetailsModal={this.handleToggleDisconnectDetailsModal}
            my_activity_configs={my_activity_configs}
            isLoading={isLoading}
            isInternetDisconnected={isInternetDisconnected}
            onSortClick={onSortClick}
            handleOnSortClick={this.handleOnSortClick}
            paginationPerPage={window.config.connectionsDetailsPagination}
            handleMobileDevice={this.handleMobileDevice}
            handleDisconnectSourceButtonClick={this.handleDisconnectSourceButtonClick}
            handleReconnectSourceButtonClick={this.handleReconnectSourceButtonClick}
            handleToggleEditNickNameModal={this.handleToggleEditNickNameModal}
            clickedRow={clickedRow}
            history={history}
            customInformationSourceSort={this.customInformationSourceSort}
          />
        }


        {/* ==================== */}
        {/* Service Provider */}
        {/* ==================== */}

        {
          isServiceProvider
          &&
          <ServiceProviderContainer
            serviceProviderfields={serviceProviderfields}
            handleClassnameForDisabled={this.handleClassnameForDisabled}
            detailsData={detailsData}
            handleShowRowMenu={this.handleShowRowMenu}
            showRowMenu={showRowMenu}
            handleRef={this.handleRef}
            handleToggleDisconnectDetailsModal={this.handleToggleDisconnectDetailsModal}
            my_activity_configs={my_activity_configs}
            isLoading={isLoading}
            isInternetDisconnected={isInternetDisconnected}
            onSortClick={onSortClick}
            handleOnSortClick={this.handleOnSortClick}
            paginationPerPage={window.config.connectionsDetailsPagination}
            handleMobileDevice={this.handleMobileDevice}
            handleDisconnectServiceProviderSourceButtonClick={this.handleDisconnectServiceProviderSourceButtonClick}
            clickedRow={clickedRow}
            history={history}
            uniqueTableIdentifier={uniqueTableIdentifier}
            customServiceProviderSort={this.customServiceProviderSort}
          />
        }

        {/* ==================== */}
        {/* Verified Credentials */}
        {/* ==================== */}
        
        {/* IF Issuer */}
        
        {
          (isVCConnection && !isProofRequest && detailsData.credentials?.length)
          &&
          <>
            {
              detailsData.credentials.map(credential => (
                <VerifiedCredentialContainer 
                  key={credential.identifier}
                  verifiedCredentialsDetailsfields={verifiedCredentialsDetailsfields}
                  verifiedCredentialsActivityfields={verifiedCredentialsActivityfields}
                  detailsData={detailsData}
                  credential={credential}
                  onSortClick={onSortClick}
                  handleOnSortClick={this.handleOnSortClick}
                  paginationPerPage={window.config.connectionsDetailsPagination}
                  isLoading={isLoading}
                  customVerifiedCredentialActivitySort={this.customVerifiedCredentialActivitySort}
                  customVerifiedCredentialDetailsSort={this.customVerifiedCredentialDetailsSort}
                  isInternetDisconnected={isInternetDisconnected}
                  uniqueTableIdentifier={uniqueTableIdentifier}
                />
              ))
            }
          </>
        }


        {/* IF Verifier */}
        
        {
          (isProofRequest && detailsData.proof_requests?.length)
          &&
          <ProofRequestContainer
            serviceProviderfields={serviceProviderfields}
            handleClassnameForDisabled={this.handleClassnameForDisabled}
            detailsData={detailsData}
            handleShowRowMenu={this.handleShowRowMenu}
            showRowMenu={showRowMenu}
            handleRef={this.handleRef}
            handleToggleDisconnectDetailsModal={this.handleToggleDisconnectDetailsModal}
            isLoading={isLoading}
            isInternetDisconnected={isInternetDisconnected}
            onSortClick={onSortClick}
            handleOnSortClick={this.handleOnSortClick}
            paginationPerPage={window.config.connectionsDetailsPagination}
            handleMobileDevice={this.handleMobileDevice}
            handleDisconnectServiceProviderSourceButtonClick={this.handleDisconnectServiceProviderSourceButtonClick}
            clickedRow={clickedRow}
            history={history}
            customServiceProviderSort={this.customServiceProviderSort}
          />
        }

        {/* ==================== */}
        {/* Modals */}
        {/* ==================== */}


        {
          isDisconnectSourceModalOpen 
          && 
          <DisconnectSourceModal 
            isDisconnectSourceModalOpen={isDisconnectSourceModalOpen} 
            handleCloseDisconnectSourceModal={this.handleCloseDisconnectSourceModal} 
            handleDisconnectDataSource={this.handleDisconnectDataSourceAction} 
            handleDisconnectClientServiceProvider={this.handleDisconnectClientServiceProvider}
            detailsData={detailsData}
            selectedClient={selectedClient}
            table_type={table_type} 
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
          />
        }

        {
          isDisconnectDetailsModalOpen 
          && 
          <DisconnectDetailsModal 
            isDisconnectDetailsModalOpen={isDisconnectDetailsModalOpen} 
            handleToggleDisconnectDetailsModal={this.handleToggleDisconnectDetailsModal} 
            handleDisconnectInformationSourceDetailsModalSubmitButton={this.handleDisconnectInformationSourceDetailsModalSubmitButton} 
            handleDisconnectServiceProviderDetailsModalSubmitButton={this.handleDisconnectServiceProviderDetailsModalSubmitButton} 
            clickedRow={clickedRow} 
            table_type={table_type} 
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            detailsData={detailsData}
          />
        }

        {
          hasError
          &&
          <ErrorModal 
            isOpen={hasError}
            errorTitle={errorTitle}
            errorMessage={errorMessage} 
            errorButtonText={errorButtonText}
            handleToggleModal={this.handleToggleErrorModal}
          />
        }

        {
          isEditNickNameModalOpen
          &&
          <EditNicknameModal
            isOpen={isEditNickNameModalOpen}
            handleToggleModal={this.handleToggleEditNickNameModal}
            handleEditNicknameAction={this.handleEditNickNameAction}
            nickname={detailsData.nickname}
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            title={i18n.t("edit_nickname_modal__title")}
            description={i18n.t("edit_nickname_modal__desc")}
            inModalMessage={inModalMessage}
            errorMessage={errorMessage}
          />
        }
      </div>
    );
  }
}

export default withRouter(ConnectionDetailContainer);
