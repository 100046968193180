import React from "react";

// Lib
import i18n from "../../../i18n";

// Components
import Spinner from "../../Spinner";

const ProofItemCredentials = ({ isLoadingCredential, toggleErrorModal, selectedCredential }) => {

  const renderRequestWithValidCredential = () => {
    if (isLoadingCredential) {
      return (
        <Spinner />
      )
    } else {
      return (
        <>
          <p className="subTitle">{i18n.t("consent__information_source_text")}</p>
          <div className={"connection-info"}>

            <p className="connected-dataSource-name">
              { selectedCredential.name }
            </p>              
          </div>
        </>
      )
    }
  }

  const renderRequestWithoutValidCredential = () => { 
    if (isLoadingCredential) {
      return (
        <Spinner />
      )
    } else {
      return (
        <div className="connection-info connection-info--add-connection">
          <p>
            <span>&#33;</span> {i18n.t("consent__select_a_source")}
          </p>

          <button
            className={"find-connection-button borderPrimaryColor backgroundPrimaryColor"}
            onClick={() => toggleErrorModal(i18n.t("proofRequest_page__no_valid_credentials_error_modal_title"), i18n.t("proofRequest_page__no_valid_credentials_error_modal_desc"))}
          >
            {i18n.t("consent__find_source")}
          </button>
        </div>
      )
    }
  }

  const renderResource = () => { 
    if (Object.keys(selectedCredential).length !== 0) {
      return (
        renderRequestWithValidCredential()
      )
    } else {
      return(
        renderRequestWithoutValidCredential()
      )
    }
  }

  return (
    <div className="connected-rs">
      {
        renderResource()
      }
    </div>
  );
}

export default ProofItemCredentials;
