import React from "react";

// Lib
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';

// Services
import i18n from "../../../i18n";

// Components
import ButtonWithSpinner from "../../ButtonWithSpinner";
 
const CredentialOfferModal = ({ 
    isOpen, 
    selectedOffer, 
    handleAcceptOfferAction,
    handleDeclineOfferAction,
    handleToggleCredentialOfferModal,
    isSendingReq,
    isInternetDisconnected,
    handleToggleNetworkModal,
}) =>  {
  
    const renderfilteredAttrs = () => {
        const tempAttr = selectedOffer.credential_preview?.attributes;
    
        return (
            tempAttr.length && tempAttr.map((attr, i) => (
                <div className="row" key={i}>
                    <p className="left">{attr.name}</p>
                    <p className="right">{attr.value}</p>
                </div>
            ))
        )
    }
    
    return (
        <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            overlayClassName="modal-overlay"
            onRequestClose={!isSendingReq ? handleToggleCredentialOfferModal : () => { return undefined; }}
            contentLabel={i18n.t("profile_credential_offer_details_modal_title")}
        >
            <div className="modal-wrapper decline-wrapper credential-offer">
        
                <button 
                    className="close-icon" 
                    onClick={handleToggleCredentialOfferModal} 
                    aria-label={i18n.t("connectionDetail__aria_label_close_button")}
                >
                    <CloseIcon className="arrow-icon" />
                </button>

                <h2 className="header">
                    {i18n.t("profile_credential_offer_details_modal_title")}
                </h2>
                
                <div 
                    className="subtitle" 
                    dangerouslySetInnerHTML={{ __html: i18n.t("profile__credential_offer_details_modal_subtitle", { issuerName: selectedOffer.vc_stakeholder?.name, issuerOffering: selectedOffer.credential_preview?.display_name }) }} 
                />

                <div className="subtitle-secondary">
                    { i18n.t("profile__credential_offer_details_modal_subtitle_secondary") }
                </div>

                <div className="modal-body">

                    {
                        renderfilteredAttrs()
                    }

                </div>

                <div className="footer">
                    <button 
                        className="noThanksButton decline-btn"
                        onClick={handleDeclineOfferAction}
                        disabled={isSendingReq}
                    >
                        { i18n.t("profile__credential_offer_details_modal_secondary_button") }
                    </button>

                    <ButtonWithSpinner
                        className={"disconnectButton accept-btn backgroundPrimaryColor"}
                        isInternetDisconnected={isInternetDisconnected}
                        handleToggleNetworkModal={handleToggleNetworkModal}
                        btnText={i18n.t("profile__credential_offer_details_modal_primary_btn")}
                        handleButtonAction={() => handleAcceptOfferAction(true)}
                        isSendingReq={isSendingReq}
                    />
                </div>
            </div>
        </Modal>  
    )
}

export default CredentialOfferModal;
