import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';

// Services
import DatasourceService from "../../Services/DatasourceService";

//Components
import DisconnectModal from "../../DisconnectModal";
import CustomTable from "../../CustomTable";
import MediaQuery from 'react-responsive';
import Spinner from "../../Spinner";

// Lib
import i18n from "../../../i18n";
import Moment from 'react-moment';
import { orderBy } from 'lodash';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import InfiniteScroll from "react-infinite-scroll-component";

class ConnectionResourceDetailContainer extends Component {
  state = {
    isLoading: true,
    informationSourcefields: [],
    permissions: [],
    paginationPermissions: [],
    isAllDisabled: false,

    isDisconnectModalOpen: false,

    showRowMenu: false,
    clickedRow: {},

    onSortClick: false,
    modalType: "",
    isSendingReq: false,

    isPaginationLoader: false,
    limit: window.config.connectionsDetailsPagination,
    pageNumber: 1,
    my_activity_configs: window.config.my_activity_configs
  };

  componentDidMount = async () => {

    // These are required for lib table
    let isSmallScreen = false;

    if (window.innerWidth <= 750) {
      isSmallScreen = true;
    }
    this.handleMobileDevice(isSmallScreen);
    
    const { resourceDetail, isInternetDisconnected} = this.props;
    const { limit } = this.state;

    if (!isInternetDisconnected) {

      return DatasourceService.getPermissions().then(res => {
        let resPermissions = [];
        res.map(permission => {
         
          if ((resourceDetail.selectedrow.resource.type === permission.resource.type) && (resourceDetail.detailsData.ds_account_id === permission.ds_account_id)) {
            resPermissions.push(permission);
          }
          return permission
        })
  
        // # Sort by latest
        resPermissions.sort((a, b) => new Date(b.created) - new Date(a.created));

        // # Sort by Disabled
        resPermissions.sort((a, b) => Boolean(a.disabled) - Boolean(b.disabled));
  
        // ## Check if all permissions disabled
        this.checkIfAllPermissionsDisabled(resPermissions);
       
        this.setState({ 
          permissions: resPermissions,
          paginationPermissions: resPermissions.slice(0, limit),
          pageNumber: 2,
          isLoading: false,
        })
      }).catch(e => {
        this.setState({
          isLoading: false
        });
      })
    }
  }

  checkIfAllPermissionsDisabled = (permissions) => {
    const permissionsDisabled = permissions.filter(permission => permission.disabled)

    if (permissionsDisabled.length === permissions.length) {
      this.setState({ isAllDisabled: true });
    }
  }

  // Row clicks

  handleToggleDisconnectDetailsModal = (e) => {
    // Prevent parent event
    e.stopPropagation();

    const { isDisconnectModalOpen } = this.state;
   
    this.setState({ 
      isDisconnectModalOpen: !isDisconnectModalOpen,
      showRowMenu: false, 
      modalType: "DISCONNECT_ROW" 
    });
  }

  handleDisconnectPermission = (e) => {
    // Prevent parent event
    e.stopPropagation();

    let { clickedRow, permissions } = this.state;

    this.setState({ isSendingReq: true });

    return DatasourceService.disablePermission(clickedRow.permission_id).then(res => {
      permissions = permissions.map(permission => {
        if (permission.permission_id === res.permission_id) {
          permission.disabled = res.disabled;
        } 

        return permission
      })

      // ## Check if all permissions disabled
      this.checkIfAllPermissionsDisabled(permissions);
      
      this.setState({
        isDisconnectModalOpen: false,
        showRowMenu: false,
        modalType: "",
        clickedRow: {},
        permissions,
        isSendingReq: false
      })
    });
  }

 
  // Handling modal toggle for disconnect all source button

  handleToggleDisconnectAllModal = (e) => {
    // Prevent parent event
    e.stopPropagation();

    const { isDisconnectModalOpen } = this.state;

    this.setState({
      isDisconnectModalOpen: !isDisconnectModalOpen,
      showRowMenu: false,
      modalType: "DISCONNECT_ALL"
    })
  }


  // Dissconnect data source all
  handleDisconnectDataSourceAll = async (e) => {
    // Prevent parent event
    e.stopPropagation();

    const { permissions } = this.state;

    this.setState({ isSendingReq: true });
    // Get all the Permissions ID
    let permissionsIdArray = [];

    permissions.forEach(permission => {
      permissionsIdArray.push(permission.permission_id);
    })
    
    const formattedPermissionsIdArray = {
      "permission_ids": permissionsIdArray
    };
  
    const successfullPermisisonDisable = await this.handleDisableBulkPermissionApi(formattedPermissionsIdArray);

    this.setState({
      showRowMenu: false,
      isDisconnectModalOpen: false,
      modalType: "",
      permissions: successfullPermisisonDisable,
      isAllDisabled: true,
      isSendingReq: false
    })
  }

  //  Disable Bulk Permission Api
  handleDisableBulkPermissionApi = (permissionsIDArray) => {
    return DatasourceService.disableBulkPermissions(permissionsIDArray).then(res => res);
  }

  // Breadcrumbs Click
  handlOrgNameClick = () => {

    const { resourceDetail } = this.props;
   
    this.props.history.push({
      pathname: window.GLOBAL_PATH + `my-activity/connections/${resourceDetail.detailsData.orgInfo.name}/${resourceDetail.detailsData.ds_account_id}`,
      state: {
        data: resourceDetail.detailsData
      }
    })
  }

  // Row Action menu functions

  handleShowRowMenu = (e, selectedrow) => {
    
    // Prevent parent event
    e.stopPropagation();

    const { showRowMenu, clickedRow } = this.state;

    let isAlreadyOpen = !showRowMenu;

    // One menu is open and user click to open to open next one...
    if (showRowMenu && clickedRow.permission_id !== selectedrow.permission_id) {
      isAlreadyOpen = true;
    }

    this.setState({ showRowMenu: isAlreadyOpen, clickedRow: selectedrow });

  }

  handleCloseMenu = () => {
    this.setState({
      showRowMenu: false,
    });
  }

  handleOnSortClick = () => {
    const { onSortClick } = this.state;

    this.setState({
      onSortClick: !onSortClick,
    })
  }

  customSort = (rows, field, direction) => {
    const handleField = row => {
      if (field === "requestedBy"){
        return row.client.name;
      }

      if (field === "requestedOn"){
        return row.created;
      }

      if (field === "accessExpires"){
        return row.expires;
      } 
    };

    return orderBy(rows, handleField, direction);
  }

  handleRef = (element) => {
    this.dropdownMenu = element;
  }

  renderMenu = (showRowMenu, rowID, clickedID) => {
    if (showRowMenu && rowID === clickedID) {
      return (
        <div 
          className="menu-wrapper" 
          ref={this.handleRef}
        >
          <button onClick={this.handleToggleDisconnectDetailsModal}>{i18n.t("connectionDetail__disconnectInformation")}</button>
        </div>
      )
    }
  }

  handleMobileDevice = (matches) => {
    let fields = [];

    if (matches) {
      fields = [{
        selector: "mobile",
        minWidth: 'unset',
        hide: "md"
      }];
    } else {
      fields = [{
        name: i18n.t("connectionResourceDetail__requested_by"),
        selector: "requestedBy"
      }, {
        name: i18n.t("connectionResourceDetail__requested_on"),
        selector: "requestedOn",
      }, {
        name: i18n.t("connectionResourceDetail__access_expires"),
        selector: "accessExpires",
      }, {
        selector: 'actionsMenu',
        ignoreRowClick: true,
        allowOverflow: true,
        right: true,
        button: true,
      }];
    }

    this.setState({ informationSourcefields: fields })
  }

  fetchMoreItems = () => {
    const {
      pageNumber,
      limit,
      permissions,
      paginationPermissions
    } = this.state;

    this.setState({ isPaginationLoader: true  });

    const offsetCalc = (limit * (pageNumber - 1));
    
    const nextPaginationPermissions = permissions.slice(offsetCalc).slice(0, limit);

    this.setState({
      isPaginationLoader: false,
      pageNumber: pageNumber + 1,
      paginationPermissions: [...paginationPermissions, ...nextPaginationPermissions]
    });
  }

  render() {
    const {
      informationSourcefields,
      permissions,

      isDisconnectModalOpen,
      modalType,

      showRowMenu,

      clickedRow,
      onSortClick,
      isLoading,

      isAllDisabled,
      isSendingReq,

      paginationPermissions,
      isPaginationLoader,
      limit,
      my_activity_configs
    } = this.state;
    
    const {
      resourceDetail,
      handleReload,
      isInternetDisconnected,
      handleToggleNetworkModal
    } = this.props;
    
    //#######################################################
    // Formatting fields data with table settings for Information source
    //#######################################################
    
    const informationSourceHeaderfields = informationSourcefields && informationSourcefields.map(item => {

      // Add custom settings for each column
      if (item.selector === "requestedBy") {
        item.sortable = true;
        item.cell = row => (
          <button 
            onClick={this.handleServiceProviderInformationClick} 
            className="name-button textPrimaryColor"
          >
            {
              row.disabled
              && 
              <span className="disconnected">{i18n.t("connectionDetail__disconnected")}</span>
            } 
            <div className='informationText'>
              {row.client.name}
            </div> 
            <ArrowRightIcon className="caretStyle textPrimaryColor" />
          </button>
        )
      }
      
      if (item.selector === "requestedOn") {
        item.sortable = true;
        item.cell = row => (
          <div className="time-wrapper">
            <Moment 
            format="hh:mm A" 
            className={row.disabled ? 'last-active  last-active--disabled' : 'last-active'}
            >
              { row.created }
            </Moment>

            <Moment 
            format="YYYY/MM/DD" 
            className={row.disabled ? 'last-active last-active--disabled' : 'last-active'}
            >
              { row.created }
            </Moment>
          </div>
        )
      }
      
      if (item.selector === "accessExpires") {
        item.sortable = true;
        item.cell = row => {
          return (
            <React.Fragment>
              {
                row.expires
                ?
                <div className={row.disabled ? 'time-wrapper time-wrapper--disabled' : 'time-wrapper'} >
                  <Moment 
                    format="hh:mm A"
                  >
                    { row.expires }
                  </Moment> 
                  <Moment 
                    format="YYYY/MM/DD" 
                  >
                    { row.expires }
                  </Moment> 
                </div>
                :
                <div className="empty">&#8213;</div>
              }
            </React.Fragment>
          )
        }
      }
      
      if (item.selector === "actionsMenu") {
        item.cell = row => (
          <React.Fragment>
            {
              !row.disabled
              && 
              <button className="actionButton textPrimaryColor" onClick={(e) => this.handleShowRowMenu(e, row)} aria-label="more actions"><MoreHorizIcon className="dots" /></button>
            }

            { 
              this.renderMenu(showRowMenu, clickedRow.permission_id, row.permission_id)
            }
          </React.Fragment>
        )
      }


      if (item.selector === "mobile") {
        item.cell = row => (
          <div className="mobile-wiew-wrapper">
            <div className="left-side">

              <button 
                onClick={this.handleServiceProviderInformationClick} 
                className="name-button textPrimaryColor"
              >
                {
                  row.disabled
                  && 
                  <span className="disconnected">{i18n.t("connectionDetail__disconnected")}</span>
                } 
                <div className='informationText'>
                  {row.client.name}
                </div> 
                <ArrowRightIcon className="caretStyle textPrimaryColor" />
              </button>
              
              <div className="sub-content">
                <div className={row.disabled ? "time-wrapper time-wrapper--disabled" : "time-wrapper"} >
                  <span>{i18n.t("connectionResourceDetail__requested_on")}:</span>
                  <Moment 
                  format="hh:mm A" 
                  >
                    { row.created }
                  </Moment>

                  <Moment 
                    format="YYYY/MM/DD" 
                  >
                    { row.created }
                  </Moment>
                </div>

                {
                    row.expires
                    ?
                    <div className={row.disabled ? 'time-wrapper time-wrapper--disabled' : 'time-wrapper'} >
                      <span>{i18n.t("connectionResourceDetail__access_expires")}</span>
                      <Moment 
                        format="hh:mm A"
                      >
                        { row.expires }
                      </Moment> 
                      <Moment 
                        format="YYYY/MM/DD" 
                      >
                        { row.expires }
                      </Moment> 
                    </div>
                    :
                    <div className={row.disabled ? 'time-wrapper time-wrapper--disabled' : 'time-wrapper'} >
                      <span>{i18n.t("connectionResourceDetail__access_expires")}:</span>
                      <div className="empty">&#8213;</div>
                    </div>
                  }
              </div>
            </div>

            <div className="right-side">
              {
                !row.disabled
                && 
                <button className="actionButton textPrimaryColor" onClick={(e) => this.handleShowRowMenu(e, row)} aria-label="more actions"><MoreHorizIcon className="dots" /></button>
              }

              { 
                this.renderMenu(showRowMenu, clickedRow.permission_id, row.permission_id)
              }
            </div>
          </div>
        )
      }

      return item;
    })

    const noDataText = ( <div className="noResultErrorMessage" >
      <h3>{i18n.t("connectionDetail__failure_no_network_title")}</h3>
      <p>{i18n.t("connectionDetail__failure_no_network_desc")}</p>
      <button className="btn backgroundPrimaryColor" onClick={handleReload}>{i18n.t("reload")}</button>
    </div>)
    
   
    if (!resourceDetail) {
      window.location.assign(window.GLOBAL_PATH+"my-activity");
      return;
    }
   
    return (
      <div className="connectionDetail-container connectionResourceDetail-container" onClick={this.handleCloseMenu}>

        {/* breadcrumb */}

        <div className="connectionDetail-breadcrumb">
          <Link to={window.GLOBAL_PATH+"my-activity"} className="breadcrumb-link textPrimaryColor">{i18n.t("connections__title")}</Link>
          <span className="icon-chevron"></span>
          <button onClick={this.handlOrgNameClick} className="textPrimaryColor">{`${resourceDetail.detailsData.orgInfo.name} - ${resourceDetail.detailsData.nickname}`}</button>
          <span className="icon-chevron textPrimaryColor"></span>
          <p className="textPrimaryColor">{resourceDetail.selectedrow.resource.name}</p>
        </div>

        <div className="connections-titleBar">
          <h1>{resourceDetail.selectedrow.resource.name}</h1>
        </div>

        {/* Information Source */}
          <div className="connectionDetail-serviceProvider connectionDetail-section-wrapper">
            <div className="topBar">
              {
                my_activity_configs.multiple_accounts_enable 
                &&
                <div className="heading">
                  <h2 className="title">
                    {i18n.t("connectionDetail__informationSource")}
                  </h2>

                  {/* Disconencted Text */}
                  
                  {
                    ((isAllDisabled || !permissions.length) && isLoading === false)
                    ?
                    <div className="disconnected">{i18n.t("connectionDetail__disconnected")}</div>
                    :
                    null
                  }

                  {/* DIsconnect all button */}

                  {     
                    (!isAllDisabled && permissions.length && isLoading === false)
                    ?
                    <button 
                      className="disconnectButton" 
                      onClick={this.handleToggleDisconnectAllModal} 
                      >
                      {i18n.t("connectionDetail__disconnect_all")}
                    </button>
                    :
                    null
                  }
                </div>
              }

              <div className="sub-heading" dangerouslySetInnerHTML={{ __html: i18n.t('connectionResourceDetail__sub_heading', { name: resourceDetail.selectedrow.resource.name, interpolation: {escapeValue: true} }) }} />
            </div>
            
            {
              isInternetDisconnected && !permissions.length
              ?
              noDataText
              :
              <React.Fragment>

                {/* Desktop Version */}
                <MediaQuery minDeviceWidth={751}>
                  <CustomTable
                    isLoading={isLoading}
                    isInternetDisconnected={isInternetDisconnected}
                    tableData={permissions}
                    onSortClick={onSortClick}
                    handleOnSortClick={this.handleOnSortClick}
                    noDataText={noDataText}
                    customSort={this.customSort}
                    fields={informationSourceHeaderfields}
                    sortIcon={onSortClick ? <div className="sortIcon textPrimaryColor caretUp">&#9662;</div> : <div className="sortIcon textPrimaryColor">&#9662;</div>}
                    paginationPerPage={limit}
                  />
                </MediaQuery>

                {/* </Mobile Version> */}

                <MediaQuery maxDeviceWidth={750} onChange={this.handleMobileDevice}>
                  <InfiniteScroll
                    dataLength={paginationPermissions.length}
                    next={this.fetchMoreItems}
                    hasMore={permissions.length >= paginationPermissions.length}
                    loader={isPaginationLoader && <div className="loader-wrapper"><Spinner /></div>}
                  >
                    <CustomTable
                      isLoading={isLoading}
                      isInternetDisconnected={isInternetDisconnected}
                      tableData={permissions}
                      onSortClick={onSortClick}
                      handleOnSortClick={this.handleOnSortClick}
                      noDataText={noDataText}
                      customSort={this.customSort}
                      fields={informationSourceHeaderfields}
                      sortIcon={onSortClick ? <div className="sortIcon textPrimaryColor caretUp">&#9662;</div> : <div className="sortIcon textPrimaryColor">&#9662;</div>}
                      paginationPerPage={window.config.connectionsDetailsPagination}
                      isMobileVersion={true}
                    />
                  </InfiniteScroll>
                </MediaQuery>
              </React.Fragment>
            }
          </div>

        {
          isDisconnectModalOpen && modalType === "DISCONNECT_ALL" && clickedRow
          ?
          <DisconnectModal 
            isDisconnectModalOpen={isDisconnectModalOpen} 
            handleToggleDisconnectAllModal={this.handleToggleDisconnectAllModal} 
            handleDisconnectSubmission={this.handleDisconnectDataSourceAll} 
            title={i18n.t("connectionDetail__disconnect_all")}
            body1={i18n.t('connectionResourceDetail__disconnect_modal_sub_heading_top', { name: resourceDetail.selectedrow.resource.name, interpolation: {escapeValue: true} })}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            isSendingReq={isSendingReq}
          />
          :
          isDisconnectModalOpen && modalType === "DISCONNECT_ROW" && clickedRow
          ?
          <DisconnectModal 
            isDisconnectModalOpen={isDisconnectModalOpen} 
            handleToggleDisconnectAllModal={this.handleToggleDisconnectAllModal}  
            handleDisconnectSubmission={this.handleDisconnectPermission}
            title={`${i18n.t("connectionDetail__disconnectInformation")}?`}
            body1={i18n.t('connectionDetail__disconnetDetailsISModalBodyTop')}
            body2={i18n.t("connectionDetail__disconnetDetailsISModalBody")}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            isSendingReq={isSendingReq}
          /> 
          : 
          null
        }

      </div>
    );
  }
}

export default withRouter(ConnectionResourceDetailContainer);
