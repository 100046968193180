import React from "react";
import DatasourceService from "./Services/DatasourceService";

// Components
import Spinner from "./Spinner";

// Lib
import { Redirect } from "react-router-dom";
import qs from "query-string";

export default class RsRedirectRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,
      dsAccountid: null,
      dataSource: {},
      hasError: false,
      error: {}
    };
  }

  componentDidMount = () => {
    const queryParts = qs.parse(this.props.location.search);
    
    return DatasourceService.connectionRequestState(queryParts.datasource)
      .then(res => {
        this.setState({
          redirectTo: res.internal_redirect,
          dsAccountid: res.ds_account_id,
          dataSource: res,
        })
      }).catch(e => {
        this.setState({
          hasError: true,
          error: e.data
        })
      })
  }

  render() {
    const {
      redirectTo,
      dsAccountid,
      dataSource,
      hasError,
      error
    } = this.state;

    if (hasError) {
      return <Redirect to = {
        {
          pathname: error?.last_view,
          state: { error }
        }
      }
      />;
    }

    if (redirectTo) {
      return (
        <Redirect
          to = {
            {
              pathname: redirectTo,
              state: {
                dsAccountid: dsAccountid,
                dataSource,
              }
            }
          }
        />
      );
    }

    return (
      <div className="redirect-router-spinner-wrapper">
        <Spinner />
      </div>
    )
  }
}
