import React from "react";

// Lib
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Moment from 'react-moment';
import i18n from "../../../i18n";

// Components
import TableDesktopVersion from "../presentation/TableDesktopVersion";
import TableMobileVersion from "../presentation/TableMobileVersion";

const ServiceProviderContainer = ({
  serviceProviderfields,
  handleClassnameForDisabled,
  detailsData,
  handleShowRowMenu,
  showRowMenu,
  handleRef,
  handleToggleDisconnectDetailsModal,
  my_activity_configs,
  isLoading,
  isInternetDisconnected,
  onSortClick,
  handleOnSortClick,
  paginationPerPage,
  handleMobileDevice,
  handleDisconnectServiceProviderSourceButtonClick,
  clickedRow,
  history,
  uniqueTableIdentifier,
  customServiceProviderSort
}) => {
  
  //################################################################
  // Formatting fields data with table settings for Service provider
  //################################################################

  const serviceProviderHeaderFields = serviceProviderfields && serviceProviderfields.map(item => {

    // Add custom settings for each column
    if (item.selector === "information") {
      item.sortable = true;
      item.cell = row => (
        <button 
          className = "name-button textPrimaryColor"
          onClick={(e) => handleSPNameClick(e, row)}
        >
          {
            row.isDisabled
            && 
            <span className="disconnected">{i18n.t("connectionDetail__disconnected")}</span>
          } 
          <div className="informationText">
            {row.resource.name}
          </div>

          <ArrowRightIcon className="caretStyle textPrimaryColor" />
        </button>
      )
    }
    
    if (item.selector === "source") {
      item.sortable = true;
      item.cell = row => (
        <div className={handleClassnameForDisabled(row.isDisabled, "last-active")}>
          {
            my_activity_configs.multiple_accounts_enable
            ?
            `${row.data_source.name} - ${row.nickname}`
            :
            row.data_source.name
          }
        </div>
      )
    }
    
    if (item.selector === "expires") {
      item.cell = row => (
        row.expires
          ? 
        <Moment 
          format="YYYY/MM/DD" 
          className={handleClassnameForDisabled(row.isDisabled, "last-active")} 
        >
          {
            row.expires
          }
        </Moment> 
        : 
        <div className="empty">&#8213;</div>
      )
    }
    
    if (item.selector === "actionsMenu") {
      item.cell = row => {
        return (<React.Fragment>
          {
            !row.isDisabled
            && 
            <button className="actionButton textPrimaryColor" onClick={(e) => handleShowRowMenu(e, row, 'SERVICE_PROVIDER')} aria-label="more actions"><MoreHorizIcon className="dots" /></button>
          }
          
          {/* Menu */}

          { rendeServiceProviderMenu(row) }
        </React.Fragment>)
      }
    }

    if (item.selector === "mobile") {
      item.cell = row => (
        mobileTableSettings(row)
      )
    }

    return item;
  })

  const mobileTableSettings = (row) => {
    return (
      <div className="mobile-wiew-wrapper">
        <div className="left-side">

          <button 
            className="name-button textPrimaryColor"
            onClick={(e) => handleSPNameClick(e, row)}
          >
            {
              row.isDisabled
              && 
              <span className="disconnected">{i18n.t("connectionDetail__disconnected")}</span>
            } 
            <div className="informationText">
              {row.resource.name}
            </div>

            <ArrowRightIcon className="caretStyle textPrimaryColor" />
          </button>
          
          <div className="sub-content">
            <div className={handleClassnameForDisabled(row.disabled, "time-wrapper")} >
              <span>{i18n.t("connectionDetail__source")}:</span>
              
              {row.data_source.name}
            </div>
            
            {
              my_activity_configs.multiple_accounts_enable
              &&
              <React.Fragment>
                <div className={handleClassnameForDisabled(row.disabled, "time-wrapper")} >
                  <span>{i18n.t("connectionDetail__connected")}:</span>
                  <Moment format="YYYY/MM/DD" className={handleClassnameForDisabled(row.isDisabled, "last-active")}>
                    {row.created}
                  </Moment>
                </div>
                {
                  row.expires
                  ?
                  <div className={handleClassnameForDisabled(row.disabled, "time-wrapper")} >
                    <span>{i18n.t("connectionDetail__expires")}</span> 
                    <Moment 
                      format="YYYY/MM/DD" 
                    >
                      { row.expires }
                    </Moment> 
                  </div>
                  :
                  <div className={handleClassnameForDisabled(row.disabled, "time-wrapper")} >
                    <span>{i18n.t("connectionDetail__expires")}:</span>
                    <div className="empty">&#8213;</div>
                  </div>
                }
              </React.Fragment>
            }

          </div>
          
        </div>

        <div className="right-side">
          {
            !row.isDisabled
            && 
            <button className="actionButton textPrimaryColor" onClick={(e) => handleShowRowMenu(e, row, 'SERVICE_PROVIDER')} aria-label="more actions"><MoreHorizIcon className="dots" /></button>
          }
          
          {/* Menu */}

          { rendeServiceProviderMenu(row) }
        </div>
      </div>
    )
  }

  const rendeServiceProviderMenu = (row) => {
    if (showRowMenu && row.permission_id === clickedRow.permission_id) {
      return (
        <div 
          className="menu-wrapper" 
          ref={handleRef}
        >
          <button onClick={handleToggleDisconnectDetailsModal}>{i18n.t("connectionDetail__disconnectInformation")}</button>
        </div>
      )
    }
  }

  // SP Name click 

  const handleSPNameClick = (e, selectedrow) => {
    // Prevent parent event
    e.stopPropagation();
    
    history.push({
      pathname: window.GLOBAL_PATH+`my-activity/connections/${detailsData.orgInfo.name}/${detailsData.orgInfo.id}/${selectedrow.client.identifier}/${selectedrow.rs_res_id}`,
      state: {
        selectedrow,
        detailsData
      }
    })
  }

  // Render Table Header

  const renderTableHeader = (client) => {
    if (my_activity_configs.multiple_accounts_enable) {
      return  (
        <h3>
          {client.name}
        </h3> 
      )
    } else {
      return (
        <div />
      )
    }
  }

  // Subheading render

  const handleServiceProviderSubheading = () => {
    if (my_activity_configs.multiple_accounts_enable) {
      return  (
        <div className="sub-heading" dangerouslySetInnerHTML={{ __html: i18n.t('connectionDetail__serviceSubHeading_multiple_accounts', { name: detailsData.orgInfo.name , interpolation: {escapeValue: true} }) }} />
      )
    } 
      
    return  (
      <div className="sub-heading--single" dangerouslySetInnerHTML={{ __html: i18n.t('connectionDetail__serviceSubHeading', { name: detailsData.orgInfo.name, interpolation: {escapeValue: true} }) }} />
    )
  }
 
  return (
    <div className="connectionDetail-serviceProvider">
      <div className="connectionDetail-section-wrapper">

        {/* Top Bar */}

        <div className="topBar">
          <div className="heading">
            <h2 className="title">{i18n.t("connectionDetail__serviceProvider")}</h2>
          </div>

          {
            handleServiceProviderSubheading()
          }
        </div>

        {/* Service Provider Data Table  */}

        {
          detailsData.clients.map(client => (
            <div key={client.identifier}>

              {/* Table header */}

              <div className="account-heading">
                {
                  renderTableHeader(client)
                }

                {
                  client.isDisabled
                  && 
                  <div className="disconnected">{i18n.t("connectionDetail__disconnected")}</div>
                } 

                {
                  !client.isDisabled
                  ?
                  <button 
                    className="disconnectButton" 
                    onClick={(e) => handleDisconnectServiceProviderSourceButtonClick(e, client)} 
                  > 
                    {i18n.t("connectionDetail__disconnect_all")}
                  </button>
                  :
                  null
                }
              </div>
              
              {/* Desktop Version */}

              <TableDesktopVersion
                isLoading={isLoading}
                isInternetDisconnected={isInternetDisconnected}
                tableData={client.uniquePermissions}
                onSortClick={onSortClick }
                handleOnSortClick={handleOnSortClick.bind(this, client.identifier)}
                customSort={customServiceProviderSort}
                fields={serviceProviderHeaderFields}
                paginationPerPage={paginationPerPage}
                sortIcon={onSortClick && client.identifier === uniqueTableIdentifier ? <div className="sortIcon textPrimaryColor caretUp">&#9662;</div> : <div className="sortIcon textPrimaryColor">&#9662;</div>}
              />


              {/* </Mobile Version> */}

              <TableMobileVersion
                handleMobileDevice={handleMobileDevice}
                isLoading={isLoading}
                isInternetDisconnected={isInternetDisconnected}
                tableData={client.uniquePermissions}
                onSortClick={onSortClick}
                handleOnSortClick={handleOnSortClick.bind(this, client.identifier)}
                customSort={customServiceProviderSort}
                fields={serviceProviderHeaderFields}
                sortIcon={onSortClick && client.identifier === uniqueTableIdentifier ? <div className="sortIcon textPrimaryColor caretUp">&#9662;</div> : <div className="sortIcon textPrimaryColor">&#9662;</div>}
              />
            </div>
          ))
        }       
      </div>
    </div>
  );
}

export default ServiceProviderContainer;
