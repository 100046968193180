import React from "react";

// Lib
import i18n from "../../../i18n";
import Toggle from "react-toggle";

// Components
import RsListItemResource from "./RsListItemResource";
import DataSourceSelectorModal from "../container/DataSourceSelectorModal";
import ScopeModal from "./ScopeModal";

const RsListItem = ({ resource, request, acceptAccessToResource, connectedAccounts, datasources, isScopeModalOpen, scopeModalID, shouldDateSourceSelectorModalOpen, consent_configs, isInternetDisconnected, handleToggleNetworkModal, handleToggleScopeModal, handleToggleChange, onDataSourceSelect, openConnectionModal, closeConnectionModal, isLoadingConnection, handleDataSourceSelectorModalToggle }) => {
  
  return (
    <li 
      className={!acceptAccessToResource[resource.resource_definition.res_def_id] ? "rs-item rs-item--disabled" : "rs-item"}
    >
      <div className="container">
        <div className="header">
          <div className="title-container">
            <div className="title">
              {resource.resource_definition.name}
            </div>

            {/* Scope Modal Button */}

            <button 
              className="see-details textPrimaryColor"
              onClick={handleToggleScopeModal.bind(this, resource.resource_definition)} 
            >
              { i18n.t("consent__whats_this")}
            </button>
          </div>

          {/* Disable Toggle button */}
          
          {
            consent_configs.enable_source_toggle
            &&
            <Toggle
              checked={acceptAccessToResource[resource.resource_definition.res_def_id]}
              className="toggler"
              icons={false}
              id={resource.resource_definition.res_def_id.toString()}
              onChange={handleToggleChange}
              aria-label="toggle switch"
            />
          }

        </div>

        {/* Render Rs */}
        
        <RsListItemResource 
          resource={resource} 
          acceptAccessToResource={acceptAccessToResource} 
          isLoadingConnection={isLoadingConnection} 
          consent_configs={consent_configs}
          openConnectionModal={openConnectionModal}
        />
        
      </div>     
      
      {/* Scopes Modal */}

      {
        (isScopeModalOpen && scopeModalID === resource.resource_definition.res_def_id)
        &&
        <ScopeModal
          isOpen={isScopeModalOpen} 
          data={resource} 
          consent_configs={consent_configs}
          handleToggleScopeModal={handleToggleScopeModal}
        />
      }

      {/* DS selector Modal */}

      {
        (handleDataSourceSelectorModalToggle(resource.resource_definition.res_def_id) && shouldDateSourceSelectorModalOpen)
        &&
        <DataSourceSelectorModal
          resource={resource}
          connectedAccounts={connectedAccounts}
          datasources={datasources}
          transaction_id={request.transaction_id}
          onDataSourceSelect={onDataSourceSelect}
          isOpen={handleDataSourceSelectorModalToggle(resource.resource_definition.res_def_id) && shouldDateSourceSelectorModalOpen}
          onRequestClose={closeConnectionModal.bind(this, resource.resource_definition)}
          resourceName={resource.resource_definition.name}
          isInternetDisconnected={isInternetDisconnected}
          handleToggleNetworkModal={handleToggleNetworkModal}
        />
      }
    </li>
  );
}

export default RsListItem;